import { Union } from "./fable_modules/fable-library.3.7.22/Types.js";
import { union_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { split, printf, toText } from "./fable_modules/fable-library.3.7.22/String.js";
import { map as map_1, reverse, tail, collect, head, isEmpty, empty, ofArray } from "./fable_modules/fable-library.3.7.22/List.js";
import { parsePath, oneOf, map, s } from "./fable_modules/Fable.Elmish.Browser.3.0.5/parser.fs.js";
import { Navigation_newUrl } from "./fable_modules/Fable.Elmish.Browser.3.0.5/navigation.fs.js";

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Home", "Login", "Logout", "Settings", "Members", "Administrators", "Administrator", "Channels", "Infoboards", "Statistics", "Billing", "HowTo", "OpenApi"];
    }
}

export function Page$reflection() {
    return union_type("Pages.Page", [], Page, () => [[], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export const newDomain = (() => {
    const arg = tenantId;
    return toText(printf("%s.mobilenews.no"))(arg);
})();

export const oldDomain = (() => {
    const arg = tenantId;
    return toText(printf("emn.itpartner.no/%s"))(arg);
})();

export const usesNewDomain = (() => {
    const domain = window.location.host;
    return ((domain === newDomain) ? true : (domain === "localhost:8080")) ? true : (domain === "localhost:8085");
})();

export const basePath = usesNewDomain ? "" : (() => {
    const arg = tenantId;
    return toText(printf("/%s"))(arg);
})();

export function toPath(_arg) {
    switch (_arg.tag) {
        case 1: {
            return basePath + "/login";
        }
        case 2: {
            return basePath + "/logout";
        }
        case 3: {
            return basePath + "/settings";
        }
        case 4: {
            return basePath + "/employees";
        }
        case 5: {
            return basePath + "/administrators";
        }
        case 6: {
            return basePath + "/administrator";
        }
        case 7: {
            return basePath + "/channels";
        }
        case 8: {
            return basePath + "/infoboards";
        }
        case 9: {
            return basePath + "/stats";
        }
        case 10: {
            return basePath + "/billing";
        }
        case 11: {
            return basePath + "/how";
        }
        case 12: {
            return basePath + "/publicapi";
        }
        default: {
            return basePath + "/";
        }
    }
}

const basePath$0027 = (basePath.length > 1) ? ofArray(split(basePath.slice(1, basePath.length), ["/"], null, 0)) : empty();

function matchBasePath() {
    const walk = (p) => ((_arg) => {
        let parseBefore;
        return isEmpty(_arg) ? p : walk((parseBefore = s(head(_arg)), (state) => collect(p, parseBefore(state))))(tail(_arg));
    });
    return (_arg_1) => (isEmpty(_arg_1) ? (void 0) : walk(s(head(_arg_1)))(tail(_arg_1)));
}

export function skipBasePath(next) {
    const matchValue = matchBasePath()(reverse(basePath$0027));
    if (matchValue == null) {
        return next;
    }
    else {
        const p = matchValue;
        return (state) => collect(next, p(state));
    }
}

export const pageParser = usesNewDomain ? (() => {
    const parsers = ofArray([map(new Page(0), s("")), map(new Page(1), s("login")), map(new Page(2), s("logout")), map(new Page(4), s("employees")), map(new Page(5), s("administrators")), map(new Page(6), s("administrator")), map(new Page(7), s("channels")), map(new Page(8), s("infoboards")), map(new Page(9), s("stats")), map(new Page(10), s("billing")), map(new Page(11), s("how")), map(new Page(12), s("publicapi"))]);
    return (state) => oneOf(parsers, state);
})() : (() => {
    const parsers_1 = ofArray([map(new Page(0), skipBasePath(s(""))), map(new Page(1), skipBasePath(s("login"))), map(new Page(2), skipBasePath(s("logout"))), map(new Page(4), skipBasePath(s("employees"))), map(new Page(5), skipBasePath(s("administrators"))), map(new Page(6), skipBasePath(s("administrator"))), map(new Page(7), skipBasePath(s("channels"))), map(new Page(8), skipBasePath(s("infoboards"))), map(new Page(9), skipBasePath(s("stats"))), map(new Page(10), skipBasePath(s("billing"))), map(new Page(11), skipBasePath(s("how"))), map(new Page(12), skipBasePath(s("publicapi")))]);
    return (state_1) => oneOf(parsers_1, state_1);
})();

export function urlParser(location) {
    return parsePath(pageParser, location);
}

export function goToUrl(e) {
    e.preventDefault();
    map_1((f) => {
        f((value) => {
        });
    }, Navigation_newUrl(e.target.href));
}

