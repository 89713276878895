import { Union, Record } from "../fable_modules/fable-library.3.7.22/Types.js";
import { StatisticsResponse$reflection, Statistics_AppInstalls$reflection, Channel$reflection, NewsStat$reflection } from "../Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, string_type, bool_type, int32_type, option_type, array_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { parseAsUtc, checkFetchError } from "../Utils.js";
import { sortByDescending, map as map_1, append } from "../fable_modules/fable-library.3.7.22/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Statistics_OverviewInterval } from "../Shared/Urls.js";
import { props as props_13 } from "../Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "../fable_modules/fable-library.3.7.22/Util.js";
import { createElement } from "react";
import * as react from "react";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { compare } from "../fable_modules/fable-library.3.7.22/Date.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import Replay from "@material-ui/icons/Replay";
import { loadingIndicator, loadingIndicatorLarge } from "../ViewHelpers.js";
import { tryLoadSession } from "../LocalStorage.js";
import { toBase64String } from "../fable_modules/fable-library.3.7.22/String.js";
import { get_UTF8 } from "../fable_modules/fable-library.3.7.22/Encoding.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { PieChart, Pie, Cell } from "recharts";
import { basePath } from "../Pages.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { Channel_fetchChannels } from "../Promises.js";
import { NewsReport } from "./NewsReport.js";

class Model extends Record {
    constructor(Entries, EntriesLoaded, HasFetchedAllEntries, Channels, SelectedChannel, NumEmployees, SelectedStat, AppInstalls, ErrorMsg) {
        super();
        this.Entries = Entries;
        this.EntriesLoaded = (EntriesLoaded | 0);
        this.HasFetchedAllEntries = HasFetchedAllEntries;
        this.Channels = Channels;
        this.SelectedChannel = SelectedChannel;
        this.NumEmployees = (NumEmployees | 0);
        this.SelectedStat = SelectedStat;
        this.AppInstalls = AppInstalls;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Report.Model", [], Model, () => [["Entries", option_type(array_type(NewsStat$reflection()))], ["EntriesLoaded", int32_type], ["HasFetchedAllEntries", bool_type], ["Channels", option_type(array_type(Channel$reflection()))], ["SelectedChannel", option_type(Channel$reflection())], ["NumEmployees", int32_type], ["SelectedStat", option_type(NewsStat$reflection())], ["AppInstalls", option_type(Statistics_AppInstalls$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EntriesResponse", "ChannelsResponse", "InstallsResponse", "SetSelectedChannel", "SetSelectedStat", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Report.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [StatisticsResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", StatisticsResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Channel$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Channel$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Statistics_AppInstalls$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Statistics_AppInstalls$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(Channel$reflection())]], [["Item", option_type(NewsStat$reflection())]], []]);
}

function init() {
    return new Model(void 0, 0, false, void 0, void 0, 0, void 0, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                checkFetchError(res_2.fields[0]);
                return model;
            }
            else {
                return new Model(model.Entries, model.EntriesLoaded, model.HasFetchedAllEntries, model.Channels, model.SelectedChannel, model.NumEmployees, model.SelectedStat, res_2.fields[0], model.ErrorMsg);
            }
        }
        case 1: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                checkFetchError(res_3.fields[0]);
                return model;
            }
            else {
                return new Model(model.Entries, model.EntriesLoaded, model.HasFetchedAllEntries, res_3.fields[0], model.SelectedChannel, model.NumEmployees, model.SelectedStat, model.AppInstalls, model.ErrorMsg);
            }
        }
        case 3: {
            return new Model(model.Entries, model.EntriesLoaded, model.HasFetchedAllEntries, model.Channels, msg.fields[0], model.NumEmployees, model.SelectedStat, model.AppInstalls, model.ErrorMsg);
        }
        case 4: {
            return new Model(model.Entries, model.EntriesLoaded, model.HasFetchedAllEntries, model.Channels, model.SelectedChannel, model.NumEmployees, msg.fields[0], model.AppInstalls, model.ErrorMsg);
        }
        case 5: {
            return new Model(model.Entries, model.EntriesLoaded, model.HasFetchedAllEntries, model.Channels, model.SelectedChannel, model.NumEmployees, model.SelectedStat, model.AppInstalls, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return new Model(model.Entries, model.EntriesLoaded, model.HasFetchedAllEntries, model.Channels, model.SelectedChannel, model.NumEmployees, model.SelectedStat, model.AppInstalls, "Kunne ikke laste rapport. Vennligst prøv igjen senere.");
            }
            else {
                const x = res.fields[0];
                const matchValue = model.Entries;
                if (matchValue != null) {
                    return new Model(append(matchValue, x.NewsStats), x.NewsStats.length + model.EntriesLoaded, x.NewsStats.length === 0, model.Channels, model.SelectedChannel, x.Members, model.SelectedStat, model.AppInstalls, model.ErrorMsg);
                }
                else {
                    return new Model(x.NewsStats, x.NewsStats.length, model.HasFetchedAllEntries, model.Channels, model.SelectedChannel, x.Members, model.SelectedStat, model.AppInstalls, model.ErrorMsg);
                }
            }
        }
    }
}

function fetchStats(dispatch, model) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url, props, decoder, data_1, caseStrategy_2, extra_2;
        return ((url = Statistics_OverviewInterval(model.EntriesLoaded), (props = props_13(), (decoder = Auto_generateBoxedDecoder_79988AEF(StatisticsResponse$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(StatisticsResponse$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(StatisticsResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchAppInstalls(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        return ((props = props_13(), (decoder = Auto_generateBoxedDecoder_79988AEF(Statistics_AppInstalls$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/statistics/app-installs", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Statistics_AppInstalls$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(Statistics_AppInstalls$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(2, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function drawOverview(model, dispatch) {
    let elems_4;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        maxWidth: 600,
        maxHeight: 90 + "vh",
        minHeight: 100,
        overflowY: "auto",
        padding: 5,
    }], (elems_4 = toList(delay(() => append_1(singleton(createElement("span", {
        style: {
            fontSize: 0.8 + "rem",
            fontStyle: "italic",
            marginBottom: 3,
        },
        children: "Nyheter publisert kun til infoskjerm ikke inkludert",
    })), delay(() => {
        let elems;
        const matchValue = model.Entries;
        if (matchValue != null) {
            const x = matchValue;
            return append_1((x.length === 0) ? singleton(createElement("div", createObj(ofArray([["style", {
                textAlign: "center",
                padding: 10,
            }], (elems = [createElement("span", {
                style: {
                    color: "#9a9a9a",
                    fontSize: 1.2 + "rem",
                    fontStyle: "italic",
                },
                children: "Ingen aktive nyheter",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty_1(), delay(() => append_1(singleton(map_1((y_2) => {
                let elems_2, elems_1;
                const readProgress = ((y_2.NumMembersWithAccess === 0) ? 0 : (~(~((y_2.NumReads / y_2.NumMembersWithAccess) * 100)))) | 0;
                return createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    flexDirection: "column",
                    padding: ((0 + "px ") + 5) + "px",
                    borderLeft: (((2 + "px ") + "solid") + " ") + "#363636",
                    borderRight: (((2 + "px ") + "solid") + " ") + "#363636",
                    marginBottom: 15,
                }], (elems_2 = [createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    justifyContent: "space-between",
                }], (elems_1 = [createElement("span", {
                    style: {
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                    },
                    onClick: (_arg) => {
                        dispatch(new Message(4, y_2));
                    },
                    children: y_2.Title,
                }), createElement("span", {
                    style: {
                        whiteSpace: "nowrap",
                    },
                    children: `${y_2.NumReads} av ${y_2.NumMembersWithAccess} har lest`,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", {
                    style: {
                        height: 20,
                        backgroundColor: "rgb(255, 207, 1)",
                        width: readProgress + "%",
                    },
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
            }, sortByDescending((y) => parseAsUtc(y.Published), x, {
                Compare: compare,
            }))), delay(() => {
                let elems_3;
                return singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    justifyContent: "center",
                }], (elems_3 = toList(delay(() => {
                    let props_9;
                    return model.HasFetchedAllEntries ? singleton(createElement("span", {
                        children: "Ingen flere nyheter å hente.",
                    })) : singleton(MuiHelpers_createElement(Button, [["variant", "text"], ["children", "Hent flere"], ["onClick", (_arg_1) => {
                        fetchStats(dispatch, model);
                    }], ["endIcon", (props_9 = {}, react.createElement(Replay, props_9))]]));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
            }))));
        }
        else {
            return singleton(loadingIndicatorLarge());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

class PieData extends Record {
    constructor(name, vl) {
        super();
        this.name = name;
        this.vl = (vl | 0);
    }
}

function PieData$reflection() {
    return record_type("Report.PieData", [], PieData, () => [["name", string_type], ["vl", int32_type]]);
}

function drawInstalls(model, dispatch) {
    let elems_4;
    let token;
    let token$0027;
    const matchValue = tryLoadSession();
    token$0027 = ((matchValue != null) ? matchValue.Token : "");
    token = toBase64String(get_UTF8().getBytes(token$0027));
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }], (elems_4 = toList(delay(() => append_1(singleton(createElement("span", {
        style: {},
        children: "Antall brukere som har installert app",
    })), delay(() => {
        let properties_8, elements_1, properties_7, elements;
        const matchValue_1 = model.AppInstalls;
        if (matchValue_1 != null) {
            const x = matchValue_1;
            return (x.NumMembers === 0) ? singleton(createElement("span", {
                style: {
                    fontSize: 1.5 + "rem",
                    fontStyle: "italic",
                },
                children: "Ingen medlemmer registrert",
            })) : append_1(singleton((properties_8 = ofArray([["width", 250], ["height", 250], (elements_1 = singleton_1((properties_7 = ofArray([["dataKey", "vl"], ["fill", "rgb(255, 207, 1)"], ["label", true], ["cx", 50 + "%"], ["cy", 50 + "%"], ["data", [new PieData("Installert", x.Installed), new PieData("Ikke installert", x.NumMembers - x.Installed)]], ["outerRadius", 90 + "%"], ["innerRadius", 50 + "%"], (elements = ofArray([Interop_reactApi_1.createElement(Cell, {
                key: "1",
                fill: "rgb(255, 207, 1)",
                stroke: "#363636",
            }), Interop_reactApi_1.createElement(Cell, {
                key: "2",
                fill: "#f6f6f6",
                stroke: "#363636",
            })]), ["children", ["children", Interop_reactApi.Children.toArray(Array.from(elements))]])]), Interop_reactApi_1.createElement(Pie, createObj(properties_7)))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]), Interop_reactApi_1.createElement(PieChart, createObj(properties_8)))), delay(() => append_1(singleton(createElement("span", {
                style: {
                    fontStyle: "italic",
                },
                children: `${x.Installed} av ${x.NumMembers} medlemmer`,
            })), delay(() => {
                let elems_3, elems_2;
                return singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    alignItems: "center",
                }], (elems_3 = [createElement("a", createObj(ofArray([["target", "_blank"], ["href", `${basePath}/api/statistics/download/app-installs/${token}`], (elems_2 = [createElement("span", {
                    style: {
                        color: "#0000FF",
                        marginRight: 5,
                    },
                    className: "fas fa-download",
                }), "Last ned rapport"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
            }))));
        }
        else {
            return singleton(loadingIndicator());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function Overview() {
    let elems_2;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchStats(dispatch, model_1);
        Channel_fetchChannels((arg_1) => {
            dispatch(new Message(1, arg_1));
        });
        fetchAppInstalls(dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    }], (elems_2 = toList(delay(() => {
        let elems_1, elems;
        const matchValue = model_1.SelectedStat;
        if (matchValue == null) {
            return singleton(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexWrap: "wrap-reverse",
            }], (elems_1 = [drawOverview(model_1, dispatch), createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexGrow: 1,
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                padding: 5,
            }], (elems = [drawInstalls(model_1, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
        }
        else {
            const x = matchValue;
            return singleton(createElement(NewsReport, {
                x: x,
                onClose: () => {
                    dispatch(new Message(4, void 0));
                },
            }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

