import { Union } from "./fable_modules/fable-library.3.7.22/Types.js";
import { union_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.7.22/Util.js";
import { singleton, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { channelInfo, designerInfo, infoscreenInfo, memberHowToInfo, reportInfo, publishInfo, accessInfo, memberInfo } from "./HowToContent.js";
import { ofArray } from "./fable_modules/fable-library.3.7.22/List.js";

export class Sections extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Channels", "Members", "Access", "Publishing", "Report", "MembersHowTo", "Infoscreens", "Designer"];
    }
}

export function Sections$reflection() {
    return union_type("HowTo.Sections", [], Sections, () => [[], [], [], [], [], [], [], []]);
}

export function HowTo() {
    let elems_1;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Sections(0));
    const setSection = patternInput[1];
    const currentSection = patternInput[0];
    return createElement("div", createObj(ofArray([["style", {}], (elems_1 = toList(delay(() => {
        let elements;
        return append(singleton(MuiHelpers_createElement(Tabs, [["onChange", (_arg, v) => {
            const i = v | 0;
            switch (i) {
                case 1: {
                    setSection(new Sections(1));
                    break;
                }
                case 2: {
                    setSection(new Sections(2));
                    break;
                }
                case 3: {
                    setSection(new Sections(3));
                    break;
                }
                case 4: {
                    setSection(new Sections(4));
                    break;
                }
                case 5: {
                    setSection(new Sections(5));
                    break;
                }
                case 6: {
                    setSection(new Sections(6));
                    break;
                }
                case 7: {
                    setSection(new Sections(7));
                    break;
                }
                default: {
                    setSection(new Sections(0));
                }
            }
        }], ["value", (currentSection.tag === 1) ? 1 : ((currentSection.tag === 2) ? 2 : ((currentSection.tag === 3) ? 3 : ((currentSection.tag === 4) ? 4 : ((currentSection.tag === 5) ? 5 : ((currentSection.tag === 6) ? 6 : ((currentSection.tag === 7) ? 7 : 0))))))], ["variant", "scrollable"], ["style", {
            marginBottom: 20,
        }], (elements = [MuiHelpers_createElement(Tab, [["label", "Kanaler"]]), MuiHelpers_createElement(Tab, [["label", "Medlemmer"]]), MuiHelpers_createElement(Tab, [["label", "Tilgang"]]), MuiHelpers_createElement(Tab, [["label", "Publisering"]]), MuiHelpers_createElement(Tab, [["label", "Rapport"]]), MuiHelpers_createElement(Tab, [["label", "Hva ansatte må gjøre"]]), MuiHelpers_createElement(Tab, [["label", "Infoskjermer"]]), MuiHelpers_createElement(Tab, [["label", "Infoskjerm design"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])), delay(() => {
            const matchValue = currentSection;
            switch (matchValue.tag) {
                case 1: {
                    return singleton(memberInfo());
                }
                case 2: {
                    return singleton(accessInfo());
                }
                case 3: {
                    return singleton(publishInfo());
                }
                case 4: {
                    return singleton(reportInfo());
                }
                case 5: {
                    return singleton(memberHowToInfo());
                }
                case 6: {
                    return singleton(infoscreenInfo());
                }
                case 7: {
                    return singleton(designerInfo());
                }
                default: {
                    return singleton(channelInfo());
                }
            }
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

