import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "./fable_modules/fable-library.3.7.22/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { singleton } from "./fable_modules/fable-library.3.7.22/List.js";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";

export function LogoNavbar(logoNavbarInputProps) {
    let elements, elems_2, elems_1, elems, props;
    const setSideBarOpen = logoNavbarInputProps.setSideBarOpen;
    const sideBarOpen = logoNavbarInputProps.sideBarOpen;
    return MuiHelpers_createElement(AppBar, [["position", "static"], ["color", "transparent"], ["style", {
        borderColor: "#FFFFFF",
        backgroundColor: "#FFFFFF",
        height: 4 + "rem",
        zIndex: 4,
        position: "fixed",
    }], (elements = [MuiHelpers_createElement(Toolbar, [["style", {
        color: "#808080",
        display: "flex",
        height: 4 + "rem",
        justifyContent: "space-between",
    }], (elems_2 = [createElement("a", createObj(singleton((elems_1 = [createElement("img", {
        src: "/images/logo512x512.png",
        style: {
            height: 3.5 + "rem",
            padding: 0.25 + "rem",
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])))), MuiHelpers_createElement(IconButton, [["onClick", (_arg) => {
        setSideBarOpen(!sideBarOpen);
    }], ["color", "secondary"], ["style", {
        color: "#808080",
    }], (elems = [(props = {}, react.createElement(Menu, props))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
}

