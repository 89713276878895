import { Record } from "../fable_modules/fable-library.3.7.22/Types.js";
import { tuple_type, list_type, float64_type, array_type, option_type, record_type, int32_type, string_type, class_type, bool_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { utcNow } from "../fable_modules/fable-library.3.7.22/Date.js";

export class SubscriptionState extends Record {
    constructor(HasSubscription, IsDemo, Active, ValidTo, Email, Phone, BillingType) {
        super();
        this.HasSubscription = HasSubscription;
        this.IsDemo = IsDemo;
        this.Active = Active;
        this.ValidTo = ValidTo;
        this.Email = Email;
        this.Phone = Phone;
        this.BillingType = (BillingType | 0);
    }
}

export function SubscriptionState$reflection() {
    return record_type("ApiDataTypes.SubscriptionState", [], SubscriptionState, () => [["HasSubscription", bool_type], ["IsDemo", bool_type], ["Active", bool_type], ["ValidTo", class_type("System.DateTime")], ["Email", string_type], ["Phone", string_type], ["BillingType", int32_type]]);
}

export function SubscriptionState_Default() {
    return new SubscriptionState(true, true, true, utcNow(), "", "", 3);
}

export class UserSession extends Record {
    constructor(Username, Token, Subscription) {
        super();
        this.Username = Username;
        this.Token = Token;
        this.Subscription = Subscription;
    }
}

export function UserSession$reflection() {
    return record_type("ApiDataTypes.UserSession", [], UserSession, () => [["Username", string_type], ["Token", string_type], ["Subscription", SubscriptionState$reflection()]]);
}

export class LoginRequest extends Record {
    constructor(Username, Password) {
        super();
        this.Username = Username;
        this.Password = Password;
    }
}

export function LoginRequest$reflection() {
    return record_type("ApiDataTypes.LoginRequest", [], LoginRequest, () => [["Username", string_type], ["Password", string_type]]);
}

export class LoginResponse extends Record {
    constructor(Result, Message, Session) {
        super();
        this.Result = Result;
        this.Message = Message;
        this.Session = Session;
    }
}

export function LoginResponse$reflection() {
    return record_type("ApiDataTypes.LoginResponse", [], LoginResponse, () => [["Result", string_type], ["Message", string_type], ["Session", UserSession$reflection()]]);
}

export class GenericMessage extends Record {
    constructor(result, message) {
        super();
        this.result = result;
        this.message = message;
    }
}

export function GenericMessage$reflection() {
    return record_type("ApiDataTypes.GenericMessage", [], GenericMessage, () => [["result", string_type], ["message", string_type]]);
}

export class GenericResponse extends Record {
    constructor(Result, Message) {
        super();
        this.Result = Result;
        this.Message = Message;
    }
}

export function GenericResponse$reflection() {
    return record_type("ApiDataTypes.GenericResponse", [], GenericResponse, () => [["Result", string_type], ["Message", string_type]]);
}

export class PhoneNumberInput extends Record {
    constructor(phoneNumber) {
        super();
        this.phoneNumber = phoneNumber;
    }
}

export function PhoneNumberInput$reflection() {
    return record_type("ApiDataTypes.PhoneNumberInput", [], PhoneNumberInput, () => [["phoneNumber", string_type]]);
}

export class IdInput extends Record {
    constructor(id) {
        super();
        this.id = id;
    }
}

export function IdInput$reflection() {
    return record_type("ApiDataTypes.IdInput", [], IdInput, () => [["id", string_type]]);
}

export class ChannelEntry extends Record {
    constructor(channelcode, channelname) {
        super();
        this.channelcode = channelcode;
        this.channelname = channelname;
    }
}

export function ChannelEntry$reflection() {
    return record_type("ApiDataTypes.ChannelEntry", [], ChannelEntry, () => [["channelcode", string_type], ["channelname", string_type]]);
}

export class ApiChannelSettings extends Record {
    constructor(Url, UseAuth, AuthToken) {
        super();
        this.Url = Url;
        this.UseAuth = UseAuth;
        this.AuthToken = AuthToken;
    }
}

export function ApiChannelSettings$reflection() {
    return record_type("ApiDataTypes.ApiChannelSettings", [], ApiChannelSettings, () => [["Url", string_type], ["UseAuth", bool_type], ["AuthToken", string_type]]);
}

export class ChannelForm extends Record {
    constructor(Id, Name, Code, Access, SourceType, ApiSettings) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Code = Code;
        this.Access = (Access | 0);
        this.SourceType = (SourceType | 0);
        this.ApiSettings = ApiSettings;
    }
}

export function ChannelForm$reflection() {
    return record_type("ApiDataTypes.ChannelForm", [], ChannelForm, () => [["Id", option_type(int32_type)], ["Name", string_type], ["Code", string_type], ["Access", int32_type], ["SourceType", int32_type], ["ApiSettings", option_type(ApiChannelSettings$reflection())]]);
}

export class Channel extends Record {
    constructor(Id, Name, Code, Access, SourceType, NumMembers, NumNews) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Code = Code;
        this.Access = (Access | 0);
        this.SourceType = (SourceType | 0);
        this.NumMembers = (NumMembers | 0);
        this.NumNews = (NumNews | 0);
    }
}

export function Channel$reflection() {
    return record_type("ApiDataTypes.Channel", [], Channel, () => [["Id", int32_type], ["Name", string_type], ["Code", string_type], ["Access", int32_type], ["SourceType", int32_type], ["NumMembers", int32_type], ["NumNews", int32_type]]);
}

export class ExternalChannelForm extends Record {
    constructor(Name, Code, Tenant, ApiKey, NumNews) {
        super();
        this.Name = Name;
        this.Code = Code;
        this.Tenant = Tenant;
        this.ApiKey = ApiKey;
        this.NumNews = (NumNews | 0);
    }
}

export function ExternalChannelForm$reflection() {
    return record_type("ApiDataTypes.ExternalChannelForm", [], ExternalChannelForm, () => [["Name", string_type], ["Code", string_type], ["Tenant", string_type], ["ApiKey", string_type], ["NumNews", int32_type]]);
}

export class Member extends Record {
    constructor(Id, FirstName, LastName, Company, Phone, PhoneCC, UsedAppOnce) {
        super();
        this.Id = (Id | 0);
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.Company = Company;
        this.Phone = Phone;
        this.PhoneCC = PhoneCC;
        this.UsedAppOnce = UsedAppOnce;
    }
}

export function Member$reflection() {
    return record_type("ApiDataTypes.Member", [], Member, () => [["Id", int32_type], ["FirstName", string_type], ["LastName", string_type], ["Company", string_type], ["Phone", string_type], ["PhoneCC", string_type], ["UsedAppOnce", bool_type]]);
}

export class MemberForm extends Record {
    constructor(Member, Channels) {
        super();
        this.Member = Member;
        this.Channels = Channels;
    }
}

export function MemberForm$reflection() {
    return record_type("ApiDataTypes.MemberForm", [], MemberForm, () => [["Member", Member$reflection()], ["Channels", array_type(string_type)]]);
}

export class ParsedMemberCsv extends Record {
    constructor(PhoneCC, Phone, Firstname, Lastname) {
        super();
        this.PhoneCC = PhoneCC;
        this.Phone = Phone;
        this.Firstname = Firstname;
        this.Lastname = Lastname;
    }
}

export function ParsedMemberCsv$reflection() {
    return record_type("ApiDataTypes.ParsedMemberCsv", [], ParsedMemberCsv, () => [["PhoneCC", string_type], ["Phone", string_type], ["Firstname", string_type], ["Lastname", string_type]]);
}

export class MemberCsvForm extends Record {
    constructor(Content, Delimiter) {
        super();
        this.Content = Content;
        this.Delimiter = Delimiter;
    }
}

export function MemberCsvForm$reflection() {
    return record_type("ApiDataTypes.MemberCsvForm", [], MemberCsvForm, () => [["Content", string_type], ["Delimiter", string_type]]);
}

export class MemberCsvResponse extends Record {
    constructor(Provided, Accepted, Members, Failed) {
        super();
        this.Provided = (Provided | 0);
        this.Accepted = (Accepted | 0);
        this.Members = Members;
        this.Failed = Failed;
    }
}

export function MemberCsvResponse$reflection() {
    return record_type("ApiDataTypes.MemberCsvResponse", [], MemberCsvResponse, () => [["Provided", int32_type], ["Accepted", int32_type], ["Members", array_type(ParsedMemberCsv$reflection())], ["Failed", array_type(string_type)]]);
}

export class Admin extends Record {
    constructor(Id, Username, IsSuperAdmin, Activated) {
        super();
        this.Id = Id;
        this.Username = Username;
        this.IsSuperAdmin = IsSuperAdmin;
        this.Activated = Activated;
    }
}

export function Admin$reflection() {
    return record_type("ApiDataTypes.Admin", [], Admin, () => [["Id", string_type], ["Username", string_type], ["IsSuperAdmin", bool_type], ["Activated", bool_type]]);
}

export class AdminForm extends Record {
    constructor(Email) {
        super();
        this.Email = Email;
    }
}

export function AdminForm$reflection() {
    return record_type("ApiDataTypes.AdminForm", [], AdminForm, () => [["Email", string_type]]);
}

export class UpdatePasswordForm extends Record {
    constructor(Id, OldPassword, NewPassword) {
        super();
        this.Id = Id;
        this.OldPassword = OldPassword;
        this.NewPassword = NewPassword;
    }
}

export function UpdatePasswordForm$reflection() {
    return record_type("ApiDataTypes.UpdatePasswordForm", [], UpdatePasswordForm, () => [["Id", string_type], ["OldPassword", string_type], ["NewPassword", string_type]]);
}

export class UnitPrice extends Record {
    constructor(currency, value) {
        super();
        this.currency = currency;
        this.value = value;
    }
}

export function UnitPrice$reflection() {
    return record_type("ApiDataTypes.UnitPrice", [], UnitPrice, () => [["currency", string_type], ["value", float64_type]]);
}

export class BillingEntry extends Record {
    constructor(quantity, details, unitprice, linetotal) {
        super();
        this.quantity = (quantity | 0);
        this.details = details;
        this.unitprice = unitprice;
        this.linetotal = linetotal;
    }
}

export function BillingEntry$reflection() {
    return record_type("ApiDataTypes.BillingEntry", [], BillingEntry, () => [["quantity", int32_type], ["details", string_type], ["unitprice", UnitPrice$reflection()], ["linetotal", UnitPrice$reflection()]]);
}

export class BillingInfo extends Record {
    constructor(result, periodstart, periodend, items, total, message) {
        super();
        this.result = result;
        this.periodstart = periodstart;
        this.periodend = periodend;
        this.items = items;
        this.total = total;
        this.message = message;
    }
}

export function BillingInfo$reflection() {
    return record_type("ApiDataTypes.BillingInfo", [], BillingInfo, () => [["result", string_type], ["periodstart", class_type("System.DateTime")], ["periodend", class_type("System.DateTime")], ["items", list_type(BillingEntry$reflection())], ["total", UnitPrice$reflection()], ["message", string_type]]);
}

export class InfoscreenUsage extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function InfoscreenUsage$reflection() {
    return record_type("ApiDataTypes.InfoscreenUsage", [], InfoscreenUsage, () => [["Id", string_type], ["Name", string_type]]);
}

export class PaymentHistory extends Record {
    constructor(payments) {
        super();
        this.payments = payments;
    }
}

export function PaymentHistory$reflection() {
    return record_type("ApiDataTypes.PaymentHistory", [], PaymentHistory, () => [["payments", list_type(tuple_type(class_type("System.DateTime"), int32_type, string_type))]]);
}

export class DiscountSummary extends Record {
    constructor(result, discounts, message) {
        super();
        this.result = result;
        this.discounts = discounts;
        this.message = message;
    }
}

export function DiscountSummary$reflection() {
    return record_type("ApiDataTypes.DiscountSummary", [], DiscountSummary, () => [["result", string_type], ["discounts", list_type(tuple_type(float64_type, string_type))], ["message", string_type]]);
}

export class ChannelWithCustomSourceForm extends Record {
    constructor(Name, Code, Access, SourceType, RequestUrl, Authentication) {
        super();
        this.Name = Name;
        this.Code = Code;
        this.Access = (Access | 0);
        this.SourceType = (SourceType | 0);
        this.RequestUrl = RequestUrl;
        this.Authentication = Authentication;
    }
}

export function ChannelWithCustomSourceForm$reflection() {
    return record_type("ApiDataTypes.ChannelWithCustomSourceForm", [], ChannelWithCustomSourceForm, () => [["Name", string_type], ["Code", string_type], ["Access", int32_type], ["SourceType", int32_type], ["RequestUrl", string_type], ["Authentication", string_type]]);
}

export class DispatchChannelForm extends Record {
    constructor(channelcode, channelname, tenanturl, internalurl, token) {
        super();
        this.channelcode = channelcode;
        this.channelname = channelname;
        this.tenanturl = tenanturl;
        this.internalurl = internalurl;
        this.token = token;
    }
}

export function DispatchChannelForm$reflection() {
    return record_type("ApiDataTypes.DispatchChannelForm", [], DispatchChannelForm, () => [["channelcode", string_type], ["channelname", string_type], ["tenanturl", string_type], ["internalurl", string_type], ["token", string_type]]);
}

export class DispatchChannelFormResponse extends Record {
    constructor(result, channel, message) {
        super();
        this.result = result;
        this.channel = channel;
        this.message = message;
    }
}

export function DispatchChannelFormResponse$reflection() {
    return record_type("ApiDataTypes.DispatchChannelFormResponse", [], DispatchChannelFormResponse, () => [["result", string_type], ["channel", DispatchChannelForm$reflection()], ["message", string_type]]);
}

export class ChannelSettings extends Record {
    constructor(UseMaxDays, MaxDays) {
        super();
        this.UseMaxDays = UseMaxDays;
        this.MaxDays = (MaxDays | 0);
    }
}

export function ChannelSettings$reflection() {
    return record_type("ApiDataTypes.ChannelSettings", [], ChannelSettings, () => [["UseMaxDays", bool_type], ["MaxDays", int32_type]]);
}

export class ChannelAccess extends Record {
    constructor(ChannelId, MemberId) {
        super();
        this.ChannelId = (ChannelId | 0);
        this.MemberId = (MemberId | 0);
    }
}

export function ChannelAccess$reflection() {
    return record_type("ApiDataTypes.ChannelAccess", [], ChannelAccess, () => [["ChannelId", int32_type], ["MemberId", int32_type]]);
}

export class Infoscreen extends Record {
    constructor(Id, Name, Url, Created) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Url = Url;
        this.Created = Created;
    }
}

export function Infoscreen$reflection() {
    return record_type("ApiDataTypes.Infoscreen", [], Infoscreen, () => [["Id", string_type], ["Name", string_type], ["Url", string_type], ["Created", string_type]]);
}

export class NewsStat extends Record {
    constructor(Id, ChannelName, ChannelCode, Title, Published, NumReads, NumMembersWithAccess, AvailableOnApp, ClosedChannel, RequiresConfirmation, Confirmed) {
        super();
        this.Id = Id;
        this.ChannelName = ChannelName;
        this.ChannelCode = ChannelCode;
        this.Title = Title;
        this.Published = Published;
        this.NumReads = (NumReads | 0);
        this.NumMembersWithAccess = (NumMembersWithAccess | 0);
        this.AvailableOnApp = AvailableOnApp;
        this.ClosedChannel = ClosedChannel;
        this.RequiresConfirmation = RequiresConfirmation;
        this.Confirmed = (Confirmed | 0);
    }
}

export function NewsStat$reflection() {
    return record_type("ApiDataTypes.NewsStat", [], NewsStat, () => [["Id", string_type], ["ChannelName", string_type], ["ChannelCode", string_type], ["Title", string_type], ["Published", string_type], ["NumReads", int32_type], ["NumMembersWithAccess", int32_type], ["AvailableOnApp", bool_type], ["ClosedChannel", bool_type], ["RequiresConfirmation", bool_type], ["Confirmed", int32_type]]);
}

export class NewsItem extends Record {
    constructor(Id, Title, Excerpt, Body, CreatedOn, CreatedBy, Published, Push, SMS, Pinned, PublishTo, Reactions, Confirmation) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.Excerpt = Excerpt;
        this.Body = Body;
        this.CreatedOn = CreatedOn;
        this.CreatedBy = CreatedBy;
        this.Published = Published;
        this.Push = Push;
        this.SMS = SMS;
        this.Pinned = Pinned;
        this.PublishTo = (PublishTo | 0);
        this.Reactions = Reactions;
        this.Confirmation = Confirmation;
    }
}

export function NewsItem$reflection() {
    return record_type("ApiDataTypes.NewsItem", [], NewsItem, () => [["Id", string_type], ["Title", string_type], ["Excerpt", string_type], ["Body", string_type], ["CreatedOn", string_type], ["CreatedBy", string_type], ["Published", bool_type], ["Push", bool_type], ["SMS", option_type(bool_type)], ["Pinned", bool_type], ["PublishTo", int32_type], ["Reactions", array_type(int32_type)], ["Confirmation", bool_type]]);
}

export class NewsListResponse extends Record {
    constructor(Result, NewsItems, Message) {
        super();
        this.Result = Result;
        this.NewsItems = NewsItems;
        this.Message = Message;
    }
}

export function NewsListResponse$reflection() {
    return record_type("ApiDataTypes.NewsListResponse", [], NewsListResponse, () => [["Result", string_type], ["NewsItems", array_type(NewsItem$reflection())], ["Message", string_type]]);
}

export class TokenRecord extends Record {
    constructor(ShortCode, Token, Phone) {
        super();
        this.ShortCode = ShortCode;
        this.Token = Token;
        this.Phone = Phone;
    }
}

export function TokenRecord$reflection() {
    return record_type("ApiDataTypes.TokenRecord", [], TokenRecord, () => [["ShortCode", string_type], ["Token", string_type], ["Phone", string_type]]);
}

export class SmsRequest extends Record {
    constructor(phonenumber, channelcode) {
        super();
        this.phonenumber = phonenumber;
        this.channelcode = channelcode;
    }
}

export function SmsRequest$reflection() {
    return record_type("ApiDataTypes.SmsRequest", [], SmsRequest, () => [["phonenumber", string_type], ["channelcode", string_type]]);
}

export class TokenRequest extends Record {
    constructor(shortcode, channelcode, phonenumber) {
        super();
        this.shortcode = shortcode;
        this.channelcode = channelcode;
        this.phonenumber = phonenumber;
    }
}

export function TokenRequest$reflection() {
    return record_type("ApiDataTypes.TokenRequest", [], TokenRequest, () => [["shortcode", string_type], ["channelcode", string_type], ["phonenumber", string_type]]);
}

export class TokenResponse extends Record {
    constructor(result, token, phonenumber, channelcode, channelname, channelurl, api, message) {
        super();
        this.result = result;
        this.token = token;
        this.phonenumber = phonenumber;
        this.channelcode = channelcode;
        this.channelname = channelname;
        this.channelurl = channelurl;
        this.api = api;
        this.message = message;
    }
}

export function TokenResponse$reflection() {
    return record_type("ApiDataTypes.TokenResponse", [], TokenResponse, () => [["result", string_type], ["token", string_type], ["phonenumber", string_type], ["channelcode", string_type], ["channelname", string_type], ["channelurl", string_type], ["api", string_type], ["message", string_type]]);
}

export class NewsResponseItem extends Record {
    constructor(id, title, excerpt, body, datecreated, author, channelname, channelcode, pinned, checksum) {
        super();
        this.id = id;
        this.title = title;
        this.excerpt = excerpt;
        this.body = body;
        this.datecreated = datecreated;
        this.author = author;
        this.channelname = channelname;
        this.channelcode = channelcode;
        this.pinned = pinned;
        this.checksum = checksum;
    }
}

export function NewsResponseItem$reflection() {
    return record_type("ApiDataTypes.NewsResponseItem", [], NewsResponseItem, () => [["id", string_type], ["title", string_type], ["excerpt", string_type], ["body", string_type], ["datecreated", string_type], ["author", string_type], ["channelname", string_type], ["channelcode", string_type], ["pinned", bool_type], ["checksum", string_type]]);
}

export class NewsResponse extends Record {
    constructor(result, newsitems) {
        super();
        this.result = result;
        this.newsitems = newsitems;
    }
}

export function NewsResponse$reflection() {
    return record_type("ApiDataTypes.NewsResponse", [], NewsResponse, () => [["result", string_type], ["newsitems", list_type(NewsResponseItem$reflection())]]);
}

export class NewsItemRequestEntry extends Record {
    constructor(channelcode, id) {
        super();
        this.channelcode = channelcode;
        this.id = id;
    }
}

export function NewsItemRequestEntry$reflection() {
    return record_type("ApiDataTypes.NewsItemRequestEntry", [], NewsItemRequestEntry, () => [["channelcode", string_type], ["id", string_type]]);
}

export class NewsItemRequest extends Record {
    constructor(requests) {
        super();
        this.requests = requests;
    }
}

export function NewsItemRequest$reflection() {
    return record_type("ApiDataTypes.NewsItemRequest", [], NewsItemRequest, () => [["requests", list_type(NewsItemRequestEntry$reflection())]]);
}

export class NewsChecksumEntry extends Record {
    constructor(id, channelcode, checksum) {
        super();
        this.id = id;
        this.channelcode = channelcode;
        this.checksum = checksum;
    }
}

export function NewsChecksumEntry$reflection() {
    return record_type("ApiDataTypes.NewsChecksumEntry", [], NewsChecksumEntry, () => [["id", string_type], ["channelcode", string_type], ["checksum", string_type]]);
}

export class NewsChecksumResponse extends Record {
    constructor(result, entries, message) {
        super();
        this.result = result;
        this.entries = entries;
        this.message = message;
    }
}

export function NewsChecksumResponse$reflection() {
    return record_type("ApiDataTypes.NewsChecksumResponse", [], NewsChecksumResponse, () => [["result", string_type], ["entries", list_type(NewsChecksumEntry$reflection())], ["message", string_type]]);
}

export class PhoneNumberChannel extends Record {
    constructor(channelname, channelcode, tenanturl, token) {
        super();
        this.channelname = channelname;
        this.channelcode = channelcode;
        this.tenanturl = tenanturl;
        this.token = token;
    }
}

export function PhoneNumberChannel$reflection() {
    return record_type("ApiDataTypes.PhoneNumberChannel", [], PhoneNumberChannel, () => [["channelname", string_type], ["channelcode", string_type], ["tenanturl", string_type], ["token", string_type]]);
}

export class NewsChecksumResponseV2 extends Record {
    constructor(result, entries, message, channelcode, devicetoken, subscribed) {
        super();
        this.result = result;
        this.entries = entries;
        this.message = message;
        this.channelcode = channelcode;
        this.devicetoken = devicetoken;
        this.subscribed = subscribed;
    }
}

export function NewsChecksumResponseV2$reflection() {
    return record_type("ApiDataTypes.NewsChecksumResponseV2", [], NewsChecksumResponseV2, () => [["result", string_type], ["entries", list_type(NewsChecksumEntry$reflection())], ["message", string_type], ["channelcode", string_type], ["devicetoken", list_type(string_type)], ["subscribed", bool_type]]);
}

export class PhoneNumberRequestResponse extends Record {
    constructor(result, channels, message) {
        super();
        this.result = result;
        this.channels = channels;
        this.message = message;
    }
}

export function PhoneNumberRequestResponse$reflection() {
    return record_type("ApiDataTypes.PhoneNumberRequestResponse", [], PhoneNumberRequestResponse, () => [["result", string_type], ["channels", list_type(PhoneNumberChannel$reflection())], ["message", string_type]]);
}

export class DeviceTokenRegister extends Record {
    constructor(RegisterID, DeviceType) {
        super();
        this.RegisterID = RegisterID;
        this.DeviceType = (DeviceType | 0);
    }
}

export function DeviceTokenRegister$reflection() {
    return record_type("ApiDataTypes.DeviceTokenRegister", [], DeviceTokenRegister, () => [["RegisterID", string_type], ["DeviceType", int32_type]]);
}

export class DeviceTokenRegisterV2 extends Record {
    constructor(regtoken, devicetype, channelcode, phonenumber) {
        super();
        this.regtoken = regtoken;
        this.devicetype = (devicetype | 0);
        this.channelcode = channelcode;
        this.phonenumber = phonenumber;
    }
}

export function DeviceTokenRegisterV2$reflection() {
    return record_type("ApiDataTypes.DeviceTokenRegisterV2", [], DeviceTokenRegisterV2, () => [["regtoken", string_type], ["devicetype", int32_type], ["channelcode", string_type], ["phonenumber", string_type]]);
}

export class DeviceTokenRegResponse extends Record {
    constructor(result, registeredtoken, message, openchannel, phonenumber) {
        super();
        this.result = result;
        this.registeredtoken = registeredtoken;
        this.message = message;
        this.openchannel = openchannel;
        this.phonenumber = phonenumber;
    }
}

export function DeviceTokenRegResponse$reflection() {
    return record_type("ApiDataTypes.DeviceTokenRegResponse", [], DeviceTokenRegResponse, () => [["result", string_type], ["registeredtoken", string_type], ["message", string_type], ["openchannel", bool_type], ["phonenumber", string_type]]);
}

export class UnregisterDeviceRequest extends Record {
    constructor(channelcode, phonenumber) {
        super();
        this.channelcode = channelcode;
        this.phonenumber = phonenumber;
    }
}

export function UnregisterDeviceRequest$reflection() {
    return record_type("ApiDataTypes.UnregisterDeviceRequest", [], UnregisterDeviceRequest, () => [["channelcode", string_type], ["phonenumber", string_type]]);
}

export class PushSubscription extends Record {
    constructor(phonenumber, channelcode) {
        super();
        this.phonenumber = phonenumber;
        this.channelcode = channelcode;
    }
}

export function PushSubscription$reflection() {
    return record_type("ApiDataTypes.PushSubscription", [], PushSubscription, () => [["phonenumber", string_type], ["channelcode", string_type]]);
}

export class InfoscreenChannels extends Record {
    constructor(result, channels, message) {
        super();
        this.result = result;
        this.channels = channels;
        this.message = message;
    }
}

export function InfoscreenChannels$reflection() {
    return record_type("ApiDataTypes.InfoscreenChannels", [], InfoscreenChannels, () => [["result", string_type], ["channels", list_type(ChannelEntry$reflection())], ["message", string_type]]);
}

export class ReadDistribution extends Record {
    constructor(result, readdates, message) {
        super();
        this.result = result;
        this.readdates = readdates;
        this.message = message;
    }
}

export function ReadDistribution$reflection() {
    return record_type("ApiDataTypes.ReadDistribution", [], ReadDistribution, () => [["result", string_type], ["readdates", list_type(tuple_type(class_type("System.DateTime"), int32_type))], ["message", string_type]]);
}

export class UserDeletedChannel extends Record {
    constructor(phonenumber, channelcode) {
        super();
        this.phonenumber = phonenumber;
        this.channelcode = channelcode;
    }
}

export function UserDeletedChannel$reflection() {
    return record_type("ApiDataTypes.UserDeletedChannel", [], UserDeletedChannel, () => [["phonenumber", string_type], ["channelcode", string_type]]);
}

export class NotReadList extends Record {
    constructor(result, members, message) {
        super();
        this.result = result;
        this.members = members;
        this.message = message;
    }
}

export function NotReadList$reflection() {
    return record_type("ApiDataTypes.NotReadList", [], NotReadList, () => [["result", string_type], ["members", list_type(Member$reflection())], ["message", string_type]]);
}

export class StatisticsResponse extends Record {
    constructor(NewsStats, Members) {
        super();
        this.NewsStats = NewsStats;
        this.Members = (Members | 0);
    }
}

export function StatisticsResponse$reflection() {
    return record_type("ApiDataTypes.StatisticsResponse", [], StatisticsResponse, () => [["NewsStats", array_type(NewsStat$reflection())], ["Members", int32_type]]);
}

export class APIToken extends Record {
    constructor(Key, CreatedBy, Created, Enabled, ReadPermission, WritePermission) {
        super();
        this.Key = Key;
        this.CreatedBy = CreatedBy;
        this.Created = Created;
        this.Enabled = Enabled;
        this.ReadPermission = ReadPermission;
        this.WritePermission = WritePermission;
    }
}

export function APIToken$reflection() {
    return record_type("ApiDataTypes.APIToken", [], APIToken, () => [["Key", string_type], ["CreatedBy", string_type], ["Created", string_type], ["Enabled", bool_type], ["ReadPermission", bool_type], ["WritePermission", bool_type]]);
}

export class News_NewsMeta extends Record {
    constructor(Id, Title, Author, Created, PublishAfter, PublishedUntil, IsPublished, PublishedTo, Pinned, ChannelId) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.Author = Author;
        this.Created = Created;
        this.PublishAfter = PublishAfter;
        this.PublishedUntil = PublishedUntil;
        this.IsPublished = IsPublished;
        this.PublishedTo = (PublishedTo | 0);
        this.Pinned = Pinned;
        this.ChannelId = (ChannelId | 0);
    }
}

export function News_NewsMeta$reflection() {
    return record_type("ApiDataTypes.News.NewsMeta", [], News_NewsMeta, () => [["Id", string_type], ["Title", string_type], ["Author", string_type], ["Created", string_type], ["PublishAfter", option_type(string_type)], ["PublishedUntil", option_type(string_type)], ["IsPublished", bool_type], ["PublishedTo", int32_type], ["Pinned", bool_type], ["ChannelId", int32_type]]);
}

export class News_NewsForm extends Record {
    constructor(Id, Title, Excerpt, Author, Body, Push, SMS, Published, Pinned, PublishTo, PublishAfter, PublishUntil, AllowReactions, AllowedReactions, RequireConfirmation) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.Excerpt = Excerpt;
        this.Author = Author;
        this.Body = Body;
        this.Push = Push;
        this.SMS = SMS;
        this.Published = Published;
        this.Pinned = Pinned;
        this.PublishTo = (PublishTo | 0);
        this.PublishAfter = PublishAfter;
        this.PublishUntil = PublishUntil;
        this.AllowReactions = AllowReactions;
        this.AllowedReactions = AllowedReactions;
        this.RequireConfirmation = RequireConfirmation;
    }
}

export function News_NewsForm$reflection() {
    return record_type("ApiDataTypes.News.NewsForm", [], News_NewsForm, () => [["Id", option_type(string_type)], ["Title", string_type], ["Excerpt", string_type], ["Author", string_type], ["Body", string_type], ["Push", bool_type], ["SMS", bool_type], ["Published", bool_type], ["Pinned", bool_type], ["PublishTo", int32_type], ["PublishAfter", option_type(string_type)], ["PublishUntil", option_type(string_type)], ["AllowReactions", bool_type], ["AllowedReactions", array_type(int32_type)], ["RequireConfirmation", bool_type]]);
}

export class News_NewsEntry extends Record {
    constructor(Id, Title, Excerpt, Author, Body, Created, Push, Published, Pinned, PublishTo, PublishAfter, PublishUntil, AllowReactions, AllowedReactions, RequireConfirmation) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.Excerpt = Excerpt;
        this.Author = Author;
        this.Body = Body;
        this.Created = Created;
        this.Push = Push;
        this.Published = Published;
        this.Pinned = Pinned;
        this.PublishTo = (PublishTo | 0);
        this.PublishAfter = PublishAfter;
        this.PublishUntil = PublishUntil;
        this.AllowReactions = AllowReactions;
        this.AllowedReactions = AllowedReactions;
        this.RequireConfirmation = RequireConfirmation;
    }
}

export function News_NewsEntry$reflection() {
    return record_type("ApiDataTypes.News.NewsEntry", [], News_NewsEntry, () => [["Id", string_type], ["Title", string_type], ["Excerpt", string_type], ["Author", string_type], ["Body", string_type], ["Created", string_type], ["Push", bool_type], ["Published", bool_type], ["Pinned", bool_type], ["PublishTo", int32_type], ["PublishAfter", option_type(string_type)], ["PublishUntil", option_type(string_type)], ["AllowReactions", bool_type], ["AllowedReactions", array_type(int32_type)], ["RequireConfirmation", bool_type]]);
}

export class Statistics_AppInstalls extends Record {
    constructor(Installed, NumMembers) {
        super();
        this.Installed = (Installed | 0);
        this.NumMembers = (NumMembers | 0);
    }
}

export function Statistics_AppInstalls$reflection() {
    return record_type("ApiDataTypes.Statistics.AppInstalls", [], Statistics_AppInstalls, () => [["Installed", int32_type], ["NumMembers", int32_type]]);
}

export class Statistics_NewsReactions extends Record {
    constructor(Reaction, Count) {
        super();
        this.Reaction = (Reaction | 0);
        this.Count = (Count | 0);
    }
}

export function Statistics_NewsReactions$reflection() {
    return record_type("ApiDataTypes.Statistics.NewsReactions", [], Statistics_NewsReactions, () => [["Reaction", int32_type], ["Count", int32_type]]);
}

export class Billing_InvoiceLine extends Record {
    constructor(Description, Quantity, UnitCost, LineTotal) {
        super();
        this.Description = Description;
        this.Quantity = (Quantity | 0);
        this.UnitCost = UnitCost;
        this.LineTotal = LineTotal;
    }
}

export function Billing_InvoiceLine$reflection() {
    return record_type("ApiDataTypes.Billing.InvoiceLine", [], Billing_InvoiceLine, () => [["Description", string_type], ["Quantity", int32_type], ["UnitCost", float64_type], ["LineTotal", float64_type]]);
}

export class Billing_InvoiceDiscount extends Record {
    constructor(Percentage, Description) {
        super();
        this.Percentage = Percentage;
        this.Description = Description;
    }
}

export function Billing_InvoiceDiscount$reflection() {
    return record_type("ApiDataTypes.Billing.InvoiceDiscount", [], Billing_InvoiceDiscount, () => [["Percentage", float64_type], ["Description", string_type]]);
}

export class Billing_Invoice extends Record {
    constructor(PeriodStart, PeriodEnd, IsDemo, Lines, Discounts, Total) {
        super();
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.IsDemo = IsDemo;
        this.Lines = Lines;
        this.Discounts = Discounts;
        this.Total = Total;
    }
}

export function Billing_Invoice$reflection() {
    return record_type("ApiDataTypes.Billing.Invoice", [], Billing_Invoice, () => [["PeriodStart", string_type], ["PeriodEnd", string_type], ["IsDemo", bool_type], ["Lines", array_type(Billing_InvoiceLine$reflection())], ["Discounts", array_type(Billing_InvoiceDiscount$reflection())], ["Total", float64_type]]);
}

export class Provisioner_SubscriptionStatus extends Record {
    constructor(IsDemo, DemoExpiry, Renews) {
        super();
        this.IsDemo = IsDemo;
        this.DemoExpiry = DemoExpiry;
        this.Renews = Renews;
    }
}

export function Provisioner_SubscriptionStatus$reflection() {
    return record_type("ApiDataTypes.Provisioner.SubscriptionStatus", [], Provisioner_SubscriptionStatus, () => [["IsDemo", bool_type], ["DemoExpiry", option_type(string_type)], ["Renews", bool_type]]);
}

export class SharePoint_ReactionConfig extends Record {
    constructor(AllowByDefault, Default) {
        super();
        this.AllowByDefault = AllowByDefault;
        this.Default = Default;
    }
}

export function SharePoint_ReactionConfig$reflection() {
    return record_type("ApiDataTypes.SharePoint.ReactionConfig", [], SharePoint_ReactionConfig, () => [["AllowByDefault", bool_type], ["Default", array_type(int32_type)]]);
}

export class SharePoint_AccessToken extends Record {
    constructor(TokenType, ExpiresIn, NotBefore, ExpiresOn, Resource, AccessToken, RefreshToken) {
        super();
        this.TokenType = TokenType;
        this.ExpiresIn = ExpiresIn;
        this.NotBefore = NotBefore;
        this.ExpiresOn = ExpiresOn;
        this.Resource = Resource;
        this.AccessToken = AccessToken;
        this.RefreshToken = RefreshToken;
    }
}

export function SharePoint_AccessToken$reflection() {
    return record_type("ApiDataTypes.SharePoint.AccessToken", [], SharePoint_AccessToken, () => [["TokenType", string_type], ["ExpiresIn", string_type], ["NotBefore", string_type], ["ExpiresOn", string_type], ["Resource", string_type], ["AccessToken", string_type], ["RefreshToken", string_type]]);
}

export class SharePoint_SharePointConfig extends Record {
    constructor(MainDomain, SpDomain, NewsList, ClientId, ClientSecret, RedirectUri, SiteRealm, Filters, AccessToken, NumNewsItems, MaxDaysAgo, Configured, FetchType) {
        super();
        this.MainDomain = MainDomain;
        this.SpDomain = SpDomain;
        this.NewsList = NewsList;
        this.ClientId = ClientId;
        this.ClientSecret = ClientSecret;
        this.RedirectUri = RedirectUri;
        this.SiteRealm = SiteRealm;
        this.Filters = Filters;
        this.AccessToken = AccessToken;
        this.NumNewsItems = (NumNewsItems | 0);
        this.MaxDaysAgo = (MaxDaysAgo | 0);
        this.Configured = Configured;
        this.FetchType = FetchType;
    }
}

export function SharePoint_SharePointConfig$reflection() {
    return record_type("ApiDataTypes.SharePoint.SharePointConfig", [], SharePoint_SharePointConfig, () => [["MainDomain", string_type], ["SpDomain", string_type], ["NewsList", string_type], ["ClientId", string_type], ["ClientSecret", string_type], ["RedirectUri", string_type], ["SiteRealm", string_type], ["Filters", list_type(string_type)], ["AccessToken", SharePoint_AccessToken$reflection()], ["NumNewsItems", int32_type], ["MaxDaysAgo", int32_type], ["Configured", bool_type], ["FetchType", string_type]]);
}

