import { toString as toString_1, Union, Record } from "../fable_modules/fable-library.3.7.22/Types.js";
import { Channel, ChannelSettings, GenericMessage$reflection, ChannelSettings$reflection, Channel$reflection, UserSession$reflection } from "../Shared/ApiDataTypes.js";
import { array_type, equals, obj_type, int32_type, union_type, record_type, lambda_type, unit_type, string_type, bool_type, option_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { checkFetchError } from "../Utils.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.22/String.js";
import { basePath } from "../Pages.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { props as props_27, authProps } from "../Promises.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_2, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_2 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_2 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { equals as equals_1, createObj, int32ToString, uncurry } from "../fable_modules/fable-library.3.7.22/Util.js";
import { Channel_Delete } from "../Shared/Urls.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { HTMLAttr, DOMAttr } from "../fable_modules/Fable.React.7.4.0/Fable.React.Props.fs.js";
import { Color_IColor, Size_ISize, Text_p, Common_GenericOption } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { Option as Option_1, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { h5 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { Option as Option_2, icon } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Option as Option_3, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_4, label } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { input } from "../fable_modules/Fulma.2.16.0/Elements/Form/Checkbox.fs.js";
import { input as input_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option as Option_5, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { parse } from "../fable_modules/fable-library.3.7.22/Int32.js";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../fable_modules/Fable.React.7.4.0/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useContext_37FA55CF, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505, useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { append as append_1, map as map_1 } from "../fable_modules/fable-library.3.7.22/Array.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import { demoLimitNotification, loadingIndicatorLarge, loadingIndicatorSmall, errorBanner } from "../ViewHelpers.js";
import DialogContent from "@material-ui/core/DialogContent";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { ChannelAccess, ChannelSource_FromInt_Z524259A4, ChannelAccess_FromInt_Z524259A4 } from "../Shared/Shared.js";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Settings_3 from "@material-ui/icons/Settings";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { globalCtx } from "../Context.js";
import { Channel_fetchChannels } from "../Promises.js";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import { Form } from "./ChannelForm.js";
import { Settings as Settings_4 } from "./SharePointSettings.js";
import { Access as Access_2 } from "./ChannelAccess.js";
import { Feed } from "../News/NewsFeed.js";

class ChannelSettings_Model extends Record {
    constructor(UserSession, Channel, Settings, Processing, Deleting, ErrorMsg, ValidMaxDays, OnClose) {
        super();
        this.UserSession = UserSession;
        this.Channel = Channel;
        this.Settings = Settings;
        this.Processing = Processing;
        this.Deleting = Deleting;
        this.ErrorMsg = ErrorMsg;
        this.ValidMaxDays = ValidMaxDays;
        this.OnClose = OnClose;
    }
}

function ChannelSettings_Model$reflection() {
    return record_type("Channels.ChannelSettings.Model", [], ChannelSettings_Model, () => [["UserSession", UserSession$reflection()], ["Channel", Channel$reflection()], ["Settings", option_type(ChannelSettings$reflection())], ["Processing", bool_type], ["Deleting", bool_type], ["ErrorMsg", option_type(string_type)], ["ValidMaxDays", bool_type], ["OnClose", lambda_type(bool_type, unit_type)]]);
}

class ChannelSettings_Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SettingsResponse", "SetMaxDays", "UpdateMaxDays", "Commit", "CommitResponse", "ToggleDelete", "CommitDelete", "DeleteResponse", "InvalidateMaxDays"];
    }
}

function ChannelSettings_Message$reflection() {
    return union_type("Channels.ChannelSettings.Message", [], ChannelSettings_Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ChannelSettings$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", ChannelSettings$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], [["Item", int32_type]], [["Item", ChannelSettings$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function ChannelSettings_init(x, y, z) {
    return new ChannelSettings_Model(x, y, void 0, false, false, void 0, true, z);
}

function ChannelSettings_update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const matchValue = model.Settings;
            if (matchValue == null) {
                return model;
            }
            else {
                return new ChannelSettings_Model(model.UserSession, model.Channel, new ChannelSettings(msg.fields[0], matchValue.MaxDays), model.Processing, model.Deleting, model.ErrorMsg, true, model.OnClose);
            }
        }
        case 2: {
            const matchValue_1 = model.Settings;
            if (matchValue_1 == null) {
                return model;
            }
            else {
                return new ChannelSettings_Model(model.UserSession, model.Channel, new ChannelSettings(matchValue_1.UseMaxDays, msg.fields[0]), model.Processing, model.Deleting, model.ErrorMsg, model.ValidMaxDays, model.OnClose);
            }
        }
        case 3: {
            if (model.ValidMaxDays ? true : (!msg.fields[0].UseMaxDays)) {
                return new ChannelSettings_Model(model.UserSession, model.Channel, model.Settings, true, model.Deleting, void 0, model.ValidMaxDays, model.OnClose);
            }
            else {
                return model;
            }
        }
        case 4: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                checkFetchError(res_1.fields[0]);
                return new ChannelSettings_Model(model.UserSession, model.Channel, model.Settings, false, model.Deleting, "Noe gikk galt. Kunne ikke oppdatere kanal innstillinger.", model.ValidMaxDays, model.OnClose);
            }
            else {
                const x_4 = res_1.fields[0];
                if (x_4.result === "success") {
                    model.OnClose(false);
                    return model;
                }
                else {
                    return new ChannelSettings_Model(model.UserSession, model.Channel, model.Settings, false, model.Deleting, x_4.message, model.ValidMaxDays, model.OnClose);
                }
            }
        }
        case 5: {
            return new ChannelSettings_Model(model.UserSession, model.Channel, model.Settings, model.Processing, !model.Deleting, model.ErrorMsg, model.ValidMaxDays, model.OnClose);
        }
        case 6: {
            return new ChannelSettings_Model(model.UserSession, model.Channel, model.Settings, true, model.Deleting, void 0, model.ValidMaxDays, model.OnClose);
        }
        case 7: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                checkFetchError(res_2.fields[0]);
                return new ChannelSettings_Model(model.UserSession, model.Channel, model.Settings, false, model.Deleting, "Noe gikk galt. Kunne ikke slette kanal.", model.ValidMaxDays, model.OnClose);
            }
            else {
                const x_5 = res_2.fields[0];
                if (x_5.result === "success") {
                    model.OnClose(true);
                    return model;
                }
                else {
                    return new ChannelSettings_Model(model.UserSession, model.Channel, model.Settings, false, model.Deleting, x_5.message, model.ValidMaxDays, model.OnClose);
                }
            }
        }
        case 8: {
            return new ChannelSettings_Model(model.UserSession, model.Channel, model.Settings, model.Processing, model.Deleting, model.ErrorMsg, false, model.OnClose);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return model;
            }
            else {
                return new ChannelSettings_Model(model.UserSession, model.Channel, res.fields[0], model.Processing, model.Deleting, model.ErrorMsg, model.ValidMaxDays, model.OnClose);
            }
        }
    }
}

function ChannelSettings_getSettings(tkn, chnId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("%s/api/channel/settings/%i"))(basePath)(chnId);
        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => {
            let properties, data_1, caseStrategy_2, extra_2;
            const decoder = Auto_generateBoxedDecoder_79988AEF(ChannelSettings$reflection(), void 0, void 0);
            return ((properties = authProps(tkn), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(properties, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(ChannelSettings$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(ChannelSettings$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_5) => (new FSharpResult$2(1, new FetchError(3, arg_5)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))));
        })).then((_arg_2) => {
            dispatch(new ChannelSettings_Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
}

function ChannelSettings_setSettings(tkn, chnId, settings, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("%s/api/channel/settings/%i"))(basePath)(chnId);
        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => {
            let properties, data_1, caseStrategy_3, extra_3;
            const body = Auto_generateBoxedEncoder_Z20B7B430(ChannelSettings$reflection(), void 0, void 0, void 0)(settings);
            const decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0);
            return ((properties = authProps(tkn), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(properties, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_5) => (new FSharpResult$2(1, new FetchError(3, arg_5)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))));
        })).then((_arg_2) => {
            dispatch(new ChannelSettings_Message(4, _arg_2));
            return Promise.resolve();
        });
    }));
}

function ChannelSettings_deleteChannel(tkn, chn, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new ChannelSettings_Message(6));
        const requestPath = basePath + Channel_Delete;
        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => {
            let properties, data_1, caseStrategy_3, extra_3;
            const body = Auto_generateBoxedEncoder_Z20B7B430(Channel$reflection(), void 0, void 0, void 0)(chn);
            const decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0);
            return ((properties = authProps(tkn), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(properties, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))));
        })).then((_arg_2) => {
            dispatch(new ChannelSettings_Message(7, _arg_2));
            return Promise.resolve();
        });
    }));
}

function ChannelSettings_view(model, dispatch) {
    let props_14, children_14;
    return modal(singleton(new Option(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, (_arg) => {
        model.OnClose(false);
    })))), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton(Card_title(empty(), singleton(toText(printf("Innstillinger for %s"))(model.Channel.Name))))), Card_body(empty(), singleton((props_14 = [["style", {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
    }]], (children_14 = toList(delay(() => {
        const matchValue = model.Settings;
        if (matchValue == null) {
            return singleton_1(button(singleton(new Option_1(13, true)), empty()));
        }
        else {
            const settings = matchValue;
            return model.Deleting ? append(singleton_1(h5(empty())(singleton(toText(printf("Ønsker du å slette kanalen \"%s\"?"))(model.Channel.Name)))), delay(() => append(singleton_1(Text_p(singleton(new Common_GenericOption(1, singleton(["style", {
                marginBottom: "20px",
            }]))), singleton("Publiserte nyheter og innsamlet lesestatistikk vil gå tapt."))), delay(() => {
                let children_8;
                return append(singleton_1((children_8 = [button(ofArray([new Option_1(17, singleton(["style", {
                    marginRight: "10px",
                }])), new Option_1(1, new Size_ISize(0)), new Option_1(0, new Color_IColor(8)), new Option_1(13, model.Processing), new Option_1(18, (_arg_1) => {
                    if (!model.Processing) {
                        const pr = ChannelSettings_deleteChannel(model.UserSession.Token, model.Channel, dispatch);
                        pr.then();
                    }
                })]), ofArray([icon(ofArray([new Option_2(1), new Option_2(0, new Size_ISize(0))]), singleton(react.createElement("i", {
                    className: "fas fa-check",
                }))), react.createElement("span", {}, "Slett")])), button(ofArray([new Option_1(1, new Size_ISize(0)), new Option_1(18, (_arg_2) => {
                    dispatch(new ChannelSettings_Message(5));
                }), new Option_1(13, model.Processing)]), ofArray([icon(ofArray([new Option_2(1), new Option_2(0, new Size_ISize(0))]), singleton(react.createElement("i", {
                    className: "fas fa-times",
                }))), react.createElement("span", {}, "Avbryt")]))], react.createElement("div", {}, ...children_8))), delay(() => {
                    let props_10;
                    const matchValue_1 = model.ErrorMsg;
                    if (matchValue_1 != null) {
                        const errMsg = matchValue_1;
                        return singleton_1((props_10 = [["style", {
                            fontSize: "0.8rem",
                        }]], react.createElement("p", keyValueList(props_10, 1), errMsg)));
                    }
                    else {
                        return empty_1();
                    }
                }));
            })))) : append(singleton_1(div(singleton(new Option_3(11, singleton(["style", {
                textAlign: "center",
            }]))), ofArray([label(singleton(new Option_4(3, singleton(["style", {
                fontSize: "0.7rem",
            }]))), singleton("Vis nyheter kun i ett begrenset antall dager")), input(singleton(new Common_GenericOption(1, ofArray([new HTMLAttr(62, settings.UseMaxDays), new DOMAttr(9, (_arg_3) => {
                dispatch(new ChannelSettings_Message(1, !settings.UseMaxDays));
            })]))))]))), delay(() => append(settings.UseMaxDays ? singleton_1(div(singleton(new Option_3(11, singleton(["style", {
                textAlign: "center",
            }]))), ofArray([label(singleton(new Option_4(3, singleton(["style", {
                fontSize: "0.7rem",
            }]))), singleton("Antall dager bak i tid nyheter skal være tilgjengelig")), input_1(ofArray([new Option_5(1, new IInputType(0)), new Option_5(10, int32ToString(settings.MaxDays)), new Option_5(4, !settings.UseMaxDays), new Option_5(0, new Size_ISize(0)), new Option_5(15, singleton(["style", {
                maxWidth: "200px",
            }])), new Option_5(2, model.ValidMaxDays ? (new Color_IColor(20)) : (new Color_IColor(8))), new Option_5(13, (ev) => {
                try {
                    dispatch(new ChannelSettings_Message(2, parse(Browser_Types_Event__Event_get_Value(ev), 511, false, 32)));
                }
                catch (matchValue_2) {
                    dispatch(new ChannelSettings_Message(8));
                }
            })]))]))) : empty_1(), delay(() => {
                let props_12, children_12;
                return singleton_1((props_12 = [["style", {
                    textAlign: "center",
                    marginTop: "20px",
                }]], (children_12 = [button(ofArray([new Option_1(17, singleton(["style", {
                    marginLeft: "10px",
                }])), new Option_1(1, new Size_ISize(0)), new Option_1(13, model.Processing), new Option_1(18, (_arg_4) => {
                    if ((!model.Processing) && (model.ValidMaxDays ? true : (!settings.UseMaxDays))) {
                        const pr_1 = ChannelSettings_setSettings(model.UserSession.Token, model.Channel.Id, settings, dispatch);
                        pr_1.then();
                    }
                })]), singleton("Lagre")), button(ofArray([new Option_1(17, singleton(["style", {
                    marginLeft: "10px",
                }])), new Option_1(1, new Size_ISize(0)), new Option_1(0, new Color_IColor(8)), new Option_1(13, model.Processing), new Option_1(18, (_arg_5) => {
                    if (!model.Processing) {
                        dispatch(new ChannelSettings_Message(5));
                    }
                })]), singleton("Slett")), button(ofArray([new Option_1(17, singleton(["style", {
                    marginLeft: "10px",
                }])), new Option_1(1, new Size_ISize(0)), new Option_1(13, model.Processing), new Option_1(18, (_arg_6) => {
                    model.OnClose(false);
                })]), singleton("Lukk"))], react.createElement("div", keyValueList(props_12, 1), ...children_12))));
            }))));
        }
    })), react.createElement("div", keyValueList(props_14, 1), ...children_14))))), Card_foot(empty(), empty())]))]));
}

export function ChannelSettings_channelSettings(x) {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const patternInput = useReact_useReducer_2B9E6EA0(ChannelSettings_update, ChannelSettings_init(props.us, props.chn, props.onClose));
        const model_1 = patternInput[0];
        const dispatch = patternInput[1];
        useReact_useEffect_Z101E1A95(() => {
            const pr = ChannelSettings_getSettings(model_1.UserSession.Token, model_1.Channel.Id, dispatch);
            pr.then();
        }, []);
        return ChannelSettings_view(model_1, dispatch);
    }, void 0, uncurry(2, void 0), void 0, "channelSettings", "/builds/serit/mobilenews/tenant/src/Client/Channel/Channels.fs", 257)(x);
}

class Model extends Record {
    constructor(Channels, AddForm, EditForm, Access, Feed, ErrorMsg) {
        super();
        this.Channels = Channels;
        this.AddForm = AddForm;
        this.EditForm = EditForm;
        this.Access = Access;
        this.Feed = Feed;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Channels.Model", [], Model, () => [["Channels", option_type(array_type(Channel$reflection()))], ["AddForm", bool_type], ["EditForm", option_type(Channel$reflection())], ["Access", option_type(Channel$reflection())], ["Feed", option_type(Channel$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChannelsResponse", "ToggleAddForm", "ToggleEdit", "ToggleAccess", "ToggleFeed", "UpdateChannelAccess", "ChannelAdded", "ChannelDeleted", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Channels.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Channel$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Channel$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], [["Item", option_type(Channel$reflection())]], [["Item", option_type(Channel$reflection())]], [["Item", option_type(Channel$reflection())]], [["Item1", int32_type], ["Item2", int32_type]], [["Item", Channel$reflection()]], [["Item", int32_type]], []]);
}

function init() {
    return new Model(void 0, false, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    let array_1;
    switch (msg.tag) {
        case 1: {
            return new Model(model.Channels, msg.fields[0], model.EditForm, model.Access, model.Feed, model.ErrorMsg);
        }
        case 2: {
            return new Model(model.Channels, model.AddForm, msg.fields[0], model.Access, model.Feed, model.ErrorMsg);
        }
        case 3: {
            return new Model(model.Channels, model.AddForm, model.EditForm, msg.fields[0], model.Feed, model.ErrorMsg);
        }
        case 4: {
            return new Model(model.Channels, model.AddForm, model.EditForm, model.Access, msg.fields[0], model.ErrorMsg);
        }
        case 5: {
            const matchValue = model.Channels;
            if (matchValue != null) {
                return new Model(map_1((x_5) => {
                    if (x_5.Id === msg.fields[0]) {
                        return new Channel(x_5.Id, x_5.Name, x_5.Code, x_5.Access, x_5.SourceType, msg.fields[1], x_5.NumNews);
                    }
                    else {
                        return x_5;
                    }
                }, matchValue), model.AddForm, model.EditForm, void 0, model.Feed, model.ErrorMsg);
            }
            else {
                return new Model(model.Channels, model.AddForm, model.EditForm, void 0, model.Feed, model.ErrorMsg);
            }
        }
        case 6: {
            const x_7 = msg.fields[0];
            const matchValue_1 = model.Channels;
            if (matchValue_1 != null) {
                return new Model(append_1([x_7], matchValue_1), false, model.EditForm, model.Access, model.Feed, model.ErrorMsg);
            }
            else {
                return new Model([x_7], false, model.EditForm, model.Access, model.Feed, model.ErrorMsg);
            }
        }
        case 7: {
            const matchValue_2 = model.Channels;
            if (matchValue_2 != null) {
                return new Model((array_1 = matchValue_2, array_1.filter((y_1) => (y_1.Id !== msg.fields[0]))), model.AddForm, model.EditForm, model.Access, model.Feed, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 8: {
            return new Model(model.Channels, model.AddForm, model.EditForm, model.Access, model.Feed, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return new Model(model.Channels, model.AddForm, model.EditForm, model.Access, model.Feed, "Kunne ikke laste kanaler. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(res.fields[0], model.AddForm, model.EditForm, model.Access, model.Feed, model.ErrorMsg);
            }
        }
    }
}

export function DeleteForm(deleteFormInputProps) {
    let elements_2, children, children_1;
    const onClose = deleteFormInputProps.onClose;
    const channelName = deleteFormInputProps.channelName;
    const channelId = deleteFormInputProps.channelId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_4, v) => {
        onClose(false);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Slett kanal"]]), (children = ofArray([MuiHelpers_createElement(DialogContentText, [["children", `Er du sikker på at du ønsker å slette kanalen "${channelName}"? Dette vil slette alle tilhørende nyheter og data i Enterprise Mobile News og kan ikke reverseres.`]]), errorBanner(patternInput_1[0], () => {
        setErrMsg(void 0);
    })]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (patternInput[0] ? singleton_1(loadingIndicatorSmall()) : append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Avbryt"], ["onClick", (_arg_6) => {
        onClose(false);
    }]])), delay(() => singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Slett"], ["onClick", (_arg_7) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let props, decoder, data_1, caseStrategy_2, extra_2;
            const requestPath = `/api/channel/${channelId}`;
            return ((props = props_27(), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_2(promise_2, PromiseBuilder__Delay_62FBFDE1_2(promise_2, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 1) {
                    checkFetchError(res.fields[0]);
                    setErrMsg("Kunne ikke slette kanal. Vennligst prøv igjen senere.");
                    return Promise.resolve();
                }
                else {
                    const x_1 = res.fields[0];
                    if (x_1.result === "success") {
                        onClose(true);
                        return Promise.resolve();
                    }
                    else {
                        patternInput[1](false);
                        setErrMsg(x_1.message);
                        return Promise.resolve();
                    }
                }
            });
        }));
        pr_1.then();
    }]]))))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function DrawChannelBox(drawChannelBoxInputProps) {
    let elements_1;
    const onDelete = drawChannelBoxInputProps.onDelete;
    const onEdit = drawChannelBoxInputProps.onEdit;
    const onAccess = drawChannelBoxInputProps.onAccess;
    const onFeed = drawChannelBoxInputProps.onFeed;
    const x = drawChannelBoxInputProps.x;
    const access = ChannelAccess_FromInt_Z524259A4(x.Access);
    const source = ChannelSource_FromInt_Z524259A4(x.SourceType);
    const anchor = useReact_useRef_1505(void 0);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setMenuOpen = patternInput[1];
    const menuOpen = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setDeleteForm = patternInput_1[1];
    return MuiHelpers_createElement(Paper, [["style", {
        padding: 10,
        display: "flex",
        flexDirection: "column",
        width: 280,
        margin: 10,
    }], ["elevation", 1], (elements_1 = toList(delay(() => append(patternInput_1[0] ? singleton_1(createElement(DeleteForm, {
        channelId: x.Id,
        channelName: x.Name,
        onClose: (didDelete) => {
            setDeleteForm(false);
            onDelete();
        },
    })) : empty_1(), delay(() => append(singleton_1(createElement("span", {
        style: {
            fontSize: 1.2 + "rem",
        },
        children: x.Name,
    })), delay(() => {
        let children;
        return append(singleton_1(MuiHelpers_createElement(Tooltip, [["title", toText(printf("Kanalkode %s"))(x.Code)], ["children", (children = singleton(createElement("span", {
            style: {
                letterSpacing: 1,
                fontWeight: "bold",
                fontSize: 0.8 + "rem",
            },
            children: x.Code,
        })), createElement("span", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))]])), delay(() => {
            let elems_2, elems, arg_1, elems_1;
            return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                margin: ((5 + "px ") + 0) + "px",
            }], (elems_2 = [createElement("span", createObj(ofArray([["style", {
                fontSize: 0.8 + "rem",
                color: "#9a9a9a",
            }], (elems = [createElement("span", {
                style: {
                    marginRight: 5,
                },
                className: equals_1(access, new ChannelAccess(0)) ? "fas fa-lock" : "fas fa-lock-open",
            }), toString_1(access)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), MuiHelpers_createElement(Tooltip, [["title", (arg_1 = toString_1(source), toText(printf("Nyhetskilde %s"))(arg_1))], ["children", createElement("span", createObj(ofArray([["style", {
                fontSize: 0.8 + "rem",
                color: "#9a9a9a",
            }], (elems_1 = [createElement("span", {
                style: {
                    marginRight: 5,
                },
                className: "fas fa-newspaper",
            }), toString_1(source)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                let elems_3;
                return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 15,
                }], (elems_3 = [createElement("span", {
                    style: {
                        fontSize: 2 + "rem",
                        fontWeight: "bold",
                        color: "#363636",
                    },
                    children: x.NumNews,
                }), createElement("span", {
                    style: {
                        fontSize: 0.8 + "rem",
                        color: "#9a9a9a",
                    },
                    children: "Publiserte nyheter",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                    let elems_4;
                    return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: ((15 + "px ") + 0) + "px",
                    }], (elems_4 = [createElement("span", {
                        style: {
                            fontSize: 2 + "rem",
                            fontWeight: "bold",
                            color: "#363636",
                        },
                        children: x.NumMembers,
                    }), createElement("span", {
                        style: {
                            fontSize: 0.8 + "rem",
                            color: "#9a9a9a",
                        },
                        children: "Medlemmer med tilgang",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                        let elems_7, children_2, elems_6, props_19, elements;
                        return singleton_1(createElement("div", createObj(ofArray([["style", {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: 10,
                        }], (elems_7 = [(children_2 = singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Nyheter"], ["onClick", (_arg) => {
                            onFeed(x);
                        }]])), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                        })), createElement("div", createObj(ofArray([["style", {
                            display: "flex",
                            justifyContent: "flex-end",
                        }], (elems_6 = [MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Tilgang"], ["style", {
                            marginRight: 5,
                        }], ["onClick", (_arg_1) => {
                            onAccess(x);
                        }]]), MuiHelpers_createElement(IconButton, [["size", "small"], ["ref", anchor], ["onClick", (_arg_2) => {
                            setMenuOpen(!menuOpen);
                        }], ["children", (props_19 = {}, react.createElement(Settings_3, props_19))]]), MuiHelpers_createElement(Menu, [["open", menuOpen], ["onClose", (_arg_4, v) => {
                            setMenuOpen(false);
                        }], ["anchorEl", anchor.current], (elements = [MuiHelpers_createElement(MenuItem, [["children", "Rediger kanal"], ["onClick", (_arg_5) => {
                            onEdit(x);
                            setMenuOpen(false);
                        }]]), MuiHelpers_createElement(MenuItem, [["children", "Slett kanal"], ["onClick", (_arg_6) => {
                            setDeleteForm(true);
                            setMenuOpen(false);
                        }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))));
                    }));
                }));
            }));
        }));
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]);
}

function drawChannelList(model, dispatch) {
    let elems_2;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
    }], (elems_2 = toList(delay(() => {
        let elems_1, elems;
        const matchValue = model.Channels;
        if (matchValue != null) {
            const c = matchValue;
            return append((c.length === 0) ? singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
                minHeight: 50 + "vh",
            }], (elems_1 = [createElement("span", {
                style: {
                    fontStyle: "italic",
                    fontSize: 1.2 + "rem",
                },
                children: "Ingen kanaler opprettet enda",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty_1(), delay(() => singleton_1(map_1((x) => createElement(DrawChannelBox, {
                x: x,
                onFeed: (c_1) => {
                    dispatch(new Message(4, c_1));
                },
                onAccess: (c_2) => {
                    dispatch(new Message(3, c_2));
                },
                onEdit: (c_3) => {
                    dispatch(new Message(2, c_3));
                },
                onDelete: () => {
                    dispatch(new Message(7, x.Id));
                },
            }), c))));
        }
        else {
            return singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
            }], (elems = [loadingIndicatorLarge()], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function Channels() {
    let elems_1;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    let isDemo;
    const matchValue = useReact_useContext_37FA55CF(globalCtx).Session;
    let pattern_matching_result;
    if (matchValue != null) {
        if (matchValue.Subscription.IsDemo) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            isDemo = true;
            break;
        }
        case 1: {
            isDemo = false;
            break;
        }
    }
    useReact_useEffectOnce_3A5B6456(() => {
        Channel_fetchChannels((arg_1) => {
            dispatch(new Message(0, arg_1));
        });
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    }], (elems_1 = toList(delay(() => {
        let c, elems;
        const matchValue_1 = model_1.Feed;
        if (matchValue_1 == null) {
            const matchValue_2 = model_1.Access;
            if (matchValue_2 == null) {
                const matchValue_3 = model_1.EditForm;
                if (matchValue_3 == null) {
                    let isDemoBlocked;
                    if (!isDemo) {
                        isDemoBlocked = false;
                    }
                    else {
                        const matchValue_5 = model_1.Channels;
                        let pattern_matching_result_1;
                        if (matchValue_5 != null) {
                            if ((c = matchValue_5, c.length > 1)) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                        switch (pattern_matching_result_1) {
                            case 0: {
                                isDemoBlocked = true;
                                break;
                            }
                            case 1: {
                                isDemoBlocked = false;
                                break;
                            }
                        }
                    }
                    return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: 10 + "px ",
                    }], (elems = toList(delay(() => {
                        let props_1, props_2;
                        return append(singleton_1(MuiHelpers_createElement(Button, [["disabled", isDemoBlocked], ["startIcon", model_1.AddForm ? ((props_1 = {}, react.createElement(Close, props_1))) : ((props_2 = {}, react.createElement(Add, props_2)))], ["children", model_1.AddForm ? "Avbryt" : "Legg til Kanal"], ["onClick", (_arg_3) => {
                            dispatch(new Message(1, !model_1.AddForm));
                        }]])), delay(() => (isDemoBlocked ? singleton_1(demoLimitNotification("Du har nådd grensen for antall kanaler i demo")) : empty_1())));
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => (model_1.AddForm ? singleton_1(createElement(Form, {
                        updating: void 0,
                        channels: model_1.Channels,
                        onClose: (newChannel) => {
                            if (newChannel != null) {
                                dispatch(new Message(6, newChannel));
                            }
                            else {
                                dispatch(new Message(1, false));
                            }
                        },
                    })) : singleton_1(drawChannelList(model_1, dispatch)))));
                }
                else {
                    const ef = matchValue_3;
                    return (ef.SourceType === 2) ? singleton_1(createElement(Settings_4, {
                        channelId: ef.Id,
                        onClose: () => {
                            dispatch(new Message(2, void 0));
                        },
                    })) : singleton_1(createElement("span", {
                        children: ["Ingen innstillinger"],
                    }));
                }
            }
            else {
                const channel_1 = matchValue_2;
                return singleton_1(createElement(Access_2, {
                    channel: channel_1,
                    onClose: (updatedAccess) => {
                        if (updatedAccess != null) {
                            dispatch(new Message(5, channel_1.Id, updatedAccess));
                        }
                        else {
                            dispatch(new Message(3, void 0));
                        }
                    },
                }));
            }
        }
        else {
            const channel = matchValue_1;
            return singleton_1(createElement(Feed, {
                channel: channel,
                onClose: () => {
                    dispatch(new Message(4, void 0));
                },
            }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

