import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { GenericMessage$reflection, Infoscreen, Infoscreen$reflection } from "./Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, string_type, bool_type, option_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { map as map_1, append } from "./fable_modules/fable-library.3.7.22/Array.js";
import { checkFetchError } from "./Utils.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useContext_37FA55CF, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library.3.7.22/List.js";
import DialogContent from "@material-ui/core/DialogContent";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { empty as empty_1, append as append_1, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { demoLimitNotification, snackError, loadingIndicatorLarge, loadingIndicatorSmall } from "./ViewHelpers.js";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Screens_fetchAllScreens, props as props_15 } from "./Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { map, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Screens_Delete, Screens_Add } from "./Shared/Urls.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Paper from "@material-ui/core/Paper";
import { createElement } from "react";
import * as react from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ScreenShare from "@material-ui/icons/ScreenShare";
import { basePath } from "./Pages.js";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { globalCtx } from "./Context.js";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

class Model extends Record {
    constructor(Screens, AddForm, DeleteForm, ErrorMsg) {
        super();
        this.Screens = Screens;
        this.AddForm = AddForm;
        this.DeleteForm = DeleteForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Infoscreen.Model", [], Model, () => [["Screens", option_type(array_type(Infoscreen$reflection()))], ["AddForm", bool_type], ["DeleteForm", option_type(Infoscreen$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ScreensResponse", "ToggleAdd", "ToggleDelete", "ScreenAdded", "ScreenDeleted", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Infoscreen.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Infoscreen$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Infoscreen$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], [["Item", option_type(Infoscreen$reflection())]], [["Item", Infoscreen$reflection()]], [["Item", string_type]], []]);
}

function init() {
    return new Model(void 0, false, void 0, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1, array;
    switch (msg.tag) {
        case 1: {
            return new Model(model.Screens, msg.fields[0], model.DeleteForm, model.ErrorMsg);
        }
        case 2: {
            return new Model(model.Screens, model.AddForm, msg.fields[0], model.ErrorMsg);
        }
        case 3: {
            const x_3 = msg.fields[0];
            return new Model((matchValue = model.Screens, (matchValue != null) ? append([x_3], matchValue) : [x_3]), false, model.DeleteForm, model.ErrorMsg);
        }
        case 4: {
            return new Model((matchValue_1 = model.Screens, (matchValue_1 != null) ? ((array = matchValue_1, array.filter((s) => (s.Id !== msg.fields[0])))) : (void 0)), model.AddForm, void 0, model.ErrorMsg);
        }
        case 5: {
            return new Model(model.Screens, model.AddForm, model.DeleteForm, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return new Model(model.Screens, model.AddForm, model.DeleteForm, "Kunne ikke laste infoskjermer. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(res.fields[0], model.AddForm, model.DeleteForm, model.ErrorMsg);
            }
        }
    }
}

function ScreenForm(screenFormInputProps) {
    let elements_2, children, children_1;
    const onClose = screenFormInputProps.onClose;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const name = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const validName = patternInput_1[0];
    const setValidName = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_4, v) => {
        onClose(void 0);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Opprett infoskjerm"]]), (children = singleton(MuiHelpers_createElement(TextField, [["onChange", (e) => {
        patternInput[1](Browser_Types_Event__Event_get_Value(e));
    }], ["error", !validName], ["variant", "outlined"], ["label", "Navn på infoskjerm"], ["autoFocus", true], ["required", true]])), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (patternInput_2[0] ? singleton_1(loadingIndicatorSmall()) : append_1(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Avbryt"], ["onClick", (_arg_5) => {
        onClose(void 0);
    }]])), delay(() => singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["disabled", (name.trim().length === 0) ? true : (!validName)], ["children", "Opprett"], ["onClick", (_arg_6) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let body, props, decoder, data_1, caseStrategy_3, extra_3;
            const trimmed = name.trim();
            if (trimmed.length === 0) {
                setValidName(false);
                return Promise.resolve();
            }
            else {
                patternInput_2[1](true);
                setValidName(true);
                const req = new Infoscreen("", trimmed, "", "");
                return ((body = Auto_generateBoxedEncoder_Z20B7B430(Infoscreen$reflection(), void 0, void 0, void 0)(req), (props = props_15(), (decoder = Auto_generateBoxedDecoder_79988AEF(Infoscreen$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                    let properties_2, headers_2;
                    try {
                        const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(Screens_Add, properties_3).then((_arg) => {
                            let response_1, decoder_1_1;
                            return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Infoscreen$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                let matchValue;
                                return Promise.resolve(equals(Infoscreen$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                            })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                        }))));
                        return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                    }
                    catch (exn) {
                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                    }
                })()))))))).then((_arg_2) => {
                    const res = _arg_2;
                    if (res.tag === 1) {
                        checkFetchError(res.fields[0]);
                        patternInput_3[1]("Kunne ikke opprette infoskjerm. Vennligst prøv igjen senere.");
                        return Promise.resolve();
                    }
                    else {
                        onClose(res.fields[0]);
                        return Promise.resolve();
                    }
                });
            }
        }));
        pr_1.then();
    }]]))))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function DeleteForm(deleteFormInputProps) {
    let elements_2, children, children_1;
    const onClose = deleteFormInputProps.onClose;
    const x = deleteFormInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput[1];
    const processing = patternInput[0];
    const setErrMsg = useFeliz_React__React_useState_Static_1505(void 0)[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_4, v) => {
        onClose(false);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Slett infoskjerm"]]), (children = singleton(MuiHelpers_createElement(DialogContentText, [["children", `Er du sikker på at du ønsker å slette infoskjermen "${x.Name}"?`]])), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (processing ? singleton_1(loadingIndicatorSmall()) : append_1(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Avbryt"], ["onClick", (_arg_5) => {
        onClose(false);
    }]])), delay(() => singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Slett"], ["onClick", (_arg_6) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let props, decoder, data_1, caseStrategy_2, extra_2;
            if (!processing) {
                setProcessing(true);
                const requestPath = Screens_Delete(x.Id);
                return ((props = props_15(), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
                    let properties_2, headers_2;
                    try {
                        const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                            let response_1, decoder_1_1;
                            return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                let matchValue;
                                return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                            })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                        }))));
                        return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                    }
                    catch (exn) {
                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                    }
                })())))))).then((_arg_2) => {
                    const res = _arg_2;
                    if (res.tag === 1) {
                        checkFetchError(res.fields[0]);
                        setErrMsg("Kunne ikke slette infoskjerm. Vennligst prøv igjen senere.");
                        setProcessing(false);
                        return Promise.resolve();
                    }
                    else {
                        const x_2 = res.fields[0];
                        if (x_2.result === "success") {
                            onClose(true);
                            return Promise.resolve();
                        }
                        else {
                            setErrMsg(x_2.message);
                            setProcessing(false);
                            return Promise.resolve();
                        }
                    }
                });
            }
            else {
                return Promise.resolve();
            }
        }));
        pr_1.then();
    }]]))))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function drawScreenBox(x, dispatch) {
    let elements, elems_6, elems_1, elems, props_1, elems_3, elems_2, props_5, elems_5, elems_4, props_9;
    return MuiHelpers_createElement(Paper, [["style", {
        padding: 10,
        display: "flex",
        flexDirection: "column",
        width: 280,
        margin: 10,
    }], ["elevation", 1], (elements = [createElement("span", {
        style: {
            fontSize: 1.2 + "rem",
            textAlign: "center",
        },
        children: x.Name,
    }), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginTop: 10,
    }], (elems_6 = [createElement("div", createObj(ofArray([["style", {}], (elems_1 = [MuiHelpers_createElement(Tooltip, [["title", "Åpne infoskjerm"], ["children", createElement("a", createObj(ofArray([["style", {
        color: "#363636",
    }], ["href", `/screens/screen/${x.Url}`], ["target", "_blank"], (elems = [(props_1 = {
        style: {
            fontSize: 2.5 + "rem",
        },
    }, react.createElement(ScreenShare, props_1))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {}], (elems_3 = [MuiHelpers_createElement(Tooltip, [["title", "Rediger infoskjerm"], ["children", createElement("a", createObj(ofArray([["style", {
        color: "#363636",
    }], ["href", `${basePath}/screens/designer/${x.Id}`], ["target", "_blank"], (elems_2 = [(props_5 = {
        style: {
            fontSize: 2.5 + "rem",
        },
    }, react.createElement(Edit, props_5))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["style", {}], (elems_5 = [MuiHelpers_createElement(Tooltip, [["title", "Slett infoskjerm"], ["children", createElement("a", createObj(ofArray([["style", {
        color: "#363636",
        cursor: "pointer",
    }], ["onClick", (_arg) => {
        dispatch(new Message(2, x));
    }], (elems_4 = [(props_9 = {
        style: {
            fontSize: 2.5 + "rem",
        },
    }, react.createElement(Delete, props_9))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
}

function drawScreens(model, dispatch) {
    let elems_2;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
    }], (elems_2 = toList(delay(() => {
        let elems_1, elems;
        const matchValue = model.Screens;
        if (matchValue != null) {
            const i = matchValue;
            return append_1((i.length === 0) ? singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
                minHeight: 50 + "vh",
            }], (elems_1 = [createElement("span", {
                style: {
                    fontStyle: "italic",
                    fontSize: 1.2 + "rem",
                },
                children: "Ingen infoskjermer opprettet enda",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty_1(), delay(() => singleton_1(map_1((x) => drawScreenBox(x, dispatch), i))));
        }
        else {
            return singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
            }], (elems = [loadingIndicatorLarge()], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function Screens() {
    let elems_1;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    let isDemo;
    const matchValue = useReact_useContext_37FA55CF(globalCtx).Session;
    isDemo = ((matchValue != null) && matchValue.Subscription.IsDemo);
    useReact_useEffectOnce_3A5B6456(() => {
        Screens_fetchAllScreens((arg_1) => {
            dispatch(new Message(0, arg_1));
        });
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    }], (elems_1 = toList(delay(() => append_1(singleton_1(snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(5));
    })), delay(() => {
        let elems;
        return append_1(singleton_1(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 10 + "px ",
        }], (elems = toList(delay(() => {
            let c, props, props_1;
            let isDemoBlocked;
            if (!isDemo) {
                isDemoBlocked = false;
            }
            else {
                const matchValue_1 = model_1.Screens;
                let pattern_matching_result;
                if (matchValue_1 != null) {
                    if ((c = matchValue_1, c.length > 0)) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        isDemoBlocked = true;
                        break;
                    }
                    case 1: {
                        isDemoBlocked = false;
                        break;
                    }
                }
            }
            return append_1(singleton_1(MuiHelpers_createElement(Button, [["disabled", isDemoBlocked], ["startIcon", model_1.AddForm ? ((props = {}, react.createElement(Close, props))) : ((props_1 = {}, react.createElement(Add, props_1)))], ["children", model_1.AddForm ? "Avbryt" : "Legg til infoskjerm"], ["onClick", (_arg_2) => {
                dispatch(new Message(1, !model_1.AddForm));
            }]])), delay(() => (isDemoBlocked ? singleton_1(demoLimitNotification("Du har nådd grensen for antall infoskjermer i demo.")) : empty_1())));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append_1(model_1.AddForm ? singleton_1(createElement(ScreenForm, {
            onClose: (newScreen) => {
                if (newScreen != null) {
                    dispatch(new Message(3, newScreen));
                }
                else {
                    dispatch(new Message(1, false));
                }
            },
        })) : empty_1(), delay(() => {
            let matchValue_2, df;
            return append_1((matchValue_2 = model_1.DeleteForm, (matchValue_2 != null) ? ((df = matchValue_2, singleton_1(createElement(DeleteForm, {
                x: df,
                onClose: (didDelete) => {
                    if (didDelete) {
                        dispatch(new Message(4, df.Id));
                    }
                    else {
                        dispatch(new Message(2, void 0));
                    }
                },
            })))) : ((empty_1()))), delay(() => singleton_1(drawScreens(model_1, dispatch))));
        }))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

