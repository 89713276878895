import { Record, Union } from "./fable_modules/fable-library.3.7.22/Types.js";
import { option_type, equals, obj_type, anonRecord_type, record_type, lambda_type, unit_type, bool_type, union_type, string_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { LoginResponse$reflection, LoginRequest$reflection, LoginRequest, GenericMessage$reflection } from "./Shared/ApiDataTypes.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { tryFocus, validateEmail } from "./Utils.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { props as props_12 } from "./Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { map, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, equals as equals_1, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./fable_modules/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { StyleCreator$1__create_4EAC9E1E } from "./fable_modules/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { createElement } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { snackError, loadingIndicatorSmall } from "./ViewHelpers.js";
import DialogActions from "@material-ui/core/DialogActions";
import { storeUserSession, deleteRememberMe, storeRememberMe, tryGetRememberMe } from "./LocalStorage.js";
import { basePath } from "./Pages.js";
import { Auth_Login } from "./Shared/Urls.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class RecoveryRequestForm_Step extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EmailStep", "FailedStep", "CompletedStep"];
    }
}

function RecoveryRequestForm_Step$reflection() {
    return union_type("Login.RecoveryRequestForm.Step", [], RecoveryRequestForm_Step, () => [[], [["Item", string_type]], []]);
}

class RecoveryRequestForm_Model extends Record {
    constructor(Email, CurrentStep, ValidEmail, Processing, OnClose) {
        super();
        this.Email = Email;
        this.CurrentStep = CurrentStep;
        this.ValidEmail = ValidEmail;
        this.Processing = Processing;
        this.OnClose = OnClose;
    }
}

function RecoveryRequestForm_Model$reflection() {
    return record_type("Login.RecoveryRequestForm.Model", [], RecoveryRequestForm_Model, () => [["Email", string_type], ["CurrentStep", RecoveryRequestForm_Step$reflection()], ["ValidEmail", bool_type], ["Processing", bool_type], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

class RecoveryRequestForm_Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateEmail", "GotoStep", "InvalidateEmail", "Commit", "ResetResponse", "SetError", "DismissError"];
    }
}

function RecoveryRequestForm_Message$reflection() {
    return union_type("Login.RecoveryRequestForm.Message", [], RecoveryRequestForm_Message, () => [[["Item", string_type]], [["Item", RecoveryRequestForm_Step$reflection()]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], []]);
}

function RecoveryRequestForm_init(x) {
    return new RecoveryRequestForm_Model("", new RecoveryRequestForm_Step(0), true, false, x);
}

function RecoveryRequestForm_update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new RecoveryRequestForm_Model(model.Email, msg.fields[0], model.ValidEmail, model.Processing, model.OnClose);
        }
        case 2: {
            return new RecoveryRequestForm_Model(model.Email, model.CurrentStep, false, model.Processing, model.OnClose);
        }
        case 3: {
            if (validateEmail(model.Email)) {
                return new RecoveryRequestForm_Model(model.Email, model.CurrentStep, true, true, model.OnClose);
            }
            else {
                return new RecoveryRequestForm_Model(model.Email, model.CurrentStep, false, model.Processing, model.OnClose);
            }
        }
        case 4: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new RecoveryRequestForm_Model(model.Email, new RecoveryRequestForm_Step(1, "Kunne ikke forespørre passordtilbakestilling. Vennligst prøv igjen senere."), model.ValidEmail, false, model.OnClose);
            }
            else {
                const x_2 = res.fields[0];
                if (x_2.result === "success") {
                    return new RecoveryRequestForm_Model(model.Email, new RecoveryRequestForm_Step(2), model.ValidEmail, model.Processing, model.OnClose);
                }
                else {
                    return new RecoveryRequestForm_Model(model.Email, new RecoveryRequestForm_Step(1, x_2.message), model.ValidEmail, false, model.OnClose);
                }
            }
        }
        case 5: {
            return new RecoveryRequestForm_Model(model.Email, new RecoveryRequestForm_Step(1, msg.fields[0]), model.ValidEmail, model.Processing, model.OnClose);
        }
        case 6: {
            return new RecoveryRequestForm_Model(model.Email, new RecoveryRequestForm_Step(0), model.ValidEmail, model.Processing, model.OnClose);
        }
        default: {
            return new RecoveryRequestForm_Model(msg.fields[0], model.CurrentStep, model.ValidEmail, model.Processing, model.OnClose);
        }
    }
}

function RecoveryRequestForm_requestReset(email, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let item, body, props, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new RecoveryRequestForm_Message(3));
        return ((item = {
            Email: email,
        }, (body = Auto_generateBoxedEncoder_Z20B7B430(anonRecord_type(["Email", string_type]), void 0, void 0, void 0)(item), (props = props_12(), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/password-recovery/request", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))))).then((_arg_2) => {
            dispatch(new RecoveryRequestForm_Message(4, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function RecoveryRequestForm_failed(errMsg) {
    return MuiHelpers_createElement(DialogContentText, [["children", Interop_reactApi.Children.toArray([errMsg])]]);
}

const RecoveryRequestForm_useStyles = StyleImports_makeStyles_get((() => {
    let f1;
    const styles = StyleCreator$1_$ctor();
    f1 = ((theme) => ({
        paper: StyleCreator$1__create_4EAC9E1E(styles, singleton(["backgroundColor", "#FFFFFF"])),
    }));
    return (arg) => {
        const o = f1(arg);
        return Object.assign({}, o);
    };
})());

export function RecoveryRequestForm_RecoveryForm(recoveryRequestForm_RecoveryFormInputProps) {
    let elements_2, children_2, children_3;
    const onClose = recoveryRequestForm_RecoveryFormInputProps.onClose;
    const patternInput = useReact_useReducer_2B9E6EA0(RecoveryRequestForm_update, RecoveryRequestForm_init(onClose));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const s = RecoveryRequestForm_useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_1, v) => {
        model_1.OnClose();
    }], ["PaperProps", {
        className: s.paper,
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Tilbakestill passord"]]), (children_2 = toList(delay(() => {
        let children;
        const matchValue = model_1.CurrentStep;
        switch (matchValue.tag) {
            case 1: {
                return singleton_1(RecoveryRequestForm_failed(matchValue.fields[0]));
            }
            case 2: {
                return singleton_1(MuiHelpers_createElement(DialogContentText, [["children", "Tilbakestilling av passord er forespurt for denne e-post adressen. Om det eksisterer en bruker tilknyttet denne adressen vil du motta en e-post med instrukser for tilbakestilling."]]));
            }
            default: {
                return singleton_1((children = ofArray([MuiHelpers_createElement(DialogContentText, [["children", "Forespør tilbakestilling av passord ved å skrive inn e-post adressen tilknyttet bruker i feltet under."]]), MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["id", "recovery-email"]), delay(() => append(singleton_1(["defaultValue", model_1.Email]), delay(() => append(singleton_1(["error", !model_1.ValidEmail]), delay(() => append((!model_1.ValidEmail) ? singleton_1(["helperText", "Ugyldig e-post adresse"]) : empty_1(), delay(() => append(singleton_1(["onChange", (e) => {
                    dispatch(new RecoveryRequestForm_Message(0, Browser_Types_Event__Event_get_Value(e)));
                }]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["autoFocus", true]), delay(() => append(singleton_1(["label", "E-post adresse"]), delay(() => append(singleton_1(["type", "email"]), delay(() => singleton_1(["fullWidth", true]))))))))))))))))))))))]), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                })));
            }
        }
    })), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_3 = toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg_2) => {
        onClose();
    }]])), delay(() => (equals_1(model_1.CurrentStep, new RecoveryRequestForm_Step(0)) ? (model_1.Processing ? singleton_1(loadingIndicatorSmall()) : singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", model_1.Email.length === 0], ["children", "Neste"], ["onClick", (_arg_3) => {
        if (validateEmail(model_1.Email) && (!model_1.Processing)) {
            RecoveryRequestForm_requestReset(model_1.Email, dispatch);
        }
        else {
            dispatch(new RecoveryRequestForm_Message(2));
        }
    }]]))) : empty_1()))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

class Model extends Record {
    constructor(Email, Password, ErrorMsg, ValidEmail, ValidPassword, ProcessingRequest, RememberMe, Recover) {
        super();
        this.Email = Email;
        this.Password = Password;
        this.ErrorMsg = ErrorMsg;
        this.ValidEmail = ValidEmail;
        this.ValidPassword = ValidPassword;
        this.ProcessingRequest = ProcessingRequest;
        this.RememberMe = RememberMe;
        this.Recover = Recover;
    }
}

function Model$reflection() {
    return record_type("Login.Model", [], Model, () => [["Email", string_type], ["Password", string_type], ["ErrorMsg", option_type(string_type)], ["ValidEmail", bool_type], ["ValidPassword", bool_type], ["ProcessingRequest", bool_type], ["RememberMe", bool_type], ["Recover", bool_type]]);
}

class LoginMessage extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateEmail", "UpdatePassword", "TryLogin", "ToggleRememberMe", "ToggleProcessing", "ToggleRecover", "SetError", "DismissError"];
    }
}

function LoginMessage$reflection() {
    return union_type("Login.LoginMessage", [], LoginMessage, () => [[["Item", string_type]], [["Item", string_type]], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], []]);
}

function init() {
    const rm = tryGetRememberMe();
    return new Model((rm.tag === 0) ? rm.fields[0] : "", "", void 0, true, true, false, rm.tag === 0, false);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const x_1 = msg.fields[0];
            return new Model(model.Email, x_1, model.ErrorMsg, model.ValidEmail, x_1.length > 0, model.ProcessingRequest, model.RememberMe, model.Recover);
        }
        case 2: {
            const validEmail = validateEmail(model.Email) ? true : (model.Email === "servicedesk");
            const validPassword = model.Password.length > 0;
            const matchValue = [validEmail, validPassword, model.ProcessingRequest];
            let pattern_matching_result;
            if (matchValue[0]) {
                if (matchValue[1]) {
                    if (matchValue[2]) {
                        pattern_matching_result = 1;
                    }
                    else {
                        pattern_matching_result = 0;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    if (model.RememberMe) {
                        storeRememberMe(model.Email);
                    }
                    return new Model(model.Email, model.Password, void 0, true, true, true, model.RememberMe, model.Recover);
                }
                case 1: {
                    return new Model(model.Email, model.Password, model.ErrorMsg, validEmail, validPassword, model.ProcessingRequest, model.RememberMe, model.Recover);
                }
            }
        }
        case 3: {
            if (!msg.fields[0]) {
                deleteRememberMe();
            }
            return new Model(model.Email, model.Password, model.ErrorMsg, model.ValidEmail, model.ValidPassword, model.ProcessingRequest, !model.RememberMe, model.Recover);
        }
        case 4: {
            return new Model(model.Email, model.Password, model.ErrorMsg, model.ValidEmail, model.ValidPassword, msg.fields[0], model.RememberMe, model.Recover);
        }
        case 5: {
            return new Model(model.Email, model.Password, model.ErrorMsg, model.ValidEmail, model.ValidPassword, model.ProcessingRequest, model.RememberMe, msg.fields[0]);
        }
        case 6: {
            return new Model(model.Email, model.Password, msg.fields[0], model.ValidEmail, model.ValidPassword, model.ProcessingRequest, model.RememberMe, model.Recover);
        }
        case 7: {
            return new Model(model.Email, model.Password, void 0, model.ValidEmail, model.ValidPassword, model.ProcessingRequest, model.RememberMe, model.Recover);
        }
        default: {
            return new Model(msg.fields[0], model.Password, model.ErrorMsg, model.ValidEmail, model.ValidPassword, model.ProcessingRequest, model.RememberMe, model.Recover);
        }
    }
}

export function Login(loginInputProps) {
    let elems_5;
    const onLogin = loginInputProps.onLogin;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const tryLogin = () => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let body, decoder, data_1, caseStrategy_3, extra_3;
            dispatch(new LoginMessage(2));
            if ((validateEmail(model_1.Email) ? true : (model_1.Email === "servicedesk")) && (model_1.Password.trim().length > 0)) {
                const request = new LoginRequest(model_1.Email, model_1.Password);
                const requestPath = basePath + Auth_Login;
                return ((body = Auto_generateBoxedEncoder_Z20B7B430(LoginRequest$reflection(), void 0, void 0, void 0)(request), (decoder = Auto_generateBoxedDecoder_79988AEF(LoginResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                    let properties_2, headers_2;
                    try {
                        const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                            let response_1, decoder_1_1;
                            return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(LoginResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                let matchValue;
                                return Promise.resolve(equals(LoginResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                            })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                        }))));
                        return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                    }
                    catch (exn) {
                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                    }
                })())))))).then((_arg_2) => {
                    const res = _arg_2;
                    if (res.tag === 1) {
                        dispatch(new LoginMessage(6, "Kunne ikke logge deg inn. Vennligst prøv igjen senere og ta kontakt dersom problemet vedvarer."));
                        dispatch(new LoginMessage(4, false));
                        return Promise.resolve();
                    }
                    else {
                        const x_1 = res.fields[0];
                        if (x_1.Result === "success") {
                            storeUserSession(x_1.Session);
                            onLogin(x_1.Session);
                            return Promise.resolve();
                        }
                        else {
                            dispatch(new LoginMessage(6, x_1.Message));
                            dispatch(new LoginMessage(4, false));
                            return Promise.resolve();
                        }
                    }
                });
            }
            else {
                return Promise.resolve();
            }
        }));
        pr_1.then();
    };
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: "#f1f1f1",
        height: 100 + "%",
        maxHeight: 100 + "vh",
    }], (elems_5 = toList(delay(() => append(singleton_1(snackError(model_1.ErrorMsg, () => {
        dispatch(new LoginMessage(7));
    })), delay(() => append(model_1.Recover ? singleton_1(createElement(RecoveryRequestForm_RecoveryForm, {
        onClose: () => {
            dispatch(new LoginMessage(5, false));
        },
    })) : empty_1(), delay(() => {
        let elems_4, elems, elems_3, elems_2, elems_1;
        return singleton_1(createElement("div", createObj(ofArray([["style", {
            backgroundColor: "#FFFFFF",
            padding: 20,
            borderRadius: 5,
            border: (((1 + "px ") + "solid") + " ") + "#b1b1b1",
            width: 100 + "%",
            maxWidth: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }], (elems_4 = [createElement("div", createObj(ofArray([["style", {
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
        }], (elems = [createElement("img", {
            style: {
                width: 100 + "%",
            },
            src: "/images/logo_text.png",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
            maxWidth: 250,
            width: 100 + "%",
            display: "flex",
            flexDirection: "column",
        }], (elems_3 = [MuiHelpers_createElement(TextField, [["variant", "outlined"], ["label", "E-post adresse"], ["onChange", (e) => {
            dispatch(new LoginMessage(0, Browser_Types_Event__Event_get_Value(e)));
        }], ["defaultValue", model_1.Email], ["autoFocus", !model_1.RememberMe], ["error", !model_1.ValidEmail], ["fullWidth", true], ["style", {
            marginBottom: 10,
        }], ["onKeyUp", (kc) => {
            if (kc.code === "Enter") {
                tryFocus("pwd-input");
            }
        }]]), MuiHelpers_createElement(TextField, [["id", "pwd-input"], ["type", "password"], ["variant", "outlined"], ["label", "Passord"], ["autoFocus", model_1.RememberMe], ["onChange", (e_1) => {
            dispatch(new LoginMessage(1, Browser_Types_Event__Event_get_Value(e_1)));
        }], ["defaultValue", model_1.Password], ["error", !model_1.ValidPassword], ["fullWidth", true], ["onKeyUp", (kc_1) => {
            if (kc_1.code === "Enter") {
                tryLogin();
            }
        }]]), MuiHelpers_createElement(FormControlLabel, [["label", "Husk meg"], ["checked", model_1.RememberMe], ["onChange", (e_2) => {
            dispatch(new LoginMessage(3, Browser_Types_Event__Event_get_Checked(e_2)));
        }], ["control", MuiHelpers_createElement(Checkbox, [["style", {
            color: "#363636",
        }]])]]), createElement("div", createObj(ofArray([["style", {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        }], (elems_2 = [createElement("button", createObj(ofArray([["style", {
            border: "none",
            backgroundColor: "#363636",
            width: 150,
            color: "#FFFFFF",
            padding: 10,
            fontSize: 16 + "px",
            cursor: "pointer",
            borderRadius: 5,
        }], ["onClick", (_arg_5) => {
            tryLogin();
        }], (elems_1 = toList(delay(() => ((!model_1.ProcessingRequest) ? singleton_1("Logg inn") : singleton_1(loadingIndicatorSmall())))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("a", {
            style: {
                color: "#363636",
                cursor: "pointer",
                marginTop: 5,
                fontSize: 0.8 + "rem",
            },
            onClick: (_arg_6) => {
                dispatch(new LoginMessage(5, !model_1.Recover));
            },
            children: "Glemt passord?",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

