import { Record, Union } from "./fable_modules/fable-library.3.7.22/Types.js";
import { unit_type, equals, obj_type, record_type, option_type, string_type, union_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { UserSession$reflection } from "./Shared/ApiDataTypes.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { Cmd_batch, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Navigation_modifyUrl } from "./fable_modules/Fable.Elmish.Browser.3.0.5/navigation.fs.js";
import { Page, toPath } from "./Pages.js";
import { tail, head, isEmpty, cons, empty, ofArray, singleton } from "./fable_modules/fable-library.3.7.22/List.js";
import { deleteUserSession } from "./LocalStorage.js";
import { checkFetchError } from "./Utils.js";
import { value as value_91, map, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { createElement } from "react";
import * as react from "react";
import { Admin } from "./Admin.js";
import { Channels } from "./Channel/Channels.js";
import { Members } from "./Members.js";
import { Screens } from "./Infoscreen.js";
import { Overview } from "./Statistics/Report.js";
import { Billing } from "./Billing.js";
import { HowTo } from "./How.js";
import { StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./fable_modules/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { StyleCreator$1__create_4EAC9E1E } from "./fable_modules/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { printf, toText } from "./fable_modules/fable-library.3.7.22/String.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { uncurry, createObj } from "./fable_modules/fable-library.3.7.22/Util.js";
import { loadingIndicatorLarge } from "./ViewHelpers.js";
import { React_contextProvider_34D9BBBD, useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { RouterModule_router, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { props as props_21 } from "./Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { Activate } from "./Activate.js";
import { Recover } from "./PasswordRecovery.js";
import { Login } from "./Login.js";
import { GlobalCtx_New, globalCtx } from "./Context.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import { LogoNavbar } from "./Navbar.js";
import Drawer from "@material-ui/core/Drawer";
import { now, op_Subtraction } from "./fable_modules/fable-library.3.7.22/Date.js";
import { days } from "./fable_modules/fable-library.3.7.22/TimeSpan.js";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { Route_$007CQuery$007C_$007C } from "./fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { init as init_1 } from "./Sentry.js";
import { render } from "react-dom";

export class SubModel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HomeModel", "MemberModel", "AdminModel", "ChannelModel", "ScreensModel", "ReportModel", "BillingModel", "HowToModel"];
    }
}

export function SubModel$reflection() {
    return union_type("Main.SubModel", [], SubModel, () => [[], [], [], [], [], [], [], []]);
}

export class Model extends Record {
    constructor(SubMdl, Query, Session) {
        super();
        this.SubMdl = SubMdl;
        this.Query = Query;
        this.Session = Session;
    }
}

export function Model$reflection() {
    return record_type("Main.Model", [], Model, () => [["SubMdl", SubModel$reflection()], ["Query", string_type], ["Session", option_type(option_type(UserSession$reflection()))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetSession", "GotoView", "PerformLogout", "AuthValidationResponse"];
    }
}

export function Msg$reflection() {
    return union_type("Main.Msg", [], Msg, () => [[["Item", option_type(UserSession$reflection())]], [["Item", SubModel$reflection()]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserSession$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserSession$reflection()]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

export function urlUpdate(result, model) {
    let pattern_matching_result;
    if (result != null) {
        if (result.tag === 4) {
            pattern_matching_result = 0;
        }
        else if (result.tag === 5) {
            pattern_matching_result = 1;
        }
        else if (result.tag === 8) {
            pattern_matching_result = 2;
        }
        else if (result.tag === 9) {
            pattern_matching_result = 3;
        }
        else if (result.tag === 10) {
            pattern_matching_result = 4;
        }
        else if (result.tag === 11) {
            pattern_matching_result = 5;
        }
        else {
            pattern_matching_result = 6;
        }
    }
    else {
        pattern_matching_result = 6;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new Model(new SubModel(1), model.Query, model.Session), Cmd_none()];
        }
        case 1: {
            return [new Model(new SubModel(2), model.Query, model.Session), Cmd_none()];
        }
        case 2: {
            return [new Model(new SubModel(4), model.Query, model.Session), Cmd_none()];
        }
        case 3: {
            return [new Model(new SubModel(5), model.Query, model.Session), Cmd_none()];
        }
        case 4: {
            return [new Model(new SubModel(6), model.Query, model.Session), Cmd_none()];
        }
        case 5: {
            return [new Model(new SubModel(7), model.Query, model.Session), Cmd_none()];
        }
        case 6: {
            return [new Model(new SubModel(3), model.Query, model.Session), Cmd_batch(singleton(Navigation_modifyUrl(toPath(new Page(7)))))];
        }
    }
}

export function init() {
    return new Model(new SubModel(3), "", void 0);
}

export function update(model, msg) {
    const matchValue = [msg, model.SubMdl];
    if (matchValue[0].tag === 1) {
        return new Model(matchValue[0].fields[0], model.Query, model.Session);
    }
    else if (matchValue[0].tag === 2) {
        deleteUserSession();
        window.location.reload();
        return model;
    }
    else if (matchValue[0].tag === 3) {
        const res = matchValue[0].fields[0];
        if (res.tag === 1) {
            checkFetchError(res.fields[0]);
            deleteUserSession();
            return new Model(model.SubMdl, model.Query, some(void 0));
        }
        else {
            return new Model(model.SubMdl, model.Query, some(res.fields[0]));
        }
    }
    else {
        return new Model(model.SubMdl, model.Query, some(matchValue[0].fields[0]));
    }
}

export function HomeView(model, dispatch) {
    return react.createElement("div", {
        id: "content",
    });
}

export function subView(model, dispatch) {
    const matchValue = model.SubMdl;
    switch (matchValue.tag) {
        case 2: {
            return createElement(Admin, null);
        }
        case 3: {
            return createElement(Channels, null);
        }
        case 1: {
            return createElement(Members, null);
        }
        case 4: {
            return createElement(Screens, null);
        }
        case 5: {
            return createElement(Overview, null);
        }
        case 6: {
            return createElement(Billing, null);
        }
        case 7: {
            return createElement(HowTo, null);
        }
        default: {
            return HomeView(model, dispatch);
        }
    }
}

const useStyles = StyleImports_makeStyles_get((() => {
    let f1;
    const styles = StyleCreator$1_$ctor();
    f1 = ((theme) => {
        const root = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "flex"]));
        const menuButton = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginRight", theme.spacing(2)], ["color", "#2F4F4F"]]));
        const hide = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "none"]));
        const drawer = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 240], ["flexShrink", 0]]));
        const drawerPaper = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["overflowY", "hidden"], ["width", 240]]));
        const drawerHeader = StyleCreator$1__create_4EAC9E1E(styles, toList(delay(() => append(singleton_1(["display", "flex"]), delay(() => append(singleton_1(["alignItems", "center"]), delay(() => append(singleton_1(["padding", theme.spacing(0, 1)]), delay(() => append(Object.entries(theme.mixins.toolbar), delay(() => singleton_1(["justifyContent", "space-between"]))))))))))));
        return {
            content: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["flexGrow", 1], ["minWidth", 400], ["backgroundColor", "#f1f1f1"], ["overflowY", "auto"], ["height", 100 + "vh"], ["padding", 2 + "vw"]])),
            contentShift: StyleCreator$1__create_4EAC9E1E(styles, singleton(["marginLeft", 0])),
            drawer: drawer,
            drawerHeader: drawerHeader,
            drawerPaper: drawerPaper,
            hide: hide,
            menuButton: menuButton,
            menuEntry: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["padding", 16], ["paddingBottom", 8], ["paddingTop", 8]])),
            root: root,
        };
    });
    return (arg) => {
        const o = f1(arg);
        return Object.assign({}, o);
    };
})());

function NewDomainMsg() {
    let elements, arg, arg_1;
    return MuiHelpers_createElement(Modal, [["open", true], ["onClose", (_arg, v) => {
    }], ["style", {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }], ["children", MuiHelpers_createElement(Paper, [["style", {
        padding: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 500,
        textAlign: "center",
    }], ["elevation", 1], (elements = [createElement("span", {
        style: {
            fontSize: 1.5 + "rem",
        },
        children: "Din Enterprise Mobile News har fått ett nytt hjem",
    }), createElement("span", {
        style: {
            margin: ((20 + "px ") + 0) + "px",
        },
        children: "Din instans er oppdatert til å bruke mobilenews.no domenet. Din instans finner du nå under følgende nettadresse",
    }), createElement("a", {
        style: {
            fontSize: 1.5 + "rem",
        },
        href: (arg = (tenantId), toText(printf("https://%s.mobilenews.no"))(arg)),
        children: (arg_1 = (tenantId), toText(printf("%s.mobilenews.no"))(arg_1)),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])]]);
}

function drawContestSession() {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: 100 + "%",
        height: 100 + "%",
    }], (elems_1 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        marginBottom: 20,
        width: 100 + "%",
        maxWidth: 300,
    }], (elems = [createElement("img", {
        style: {
            width: 100 + "%",
        },
        src: "/images/logo_text.png",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), loadingIndicatorLarge()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function App() {
    let elems_14;
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.pathname + window.location.search, 2));
    const setPath = patternInput[1];
    const patternInput_1 = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const s = useStyles();
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setDrawerOpen = patternInput_2[1];
    const drawerOpen = patternInput_2[0];
    const responsive = useFeliz_React__React_useResponsive_Static_Z1648B8FF();
    const isBig = (responsive.tag === 4) ? true : (responsive.tag === 3);
    const menuButton$0027 = (title, icon, isActive, onClick) => {
        let elements_1, children;
        return MuiHelpers_createElement(ListItem, [["selected", isActive], ["button", true], ["key", title], ["onClick", onClick], (elements_1 = [(children = singleton(createElement("i", {
            className: icon,
        })), MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), MuiHelpers_createElement(ListItemText, [["primary", title]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]);
    };
    useReact_useEffectOnce_3A5B6456(() => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let props_2, decoder, data_1, caseStrategy_2, extra_2;
            return ((props_2 = props_21(), (decoder = Auto_generateBoxedDecoder_79988AEF(UserSession$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
                let properties_4, headers_2;
                try {
                    const properties_3 = Helper_withProperties(props_2, (properties_4 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_4), data_1), properties_4)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/auth/contest-session", properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(UserSession$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(UserSession$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 1) {
                    dispatch(new Msg(0, void 0));
                    return Promise.resolve();
                }
                else {
                    dispatch(new Msg(0, res.fields[0]));
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
    });
    return createElement("div", createObj(ofArray([["style", {
        height: 100 + "%",
    }], (elems_14 = toList(delay(() => append(singleton_1(RouterModule_router({
        onUrlChanged: (p) => {
            console.log(some(p));
            let pattern_matching_result;
            if (!isEmpty(p)) {
                if (head(p) === "/logout") {
                    if (isEmpty(tail(p))) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    dispatch(new Msg(2));
                    setPath(singleton(""));
                    break;
                }
                case 1: {
                    setPath(p);
                    break;
                }
            }
        },
        hashMode: 2,
    })), delay(() => append((window.location.host === "emn.itpartner.no") ? singleton_1(createElement(NewDomainMsg, null)) : empty_1(), delay(() => {
        let session, elems_13;
        const matchValue_1 = patternInput[0];
        let pattern_matching_result_1, token;
        if (!isEmpty(matchValue_1)) {
            if (head(matchValue_1) === "activate") {
                if (!isEmpty(tail(matchValue_1))) {
                    const activePatternResult = Route_$007CQuery$007C_$007C(head(tail(matchValue_1)));
                    if (activePatternResult != null) {
                        if (!isEmpty(activePatternResult)) {
                            if (head(activePatternResult)[0] === "token") {
                                if (isEmpty(tail(activePatternResult))) {
                                    if (isEmpty(tail(tail(matchValue_1)))) {
                                        pattern_matching_result_1 = 0;
                                        token = head(activePatternResult)[1];
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                return singleton_1(createElement(Activate, {
                    token: token,
                }));
            }
            case 1: {
                let pattern_matching_result_2, token_1, uid;
                if (!isEmpty(matchValue_1)) {
                    if (head(matchValue_1) === "password-recovery") {
                        if (!isEmpty(tail(matchValue_1))) {
                            const activePatternResult_1 = Route_$007CQuery$007C_$007C(head(tail(matchValue_1)));
                            if (activePatternResult_1 != null) {
                                if (!isEmpty(activePatternResult_1)) {
                                    if (head(activePatternResult_1)[0] === "token") {
                                        if (!isEmpty(tail(activePatternResult_1))) {
                                            if (head(tail(activePatternResult_1))[0] === "uid") {
                                                if (isEmpty(tail(tail(activePatternResult_1)))) {
                                                    if (isEmpty(tail(tail(matchValue_1)))) {
                                                        pattern_matching_result_2 = 0;
                                                        token_1 = head(activePatternResult_1)[1];
                                                        uid = head(tail(activePatternResult_1))[1];
                                                    }
                                                    else {
                                                        pattern_matching_result_2 = 1;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result_2 = 1;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_2 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_2 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_2 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_2 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                }
                else {
                    pattern_matching_result_2 = 1;
                }
                switch (pattern_matching_result_2) {
                    case 0: {
                        return singleton_1(createElement(Recover, {
                            token: token_1,
                            userId: uid,
                        }));
                    }
                    case 1: {
                        const matchValue_2 = model_1.Session;
                        return (matchValue_2 != null) ? ((value_91(matchValue_2) == null) ? singleton_1(createElement(Login, {
                            onLogin: (arg_9) => {
                                dispatch(new Msg(0, arg_9));
                            },
                        })) : ((session = value_91(matchValue_2), singleton_1(React_contextProvider_34D9BBBD(globalCtx, GlobalCtx_New(session, isBig), createElement("div", createObj(ofArray([["className", s.root], (elems_13 = toList(delay(() => append(singleton_1(MuiHelpers_createElement(CssBaseline, [])), delay(() => append((!isBig) ? singleton_1(createElement(LogoNavbar, {
                            sideBarOpen: drawerOpen,
                            setSideBarOpen: setDrawerOpen,
                        })) : empty_1(), delay(() => append(singleton_1(MuiHelpers_createElement(Drawer, toList(delay(() => append(singleton_1(["className", s.drawer]), delay(() => append(singleton_1(["classes.paper", s.drawerPaper]), delay(() => append(isBig ? singleton_1(["variant", "permanent"]) : singleton_1(["variant", "temporary"]), delay(() => append(singleton_1(["anchor", "left"]), delay(() => append(singleton_1(["open", drawerOpen]), delay(() => append(singleton_1(["onClose", (_arg_4) => {
                            setDrawerOpen(false);
                        }]), delay(() => {
                            let elements_6;
                            return singleton_1((elements_6 = toList(delay(() => {
                                let elems_2;
                                return append(singleton_1(createElement("div", createObj(ofArray([["className", s.drawerHeader], (elems_2 = [createElement("img", {
                                    style: {
                                        width: 100 + "%",
                                    },
                                    src: "/images/logo_text.png",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                                    let matchValue_3, s_2, elems_3, value_44, copyOfStruct;
                                    return append((matchValue_3 = session, (matchValue_3 != null) ? (matchValue_3.Subscription.IsDemo ? ((s_2 = matchValue_3, singleton_1(createElement("div", createObj(ofArray([["style", {
                                        textAlign: "center",
                                        fontSize: 0.85 + "rem",
                                        fontStyle: "italic",
                                        color: "#363636",
                                    }], (elems_3 = [(value_44 = (`Demo: ${((copyOfStruct = op_Subtraction(s_2.Subscription.ValidTo, now()), days(copyOfStruct)))} dager igjen`), createElement("span", {
                                        children: [value_44],
                                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))))) : ((empty_1()))) : ((empty_1()))), delay(() => append(singleton_1(MuiHelpers_createElement(Divider, [])), delay(() => {
                                        let elems_10, elems_5, elements_2, elems_9, children_2, elements_4, children_1;
                                        return singleton_1(createElement("div", createObj(ofArray([["style", {
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            flexGrow: 1,
                                            overflowY: "auto",
                                        }], (elems_10 = [createElement("div", createObj(ofArray([["style", {}], (elems_5 = [MuiHelpers_createElement(List, [(elements_2 = [menuButton$0027("Kanaler", "fas fa-broadcast-tower", model_1.SubMdl.tag === 3, (_arg_5) => {
                                            setDrawerOpen(false);
                                            dispatch(new Msg(1, new SubModel(3)));
                                        }), menuButton$0027("Medlemmer", "fas fa-users", model_1.SubMdl.tag === 1, (_arg_6) => {
                                            setDrawerOpen(false);
                                            dispatch(new Msg(1, new SubModel(1)));
                                        }), menuButton$0027("Infoskjermer", "fas fa-chalkboard", model_1.SubMdl.tag === 4, (_arg_7) => {
                                            setDrawerOpen(false);
                                            dispatch(new Msg(1, new SubModel(4)));
                                        }), menuButton$0027("Rapport", "fas fa-chart-bar", model_1.SubMdl.tag === 5, (_arg_8) => {
                                            setDrawerOpen(false);
                                            dispatch(new Msg(1, new SubModel(5)));
                                        }), menuButton$0027("Fakturering", "fas fa-file-invoice", model_1.SubMdl.tag === 6, (_arg_9) => {
                                            setDrawerOpen(false);
                                            dispatch(new Msg(1, new SubModel(6)));
                                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["style", {}], (elems_9 = [MuiHelpers_createElement(Divider, []), (children_2 = ofArray([menuButton$0027("Instillinger", "fas fa-cog", model_1.SubMdl.tag === 2, (_arg_10) => {
                                            dispatch(new Msg(1, new SubModel(2)));
                                        }), menuButton$0027("Kom i gang", "fas fa-info", model_1.SubMdl.tag === 7, (_arg_11) => {
                                            dispatch(new Msg(1, new SubModel(7)));
                                        }), MuiHelpers_createElement(ListItem, [["selected", false], ["button", true], ["component", "a"], ["key", "Serit Tromsø"], ["href", "https://serit.no/avdelinger/serit-tromso/"], ["target", "_Blank"], (elements_4 = [(children_1 = singleton(createElement("i", {
                                            className: "fas fa-headset",
                                        })), MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), MuiHelpers_createElement(ListItemText, [["primary", "Serit Tromsø"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])]), menuButton$0027("Logg ut", "fas fa-sign-out-alt", false, (_arg_12) => {
                                            dispatch(new Msg(2));
                                        })]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))));
                                    }))));
                                }));
                            })), ["children", Interop_reactApi.Children.toArray(Array.from(elements_6))]));
                        })))))))))))))))), delay(() => {
                            let elems_12;
                            return singleton_1(createElement("main", createObj(ofArray([["className", drawerOpen ? toText(printf("%s %s"))(s.content)(s.contentShift) : s.content], ["style", createObj(toList(delay(() => (isBig ? singleton_1(["width", 100 + "%"]) : append(singleton_1(["paddingTop", 75]), delay(() => singleton_1(["marginLeft", 0])))))))], (elems_12 = [subView(model_1, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))));
                        })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))))))) : singleton_1(drawContestSession());
                    }
                }
            }
        }
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])));
}

if (window.location.host.indexOf("mobilenews.no") >= 0) {
    init_1();
}

render(createElement(App, null), window.document.getElementById("mobilenews-tenant"));

