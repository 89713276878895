import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { APIToken, APIToken$reflection } from "./Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, string_type, bool_type, option_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { map as map_1, append } from "./fable_modules/fable-library.3.7.22/Array.js";
import { toLocalDate, checkFetchError } from "./Utils.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { props as props_13 } from "./Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createElement } from "react";
import * as react from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { loadingIndicator, snackError, loadingIndicatorSmall, errorBanner } from "./ViewHelpers.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import DialogContent from "@material-ui/core/DialogContent";
import { empty as empty_1, append as append_1, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

class Model extends Record {
    constructor(Tokens, AddForm, ErrorMsg) {
        super();
        this.Tokens = Tokens;
        this.AddForm = AddForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("OpenAPI.Model", [], Model, () => [["Tokens", option_type(array_type(APIToken$reflection()))], ["AddForm", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TokensResponse", "ToggleAddForm", "TokenAdded", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("OpenAPI.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(APIToken$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(APIToken$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], [["Item", APIToken$reflection()]], []]);
}

function init() {
    return new Model(void 0, false, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(model.Tokens, msg.fields[0], model.ErrorMsg);
        }
        case 2: {
            const x_2 = msg.fields[0];
            const matchValue = model.Tokens;
            if (matchValue != null) {
                return new Model(append([x_2], matchValue), false, model.ErrorMsg);
            }
            else {
                return new Model([x_2], false, model.ErrorMsg);
            }
        }
        case 3: {
            return new Model(model.Tokens, model.AddForm, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return new Model(model.Tokens, model.AddForm, "Kunne ikke laste API-tokens. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(res.fields[0], model.AddForm, model.ErrorMsg);
            }
        }
    }
}

function fetchTokens(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        return ((props = props_13(), (decoder = Auto_generateBoxedDecoder_79988AEF(array_type(APIToken$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/openapi/token/all", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(APIToken$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(APIToken$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function AddForm(addFormInputProps) {
    let elements_2, children, elems, children_1;
    const onClose = addFormInputProps.onClose;
    const patternInput = useFeliz_React__React_useState_Static_1505(new APIToken("", "", "", true, false, false));
    const updateToken = patternInput[1];
    const token = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_2[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_4, v) => {
        onClose(void 0);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Generer ny API-nøkkel"]]), (children = singleton(createElement("div", createObj(ofArray([["style", {
        maxWidth: 400,
        width: 100 + "%",
        display: "flex",
        flexDirection: "column",
    }], (elems = [MuiHelpers_createElement(FormControlLabel, [["label", "Leserettigheter"], ["control", MuiHelpers_createElement(Checkbox, [["checked", token.ReadPermission], ["onChange", (e) => {
        updateToken(new APIToken(token.Key, token.CreatedBy, token.Created, token.Enabled, Browser_Types_Event__Event_get_Checked(e), token.WritePermission));
    }], ["style", {
        color: "#363636",
    }]])]]), MuiHelpers_createElement(FormControlLabel, [["label", "Skriverettigheter"], ["control", MuiHelpers_createElement(Checkbox, [["checked", token.WritePermission], ["onChange", (e_1) => {
        updateToken(new APIToken(token.Key, token.CreatedBy, token.Created, token.Enabled, token.ReadPermission, Browser_Types_Event__Event_get_Checked(e_1)));
    }], ["style", {
        color: "#363636",
    }]])]]), errorBanner(patternInput_2[0], () => {
        setErrMsg(void 0);
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (patternInput_1[0] ? singleton_1(loadingIndicatorSmall()) : append_1(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Avbryt"], ["onClick", (_arg_6) => {
        onClose(void 0);
    }]])), delay(() => singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["disabled", (!token.WritePermission) && (!token.ReadPermission)], ["children", "Generer"], ["onClick", (_arg_7) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let body, props, decoder, data_1, caseStrategy_3, extra_3;
            return ((body = Auto_generateBoxedEncoder_Z20B7B430(APIToken$reflection(), void 0, void 0, void 0)(token), (props = props_13(), (decoder = Auto_generateBoxedDecoder_79988AEF(APIToken$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/openapi/token/generate", properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(APIToken$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(APIToken$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })()))))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 1) {
                    checkFetchError(res.fields[0]);
                    patternInput_1[1](false);
                    setErrMsg("Kunne ikke generere API-nøkkel. Vennligst prøv igjen senere.");
                    return Promise.resolve();
                }
                else {
                    onClose(res.fields[0]);
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
    }]]))))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

export function OpenAPI() {
    let elements_5;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchTokens(dispatch);
    });
    return MuiHelpers_createElement(Paper, [["elevation", 1], ["style", {
        padding: 10,
        maxWidth: 800,
        margin: 10,
        display: "flex",
        flexDirection: "column",
    }], (elements_5 = toList(delay(() => append_1(singleton_1(snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(3));
    })), delay(() => append_1(model_1.AddForm ? singleton_1(createElement(AddForm, {
        onClose: (newToken) => {
            if (newToken != null) {
                dispatch(new Message(2, newToken));
            }
            else {
                dispatch(new Message(1, false));
            }
        },
    })) : empty_1(), delay(() => {
        let elems, props_1;
        return append_1(singleton_1(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }], (elems = [createElement("span", {
            style: {
                fontSize: 1.5 + "rem",
            },
            children: "API nøkler",
        }), MuiHelpers_createElement(IconButton, [["size", "small"], ["children", (props_1 = {}, react.createElement(Add, props_1))], ["onClick", (_arg_2) => {
            dispatch(new Message(1, !model_1.AddForm));
        }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elements_4, children_2, children_1, children_4;
            const matchValue = model_1.Tokens;
            if (matchValue != null) {
                const x = matchValue;
                return (x.length === 0) ? singleton_1(createElement("span", {
                    style: {
                        fontStyle: "italic",
                    },
                    children: "Ingen API-nøkler registrert",
                })) : singleton_1(MuiHelpers_createElement(Table, [["size", "small"], (elements_4 = [(children_2 = singleton((children_1 = ofArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"], ["children", "Generert"]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"], ["children", "Nøkkel"]]), MuiHelpers_createElement(TableCell, [])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_4 = singleton(map_1((t_1) => {
                    const children_3 = ofArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["children", toLocalDate(t_1.Created)]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["style", {
                        fontSize: 0.6 + "rem",
                    }], ["children", t_1.Key]]), MuiHelpers_createElement(TableCell, [])]);
                    return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]);
                }, x)), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])]));
            }
            else {
                return singleton_1(loadingIndicator());
            }
        }));
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_5))])]);
}

