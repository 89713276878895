import { printf, toText } from "../fable_modules/fable-library.3.7.22/String.js";

export const News_News = "/news";

export const News_InfoScreenNews = "/api/infoscreennews";

export const News_Sms = "/sms";

export const News_Token = "/token";

export const News_RegisterDevice = "/registerdevice";

export const News_UnregisterDevice = "/unregisterdevice";

export const News_MarkAsRead = "/isread";

export const News_Phonenumber = "/phonenumber";

export const Member_All = "/api/member/all";

export const Member_Add = "/api/member/add";

export const Member_AddByCsv = "/api/member/csvadd";

export const Member_Update = "/api/member/update";

export function Member_Delete(memberId) {
    return `/api/member/${memberId}`;
}

export const Member_NotActive = "/api/member/notactive";

export const Member_DownloadAll = "/api/member/download/all";

export const Member_DownloadNotActive = "/api/member/download/notactive";

export const Admin_All = "/api/admin/all";

export const Admin_Add = "/api/admin/add";

export const Admin_Update = "/api/admin/update";

export const Admin_Delete = "/api/admin/delete";

export const Admin_UpdatePassword = "/api/admin/updatepassword";

export const Channel_All = "/api/channel/all";

export const Channel_Add = "/api/channel/add";

export const Channel_Delete = "/api/channel/delete";

export const Channel_Settings = "/api/channel/settings";

export const Channel_SetAccess = "/api/channel/access";

export const Channel_Available = "/api/channel/available";

export const Channel_AddExternal = "/api/channel/external/add";

export const ChannelAccess_Grant = "/api/access/grant";

export const ChannelAccess_Revoke = "/api/access/revoke";

export const Screens_All = "/api/infoscreen/all";

export const Screens_Add = "/api/infoscreen/add";

export function Screens_Delete(screenId) {
    return `/api/infoscreen/${screenId}`;
}

export const Statistics_ActiveLastWeek = "/api/statistics/activeweek";

export const Statistics_Overview = "/api/statistics/stats";

export function Statistics_OverviewInterval(x) {
    return toText(printf("/api/statistics/stats/%d"))(x);
}

export const Statistics_AlertPush = "/api/statistics/pushalert";

export const Statistics_AlertSMS = "/api/statistics/smsalert";

export const Auth_Login = "/api/login";

export const Auth_Logout = "/api/logout";

export const Billing_GetBillingInfo = "/api/billing";

export const Billing_GetPaymentHistory = "/api/paymenthistory";

export const Billing_GetDiscounts = "/api/discounts";

export const Subscription_GetSubscriptionState = "/api/provisioner/subscription/state";

export const Subscription_GetSubscriptionStatus = "/api/provisioner/subscription/status";

export const Subscription_ActivateSubscription = "/api/provisioner/subscription/activate";

export const Subscription_CancelSubscription = "/api/provisioner/subscription/cancel";

export const Subscription_UpgradeSubscription = "/api/provisioner/subscription/upgrade";

export const Subscription_HasDemoSubscription = "/api/provisioner/subscription/isdemo";

