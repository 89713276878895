import { deleteUserSession } from "./LocalStorage.js";
import { parse, toString, toUniversalTime } from "./fable_modules/fable-library.3.7.22/Date.js";
import { split, isNullOrWhiteSpace } from "./fable_modules/fable-library.3.7.22/String.js";

export function tryFocus(item) {
    try {
        window.document.getElementById(item).focus();
    }
    catch (matchValue) {
    }
}

export function checkError(err) {
    try {
        if (err.message.length >= 3) {
            if (err.message.slice(0, 2 + 1) === "401") {
                deleteUserSession();
                window.location.reload();
            }
        }
    }
    catch (matchValue_2) {
    }
}

export function checkFetchError(err) {
    let pattern_matching_result;
    if (err.tag === 2) {
        if ((err.fields[0].status) === 401) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            deleteUserSession();
            window.location.reload();
            break;
        }
        case 1: {
            break;
        }
    }
}

export function toISO8601(dt) {
    let copyOfStruct = toUniversalTime(dt);
    return toString(copyOfStruct, "yyyy-MM-dd HH:mmZ");
}

export function toISODate(dt) {
    try {
        return toString(parse(dt), "yyyy-MM-dd");
    }
    catch (matchValue) {
        return dt;
    }
}

export function parseAsUtc(dt) {
    let copyOfStruct = parse(dt, null, 128);
    return toUniversalTime(copyOfStruct);
}

export function toLocalTime(dt) {
    try {
        return toString(parse(dt), "dd/MM/yyyy HH:mm");
    }
    catch (matchValue) {
        return dt;
    }
}

export function toLocalDate(dt) {
    try {
        return toString(parse(dt), "dd/MM/yyyy");
    }
    catch (matchValue) {
        return dt;
    }
}

function requiredString(str, minLength, maxLength) {
    if ((!isNullOrWhiteSpace(str)) && (str.length >= minLength)) {
        return str.length <= maxLength;
    }
    else {
        return false;
    }
}

export function validateEmail(x) {
    const l = requiredString(x, 6, 256);
    const at = x.indexOf("@") >= 0;
    const dot = x.indexOf(".") >= 0;
    const splitted = split(x, ["@"], null, 0);
    if (splitted.length > 1) {
        if (((((l && at) && dot) && (splitted[1].length > 2)) && (splitted[0].length > 0)) && (splitted[1].indexOf(".") >= 0)) {
            return !(((x.indexOf(",") >= 0) ? true : (x.indexOf("\u0027") >= 0)) ? true : (x.indexOf("\\") >= 0));
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

