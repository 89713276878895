import { Union } from "../fable_modules/fable-library.3.7.22/Types.js";
import { lambda_type, unit_type, string_type, union_type, class_type, obj_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import * as react from "react";
import react_quill from "react-quill";

export class QuillModule extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Toolbar"];
    }
}

export function QuillModule$reflection() {
    return union_type("ReactQuill.QuillModule", [], QuillModule, () => [[["Item", class_type("System.Collections.Generic.IEnumerable`1", [obj_type])]]]);
}

export class QuillProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnChange", "DefaultValue", "Theme"];
    }
}

export function QuillProp$reflection() {
    return union_type("ReactQuill.QuillProp", [], QuillProp, () => [[["Item", lambda_type(string_type, unit_type)]], [["Item", string_type]], [["Item", string_type]]]);
}

export function QuillProp_Modules_636F969F(x) {
    return ["modules", keyValueList(x, 1)];
}

export function quill(props) {
    const props_1 = keyValueList(props, 1);
    return react.createElement(react_quill, props_1);
}

