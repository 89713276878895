import { toString, Record, Union } from "./fable_modules/fable-library.3.7.22/Types.js";
import { GenericMessage$reflection, MemberForm$reflection, MemberForm as MemberForm_1, Member, Member$reflection, ParsedMemberCsv$reflection, MemberCsvForm$reflection, MemberCsvForm as MemberCsvForm_2, MemberCsvResponse$reflection } from "./Shared/ApiDataTypes.js";
import { int32_type, array_type, unit_type, equals, obj_type, record_type, option_type, string_type, bool_type, union_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createElement } from "react";
import * as react from "react";
import { comparePrimitives, stringHash, numberHash, uncurry, createObj } from "./fable_modules/fable-library.3.7.22/Util.js";
import { map as map_1, empty, singleton, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import { cons, empty as empty_1, toArray, singleton as singleton_1, ofArray } from "./fable_modules/fable-library.3.7.22/List.js";
import TableRow from "@material-ui/core/TableRow";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import TableHead from "@material-ui/core/TableHead";
import { sortBy, append as append_1, map } from "./fable_modules/fable-library.3.7.22/Array.js";
import TableBody from "@material-ui/core/TableBody";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { join } from "./fable_modules/fable-library.3.7.22/String.js";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import { rangeDouble } from "./fable_modules/fable-library.3.7.22/Range.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Member_fetchAllMembers, Channel_fetchChannels, props as props_27 } from "./Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { map as map_2, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString as toString_1 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import Button from "@material-ui/core/Button";
import { loadingIndicator, button, snackError, loadingIndicatorSmall, errorBanner } from "./ViewHelpers.js";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { tryFocus, checkFetchError } from "./Utils.js";
import { Array_distinct, Array_distinctBy } from "./fable_modules/fable-library.3.7.22/Seq2.js";
import { Member_Delete, Member_Add, Statistics_ActiveLastWeek } from "./Shared/Urls.js";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import { Interop_reactApi as Interop_reactApi_1 } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

class MultiUserUpload_UploadStep extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SelectFile", "Verify"];
    }
}

function MultiUserUpload_UploadStep$reflection() {
    return union_type("Members.MultiUserUpload.UploadStep", [], MultiUserUpload_UploadStep, () => [[], [["Item", MemberCsvResponse$reflection()]]]);
}

class MultiUserUpload_Delimiter extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Comma", "Semicolon"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? ";" : ",";
    }
}

function MultiUserUpload_Delimiter$reflection() {
    return union_type("Members.MultiUserUpload.Delimiter", [], MultiUserUpload_Delimiter, () => [[], []]);
}

class MultiUserUpload_Model extends Record {
    constructor(Step, Delimit, Processing, ErrorMsg) {
        super();
        this.Step = Step;
        this.Delimit = Delimit;
        this.Processing = Processing;
        this.ErrorMsg = ErrorMsg;
    }
}

function MultiUserUpload_Model$reflection() {
    return record_type("Members.MultiUserUpload.Model", [], MultiUserUpload_Model, () => [["Step", MultiUserUpload_UploadStep$reflection()], ["Delimit", MultiUserUpload_Delimiter$reflection()], ["Processing", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class MultiUserUpload_Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ParseResponse", "SetDelimiter", "SetProcessing", "SetError"];
    }
}

function MultiUserUpload_Message$reflection() {
    return union_type("Members.MultiUserUpload.Message", [], MultiUserUpload_Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [MemberCsvResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", MemberCsvResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", MultiUserUpload_Delimiter$reflection()]], [["Item", bool_type]], [["Item", option_type(string_type)]]]);
}

function MultiUserUpload_init() {
    return new MultiUserUpload_Model(new MultiUserUpload_UploadStep(0), new MultiUserUpload_Delimiter(0), false, void 0);
}

function MultiUserUpload_update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new MultiUserUpload_Model(model.Step, msg.fields[0], model.Processing, model.ErrorMsg);
        }
        case 2: {
            return new MultiUserUpload_Model(model.Step, model.Delimit, msg.fields[0], model.ErrorMsg);
        }
        case 3: {
            return new MultiUserUpload_Model(model.Step, model.Delimit, model.Processing, msg.fields[0]);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                return new MultiUserUpload_Model(model.Step, model.Delimit, false, (err.tag === 2) ? (((err.fields[0].status) === 400) ? "Kunne ikke lese .csv innhold" : "Kunne ikke laste opp brukere. Vennligst prøv igjen senere.") : "Kunne ikke laste opp brukere. Vennligst prøv igjen senere.");
            }
            else {
                return new MultiUserUpload_Model(new MultiUserUpload_UploadStep(1, res.fields[0]), model.Delimit, false, model.ErrorMsg);
            }
        }
    }
}

export function MultiUserUpload_MultiUpload(multiUserUpload_MultiUploadInputProps) {
    let elements_12, children_7, elems_13, children_8;
    const onClose = multiUserUpload_MultiUploadInputProps.onClose;
    const patternInput = useReact_useReducer_2B9E6EA0(MultiUserUpload_update, MultiUserUpload_init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_10, v) => {
        onClose(void 0);
    }], (elements_12 = [MuiHelpers_createElement(DialogTitle, [["children", "Legg til brukere fra .csv fil"]]), (children_7 = ofArray([createElement("div", createObj(ofArray([["style", {}], (elems_13 = toList(delay(() => {
        let value_88, elems_4, elems_2, children, elements, elems_3;
        const matchValue_2 = model_1.Step;
        if (matchValue_2.tag === 1) {
            const x_3 = matchValue_2.fields[0];
            const th$0027 = (t) => MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", t]]);
            const td$0027 = (t_1) => MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", t_1]]);
            return append(singleton((value_88 = (`${x_3.Members.length} av ${x_3.Provided} rader lest`), createElement("span", {
                children: [value_88],
            }))), delay(() => append((x_3.Failed.length > 0) ? append(singleton(createElement("br", {})), delay(() => singleton(createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                },
                children: "Rader som ikke kunne leses listes nederst",
            })))) : empty(), delay(() => {
                let elements_6, children_2, children_1, children_4;
                return append(singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_6 = [(children_2 = singleton_1((children_1 = ofArray([th$0027("Landskode"), th$0027("Telefonnummer"), th$0027("Fornavn"), th$0027("Etternavn")]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_4 = singleton_1(map((m) => {
                    const children_3 = ofArray([td$0027(m.PhoneCC), td$0027(m.Phone), td$0027(m.Firstname), td$0027(m.Lastname)]);
                    return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]);
                }, x_3.Members)), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_6))])])), delay(() => ((x_3.Failed.length > 0) ? append(singleton(createElement("span", {
                    style: {
                        display: "block",
                        marginTop: 20,
                    },
                    children: "Rader vi ikke klarte lese",
                })), delay(() => {
                    let elements_9, children_6;
                    return singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_9 = [(children_6 = singleton_1(map((m_1) => {
                        const children_5 = singleton_1(MuiHelpers_createElement(TableCell, [["style", {
                            backgroundColor: "#ffeeee",
                        }], ["children", m_1]]));
                        return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]);
                    }, x_3.Failed)), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_9))])]));
                })) : empty())));
            }))));
        }
        else {
            return singleton(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }], (elems_4 = [createElement("span", {
                style: {
                    marginBottom: 10,
                    textAlign: "center",
                },
                children: "Last opp en .csv fil med formatet telefonnummer, fornavn, etternavn. Den første raden må starte med teksten \"telefonnummer, fornavn, etternavn\".",
            }), createElement("div", createObj(ofArray([["style", {
                marginBottom: 10,
            }], (elems_2 = [(children = ofArray([MuiHelpers_createElement(FormLabel, [["id", "csv-delimiter-label"], ["label", "Kolonnedeler"]]), MuiHelpers_createElement(RadioGroup, [["aria-labelledby", join(" ", ["csv-delimiter-label"])], ["row", true], ["value", (model_1.Delimit.tag === 1) ? "2" : "1"], ["onChange", (e) => {
                if (Browser_Types_Event__Event_get_Value(e) === "2") {
                    dispatch(new MultiUserUpload_Message(1, new MultiUserUpload_Delimiter(1)));
                }
                else {
                    dispatch(new MultiUserUpload_Message(1, new MultiUserUpload_Delimiter(0)));
                }
            }], (elements = [MuiHelpers_createElement(FormControlLabel, [["value", "1"], ["control", MuiHelpers_createElement(Radio, [["style", {
                color: "#363636",
            }]])], ["label", "Komma (,)"]]), MuiHelpers_createElement(FormControlLabel, [["value", "2"], ["control", MuiHelpers_createElement(Radio, [["style", {
                color: "#363636",
            }]])], ["label", "Semikolon (;)"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])]), MuiHelpers_createElement(FormControl, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("input", {
                id: "input-button",
                type: "file",
                accept: ".csv",
                onChange: (ev_1) => {
                    const fileList = ev_1.target.files;
                    if (!(fileList == null)) {
                        const files = toArray(toList(delay(() => map_1((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1)))));
                        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            if (files.length !== 1) {
                                dispatch(new MultiUserUpload_Message(3, "Vennligst velg kun en fil"));
                                return Promise.resolve();
                            }
                            else {
                                dispatch(new MultiUserUpload_Message(2, true));
                                return (new Promise((resolve, _reject) => {
                                    const reader = new FileReader();
                                    reader.onload = ((_arg) => {
                                        resolve(toString(reader.result));
                                    });
                                    reader.onerror = ((_arg_1) => {
                                    });
                                    reader.readAsDataURL(files[0]);
                                })).then((_arg_2) => {
                                    let item, body, props, decoder, data_1, caseStrategy_3, extra_3;
                                    return ((item = (new MemberCsvForm_2(_arg_2, toString(model_1.Delimit))), (body = Auto_generateBoxedEncoder_Z20B7B430(MemberCsvForm$reflection(), void 0, void 0, void 0)(item), (props = props_27(), (decoder = Auto_generateBoxedDecoder_79988AEF(MemberCsvResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                                        let properties_2, headers_2;
                                        try {
                                            const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty_1()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_2((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                                            const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/member/from-csv", properties_3).then((_arg_3) => {
                                                let response_1, decoder_1_1;
                                                return ((response_1 = _arg_3, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(MemberCsvResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_4) => {
                                                    let matchValue;
                                                    return Promise.resolve(equals(MemberCsvResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_4), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                                                })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                                            }))));
                                            return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
                                        }
                                        catch (exn) {
                                            return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                                        }
                                    })())))))))).then((_arg_5) => {
                                        dispatch(new MultiUserUpload_Message(0, _arg_5));
                                        return Promise.resolve();
                                    });
                                });
                            }
                        }));
                        pr_1.then();
                    }
                },
                hidden: true,
            }), MuiHelpers_createElement(Button, [["color", "default"], ["variant", "outlined"], ["size", "small"], ["style", {
                padding: 0,
            }], (elems_3 = [createElement("label", {
                style: {
                    padding: ((0.5 + "em") + " ") + (1 + "em"),
                },
                className: "MuiButtonBase-root",
                htmlFor: "input-button",
                children: "Velg fil",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), errorBanner(model_1.ErrorMsg, () => {
        dispatch(new MultiUserUpload_Message(3, void 0));
    })]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]])), (children_8 = toList(delay(() => (model_1.Processing ? singleton(loadingIndicatorSmall()) : append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Avbryt"], ["onClick", (_arg_12) => {
        onClose(void 0);
    }]])), delay(() => {
        const matchValue_4 = model_1.Step;
        if (matchValue_4.tag === 1) {
            const v_2 = matchValue_4.fields[0];
            return singleton(MuiHelpers_createElement(Button, [["size", "small"], ["children", `Legg til ${v_2.Members.length} medlemmer`], ["onClick", (_arg_13) => {
                const pr_3 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let body_3, props_1, decoder_3, data_5, caseStrategy_12, extra_12;
                    dispatch(new MultiUserUpload_Message(2, true));
                    return ((body_3 = Auto_generateBoxedEncoder_Z20B7B430(array_type(ParsedMemberCsv$reflection()), void 0, void 0, void 0)(v_2.Members), (props_1 = props_27(), (decoder_3 = Auto_generateBoxedDecoder_79988AEF(array_type(Member$reflection()), void 0, void 0), (data_5 = some(body_3), (caseStrategy_12 = (void 0), (extra_12 = (void 0), (() => {
                        let properties_6, headers_5;
                        try {
                            const properties_3_1 = Helper_withProperties(props_1, (properties_6 = ofArray([new Types_RequestProperties(0, "POST"), (headers_5 = Helper_withContentTypeJson(data_5, empty_1()), new Types_RequestProperties(1, keyValueList(headers_5, 0)))]), defaultArg(map_2((data_1_2) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_12, extra_12)(data_1_2))), properties_6), data_5), properties_6)));
                            const pr_2 = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/member/commit-csv", properties_3_1).then((_arg_6) => {
                                let response_3, decoder_1_2;
                                return ((response_3 = _arg_6, (decoder_1_2 = defaultArg(decoder_3, Auto_generateBoxedDecoderCached_79988AEF(array_type(Member$reflection()), caseStrategy_12, extra_12)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_3.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_3.text().then((_arg_7) => {
                                    let matchValue_1;
                                    return Promise.resolve(equals(array_type(Member$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue_1 = fromString(uncurry(2, decoder_1_2), _arg_7), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue_1.fields[0]))) : (new FSharpResult$2(0, matchValue_1.fields[0])))));
                                })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_3))))).then((_arg_1_2) => (Promise.resolve(_arg_1_2)))))))));
                            }))));
                            return pr_2.then(void 0, ((arg_8) => (new FSharpResult$2(1, new FetchError(3, arg_8)))));
                        }
                        catch (exn_1) {
                            return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn_1))))));
                        }
                    })()))))))).then((_arg_8) => {
                        const res_1 = _arg_8;
                        if (res_1.tag === 1) {
                            dispatch(new MultiUserUpload_Message(2, false));
                            dispatch(new MultiUserUpload_Message(3, "Kunne ikke laste opp medlemmer. Vennligst prøv igjen senere"));
                            return Promise.resolve();
                        }
                        else {
                            onClose(res_1.fields[0]);
                            return Promise.resolve();
                        }
                    });
                }));
                pr_3.then();
            }]]));
        }
        else {
            return empty();
        }
    }))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_12))])]);
}

class SortOrder extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FirstName", "FirstNameDesc", "LastName", "LastNameDesc", "Company", "CompanyDesc", "Phone", "PhoneDesc", "Active", "ActiveDesc"];
    }
}

function SortOrder$reflection() {
    return union_type("Members.SortOrder", [], SortOrder, () => [[], [], [], [], [], [], [], [], [], []]);
}

class Model extends Record {
    constructor(Members, ActivityStat, AddForm, DeleteForm, MemberCsvForm, UpdateForm, Filter, SortBy, Processing, ErrorMsg) {
        super();
        this.Members = Members;
        this.ActivityStat = ActivityStat;
        this.AddForm = AddForm;
        this.DeleteForm = DeleteForm;
        this.MemberCsvForm = MemberCsvForm;
        this.UpdateForm = UpdateForm;
        this.Filter = Filter;
        this.SortBy = SortBy;
        this.Processing = Processing;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Members.Model", [], Model, () => [["Members", option_type(array_type(Member$reflection()))], ["ActivityStat", option_type(int32_type)], ["AddForm", bool_type], ["DeleteForm", option_type(Member$reflection())], ["MemberCsvForm", bool_type], ["UpdateForm", option_type(Member$reflection())], ["Filter", string_type], ["SortBy", SortOrder$reflection()], ["Processing", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["MembersResponse", "ActivityResponse", "ToggleAddForm", "ToggleDeleteForm", "UpdateSort", "UpdateFilter", "MemberAdded", "MemberDeleted", "ToggleMultiUpload", "MultiUsersAdded", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Members.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Member$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Member$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [int32_type, FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", int32_type]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], [["Item", option_type(Member$reflection())]], [["Item", SortOrder$reflection()]], [["Item", string_type]], [["Item", Member$reflection()]], [["Item", int32_type]], [["Item", bool_type]], [["Item", array_type(Member$reflection())]], []]);
}

function init() {
    return new Model(void 0, void 0, false, void 0, false, void 0, "", new SortOrder(0), false, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1, array;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                checkFetchError(res_1.fields[0]);
                return model;
            }
            else {
                return new Model(model.Members, res_1.fields[0], model.AddForm, model.DeleteForm, model.MemberCsvForm, model.UpdateForm, model.Filter, model.SortBy, model.Processing, model.ErrorMsg);
            }
        }
        case 2: {
            return new Model(model.Members, model.ActivityStat, msg.fields[0], model.DeleteForm, model.MemberCsvForm, model.UpdateForm, model.Filter, model.SortBy, model.Processing, model.ErrorMsg);
        }
        case 3: {
            return new Model(model.Members, model.ActivityStat, model.AddForm, msg.fields[0], model.MemberCsvForm, model.UpdateForm, model.Filter, model.SortBy, model.Processing, model.ErrorMsg);
        }
        case 4: {
            return new Model(model.Members, model.ActivityStat, model.AddForm, model.DeleteForm, model.MemberCsvForm, model.UpdateForm, model.Filter, msg.fields[0], model.Processing, model.ErrorMsg);
        }
        case 5: {
            return new Model(model.Members, model.ActivityStat, model.AddForm, model.DeleteForm, model.MemberCsvForm, model.UpdateForm, msg.fields[0], model.SortBy, model.Processing, model.ErrorMsg);
        }
        case 6: {
            const x_6 = msg.fields[0];
            return new Model((matchValue = model.Members, (matchValue != null) ? append_1([x_6], matchValue) : [x_6]), model.ActivityStat, false, model.DeleteForm, model.MemberCsvForm, model.UpdateForm, model.Filter, model.SortBy, model.Processing, model.ErrorMsg);
        }
        case 7: {
            return new Model((matchValue_1 = model.Members, (matchValue_1 != null) ? ((array = matchValue_1, array.filter((m) => (m.Id !== msg.fields[0])))) : (void 0)), model.ActivityStat, model.AddForm, void 0, model.MemberCsvForm, model.UpdateForm, model.Filter, model.SortBy, model.Processing, model.ErrorMsg);
        }
        case 8: {
            return new Model(model.Members, model.ActivityStat, model.AddForm, model.DeleteForm, msg.fields[0], model.UpdateForm, model.Filter, model.SortBy, model.Processing, model.ErrorMsg);
        }
        case 9: {
            const x_9 = msg.fields[0];
            const matchValue_2 = model.Members;
            if (matchValue_2 != null) {
                return new Model(Array_distinctBy((x_10) => x_10.Id, append_1(x_9, matchValue_2), {
                    Equals: (x_11, y_2) => (x_11 === y_2),
                    GetHashCode: numberHash,
                }), model.ActivityStat, model.AddForm, model.DeleteForm, false, model.UpdateForm, model.Filter, model.SortBy, model.Processing, model.ErrorMsg);
            }
            else {
                return new Model(x_9, model.ActivityStat, model.AddForm, model.DeleteForm, false, model.UpdateForm, model.Filter, model.SortBy, model.Processing, model.ErrorMsg);
            }
        }
        case 10: {
            return new Model(model.Members, model.ActivityStat, model.AddForm, model.DeleteForm, model.MemberCsvForm, model.UpdateForm, model.Filter, model.SortBy, model.Processing, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return new Model(model.Members, model.ActivityStat, model.AddForm, model.DeleteForm, model.MemberCsvForm, model.UpdateForm, model.Filter, model.SortBy, model.Processing, "Kunne ikke laste medlemmer. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(res.fields[0], model.ActivityStat, model.AddForm, model.DeleteForm, model.MemberCsvForm, model.UpdateForm, model.Filter, model.SortBy, model.Processing, model.ErrorMsg);
            }
        }
    }
}

function fetchActivity(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        return ((props = props_27(), (decoder = Auto_generateBoxedDecoder_79988AEF(int32_type, void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty_1()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_2((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(Statistics_ActiveLastWeek, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(int32_type, caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(int32_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(1, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

export function MemberForm(memberFormInputProps) {
    let elems_9, elements_2, elems_6, elems_2, elems_1, elems_5, children_2, children_1, elems_7;
    const onClose = memberFormInputProps.onClose;
    const patternInput = useFeliz_React__React_useState_Static_1505(new MemberForm_1(new Member(-1, "", "", "", "", "47", false), []));
    const updateMember = patternInput[1];
    const member$0027 = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505([]);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const anchor = useReact_useRef_1505(void 0);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(true);
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_4[1];
    const processing = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_5[1];
    useReact_useEffectOnce_3A5B6456(() => {
        Channel_fetchChannels((res) => {
            if (res.tag === 0) {
                patternInput_1[1](res.fields[0]);
            }
        });
    });
    const fieldHolder = (children) => createElement("div", {
        style: {
            width: 100 + "%",
            maxWidth: 250,
            marginBottom: 15,
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 100 + "%",
    }], (elems_9 = [snackError(patternInput_5[0], () => {
        setErrMsg(void 0);
    }), MuiHelpers_createElement(Paper, [["style", {
        width: 100 + "%",
        maxWidth: 650,
        padding: 20,
        display: "flex",
        flexDirection: "column",
    }], ["elevation", 1], (elements_2 = [createElement("span", {
        style: {
            fontSize: 1.2 + "rem",
        },
        children: "Legg til medlem",
    }), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
    }], (elems_6 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: ((20 + "px ") + 0) + "px",
    }], (elems_2 = [fieldHolder([MuiHelpers_createElement(TextField, [["defaultValue", member$0027.Member.FirstName], ["onChange", (e) => {
        let inputRecord;
        updateMember(new MemberForm_1((inputRecord = member$0027.Member, new Member(inputRecord.Id, Browser_Types_Event__Event_get_Value(e), inputRecord.LastName, inputRecord.Company, inputRecord.Phone, inputRecord.PhoneCC, inputRecord.UsedAppOnce)), member$0027.Channels));
    }], ["variant", "outlined"], ["label", "Fornavn"], ["fullWidth", true], ["onKeyUp", (kc) => {
        if (kc.code === "Enter") {
            tryFocus("lastname-input");
        }
    }]])]), fieldHolder([MuiHelpers_createElement(TextField, [["id", "lastname-input"], ["defaultValue", member$0027.Member.LastName], ["onChange", (e_1) => {
        let inputRecord_1;
        updateMember(new MemberForm_1((inputRecord_1 = member$0027.Member, new Member(inputRecord_1.Id, inputRecord_1.FirstName, Browser_Types_Event__Event_get_Value(e_1), inputRecord_1.Company, inputRecord_1.Phone, inputRecord_1.PhoneCC, inputRecord_1.UsedAppOnce)), member$0027.Channels));
    }], ["variant", "outlined"], ["label", "Etternavn"], ["fullWidth", true], ["onKeyUp", (kc_1) => {
        if (kc_1.code === "Enter") {
            tryFocus("company-input");
        }
    }]])]), fieldHolder([MuiHelpers_createElement(TextField, [["id", "company-input"], ["defaultValue", member$0027.Member.Company], ["onChange", (e_2) => {
        let inputRecord_2;
        updateMember(new MemberForm_1((inputRecord_2 = member$0027.Member, new Member(inputRecord_2.Id, inputRecord_2.FirstName, inputRecord_2.LastName, Browser_Types_Event__Event_get_Value(e_2), inputRecord_2.Phone, inputRecord_2.PhoneCC, inputRecord_2.UsedAppOnce)), member$0027.Channels));
    }], ["variant", "outlined"], ["label", "Tilhørighet"], ["fullWidth", true], ["onKeyUp", (kc_2) => {
        if (kc_2.code === "Enter") {
            tryFocus("phone-input");
        }
    }]])]), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        maxWidth: 250,
    }], (elems_1 = [MuiHelpers_createElement(TextField, [["defaultValue", member$0027.Member.PhoneCC], ["onChange", (e_3) => {
        let inputRecord_3;
        updateMember(new MemberForm_1((inputRecord_3 = member$0027.Member, new Member(inputRecord_3.Id, inputRecord_3.FirstName, inputRecord_3.LastName, inputRecord_3.Company, inputRecord_3.Phone, Browser_Types_Event__Event_get_Value(e_3), inputRecord_3.UsedAppOnce)), member$0027.Channels));
    }], ["variant", "outlined"], ["style", {
        width: 75,
        marginRight: 5,
    }], ["inputProps", {
        style: {
            textAlign: "center",
        },
    }]]), MuiHelpers_createElement(TextField, [["id", "phone-input"], ["defaultValue", member$0027.Member.Phone], ["onChange", (e_4) => {
        let inputRecord_4;
        updateMember(new MemberForm_1((inputRecord_4 = member$0027.Member, new Member(inputRecord_4.Id, inputRecord_4.FirstName, inputRecord_4.LastName, inputRecord_4.Company, Browser_Types_Event__Event_get_Value(e_4), inputRecord_4.PhoneCC, inputRecord_4.UsedAppOnce)), member$0027.Channels));
    }], ["variant", "outlined"], ["error", !patternInput_3[0]], ["label", "Telefonnummer"], ["fullWidth", true]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        padding: 20,
    }], (elems_5 = [(children_2 = ofArray([MuiHelpers_createElement(FormLabel, [["children", "Kanaltilgang"]]), (children_1 = singleton_1(map((c) => MuiHelpers_createElement(FormControlLabel, [["label", c.Name], ["control", MuiHelpers_createElement(Checkbox, [["checked", member$0027.Channels.some((y_2) => (c.Code === y_2))], ["onChange", (e_5) => {
        if (Browser_Types_Event__Event_get_Checked(e_5)) {
            updateMember(new MemberForm_1(member$0027.Member, Array_distinct(append_1([c.Code], member$0027.Channels), {
                Equals: (x_5, y_3) => (x_5 === y_3),
                GetHashCode: stringHash,
            })));
        }
        else {
            updateMember(new MemberForm_1(member$0027.Member, member$0027.Channels.filter((z) => (z !== c.Code))));
        }
    }], ["style", {
        color: "#363636",
    }]])]]), patternInput_1[0])), MuiHelpers_createElement(FormGroup, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))]), MuiHelpers_createElement(FormControl, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
    }], (elems_7 = [button(processing, (_arg_5) => {
        const trimmedCC = member$0027.Member.PhoneCC.trim();
        const trimmedPhone = member$0027.Member.Phone.trim();
        const validPhone_1 = (trimmedCC === "47") ? (trimmedPhone.length === 8) : (trimmedPhone.length > 0);
        if (validPhone_1) {
            const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let body, props, decoder, data_1, caseStrategy_3, extra_3;
                setProcessing(true);
                return ((body = Auto_generateBoxedEncoder_Z20B7B430(MemberForm$reflection(), void 0, void 0, void 0)(member$0027), (props = props_27(), (decoder = Auto_generateBoxedDecoder_79988AEF(Member$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                    let properties_2, headers_2;
                    try {
                        const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty_1()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_2((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(Member_Add, properties_3).then((_arg_1) => {
                            let response_1, decoder_1_1;
                            return ((response_1 = _arg_1, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Member$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_2) => {
                                let matchValue;
                                return Promise.resolve(equals(Member$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_2), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                            })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                        }))));
                        return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                    }
                    catch (exn) {
                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                    }
                })()))))))).then((_arg_3) => {
                    const res_1 = _arg_3;
                    if (res_1.tag === 1) {
                        const err = res_1.fields[0];
                        checkFetchError(err);
                        setProcessing(false);
                        setErrMsg((err.tag === 2) ? (((err.fields[0].status) === 409) ? "Det eksisterer allerede ett medlem med dette telefonnummeret" : "Kunne ikke legge til medlem. Vennligst prøv igjen senere.") : "Kunne ikke legge til medlem. Vennligst prøv igjen senere.");
                        return Promise.resolve();
                    }
                    else {
                        onClose(res_1.fields[0]);
                        return Promise.resolve();
                    }
                });
            }));
            pr_1.then();
        }
        else {
            patternInput_3[1](validPhone_1);
        }
    }, empty_1(), toList(delay(() => ((!processing) ? singleton("Legg til") : singleton(loadingIndicatorSmall())))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])));
}

function DeleteForm(deleteFormInputProps) {
    let elements_2, children, children_1;
    const onClose = deleteFormInputProps.onClose;
    const x = deleteFormInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    const nameText = ((x.FirstName.length > 0) && (x.LastName.length > 0)) ? (`${x.FirstName} ${x.LastName}`) : ((x.FirstName.length > 0) ? x.FirstName : ((x.LastName.length > 0) ? x.LastName : x.Phone));
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_4, v) => {
        onClose(false);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Fjern medlem"]]), (children = ofArray([MuiHelpers_createElement(DialogContentText, [["children", `Er du sikker på at du ønsker å fjerne ${nameText} som medlem?`]]), errorBanner(patternInput_1[0], () => {
        setErrMsg(void 0);
    })]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (patternInput[0] ? singleton(loadingIndicatorSmall()) : append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Avbryt"], ["onClick", (_arg_6) => {
        onClose(false);
    }]])), delay(() => singleton(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Fjern"], ["onClick", (_arg_7) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let props, decoder, data_1, caseStrategy_2, extra_2;
            setProcessing(true);
            const requestPath = Member_Delete(x.Id);
            return ((props = props_27(), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty_1()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_2((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 1) {
                    checkFetchError(res.fields[0]);
                    setProcessing(false);
                    setErrMsg("Kunne ikke fjerne medlem. Vennligst prøv igjen senere.");
                    return Promise.resolve();
                }
                else {
                    const x_2 = res.fields[0];
                    if (x_2.result === "success") {
                        onClose(true);
                        return Promise.resolve();
                    }
                    else {
                        setErrMsg(x_2.message);
                        setProcessing(false);
                        return Promise.resolve();
                    }
                }
            });
        }));
        pr_1.then();
    }]]))))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function drawMembers(model, dispatch) {
    let elems_10;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        maxWidth: 800,
        width: 100 + "%",
    }], (elems_10 = toList(delay(() => {
        let elems, props, props_1;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
        }], (elems = [MuiHelpers_createElement(Button, [["startIcon", model.AddForm ? ((props = {}, react.createElement(Close, props))) : ((props_1 = {}, react.createElement(Add, props_1)))], ["children", model.AddForm ? "Avbryt" : "Legg til medlem"], ["onClick", (_arg) => {
            dispatch(new Message(2, !model.AddForm));
        }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let matchValue, df;
            return append((matchValue = model.DeleteForm, (matchValue != null) ? ((df = matchValue, singleton(createElement(DeleteForm, {
                x: df,
                onClose: (didDelete) => {
                    if (didDelete) {
                        dispatch(new Message(7, df.Id));
                    }
                    else {
                        dispatch(new Message(3, void 0));
                    }
                },
            })))) : ((empty()))), delay(() => {
                let elements_7, children_3, children_2, elements, children_6;
                if (model.AddForm) {
                    return singleton(createElement(MemberForm, {
                        onClose: (memberOption) => {
                            if (memberOption != null) {
                                dispatch(new Message(6, memberOption));
                            }
                            else {
                                dispatch(new Message(2, false));
                            }
                        },
                    }));
                }
                else {
                    const matchValue_1 = model.Members;
                    if (matchValue_1 != null) {
                        const m_1 = matchValue_1;
                        return append(singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_7 = [(children_3 = singleton_1((children_2 = ofArray([MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["style", {
                            width: 20,
                            padding: 0,
                            textAlign: "center",
                        }], (elements = [MuiHelpers_createElement(Tooltip, [["title", "Har logget inn i Enterprise Mobile News appen"], ["children", createElement("span", {
                            className: "fas fa-mobile-alt",
                        })]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", "Etternavn"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", "Fornavn"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", "Tilhørighet"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", "Telefonnummer"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"]])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]])), (children_6 = singleton_1(map((x_2) => {
                            let elements_3, elements_4, props_18;
                            const children_5 = ofArray([MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["style", {
                                width: 20,
                                padding: 0,
                                textAlign: "center",
                            }], (elements_3 = [createElement("span", {
                                style: {
                                    color: x_2.UsedAppOnce ? "green" : "red",
                                },
                                className: x_2.UsedAppOnce ? "fas fa-check" : "fas fa-times",
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", x_2.LastName]]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", x_2.FirstName]]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", x_2.Company]]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", `+${x_2.PhoneCC} ${x_2.Phone}`]]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], (elements_4 = [MuiHelpers_createElement(IconButton, [["size", "small"], ["children", (props_18 = {}, react.createElement(Delete, props_18))], ["onClick", (_arg_1) => {
                                dispatch(new Message(3, x_2));
                            }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])])]);
                            return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]);
                        }, sortBy((x) => x.LastName, m_1, {
                            Compare: comparePrimitives,
                        }))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_7))])])), delay(() => {
                            let elems_9;
                            return (m_1.length === 0) ? singleton(createElement("div", createObj(ofArray([["style", {
                                textAlign: "center",
                                padding: 10,
                            }], (elems_9 = [createElement("span", {
                                style: {
                                    color: "#9a9a9a",
                                    fontSize: 1.2 + "rem",
                                    fontStyle: "italic",
                                },
                                children: "Ingen medlemmer registrert",
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))) : empty();
                        }));
                    }
                    else {
                        return singleton(loadingIndicator());
                    }
                }
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])));
}

class PieData extends Record {
    constructor(name, vl) {
        super();
        this.name = name;
        this.vl = (vl | 0);
    }
}

function PieData$reflection() {
    return record_type("Members.PieData", [], PieData, () => [["name", string_type], ["vl", int32_type]]);
}

function drawActivity(model, dispatch) {
    let elements_2;
    return MuiHelpers_createElement(Paper, [["style", {
        padding: 10,
        display: "flex",
        flexDirection: "column",
        width: 280,
        margin: 10,
        alignItems: "center",
    }], ["elevation", 1], (elements_2 = toList(delay(() => append(singleton(createElement("span", {
        children: "Antall aktive medlemmer siste 7 dager",
    })), delay(() => {
        let properties_8, properties_7, elements_1, properties_6, elements;
        const matchValue = [model.Members, model.ActivityStat];
        let pattern_matching_result, a, m;
        if (matchValue[0] == null) {
            if (matchValue[1] == null) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 0;
            }
        }
        else if (matchValue[1] != null) {
            pattern_matching_result = 1;
            a = matchValue[1];
            m = matchValue[0];
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                return empty();
            }
            case 1: {
                return (m.length === 0) ? singleton(createElement("span", {
                    style: {
                        textAlign: "center",
                        fontSize: 0.8 + "rem",
                        fontStyle: "italic",
                        color: "#9a9a9a",
                        margin: 5,
                    },
                    children: "Ikke nok medlemmer",
                })) : append(singleton((properties_8 = ofArray([["width", 200], ["height", 200], ["children", (properties_7 = ofArray([["width", 200], ["height", 200], (elements_1 = singleton_1((properties_6 = ofArray([["dataKey", "vl"], ["fill", "#209cee"], ["label", true], ["cx", 50 + "%"], ["cy", 50 + "%"], ["data", [new PieData("Aktiv", a), new PieData("Inaktiv", m.length - a)]], ["outerRadius", 80 + "%"], ["innerRadius", 45 + "%"], (elements = ofArray([Interop_reactApi_1.createElement(Cell, {
                    key: "1",
                    fill: "rgb(255, 207, 1)",
                }), Interop_reactApi_1.createElement(Cell, {
                    key: "2",
                    fill: "#f6f6f6",
                })]), ["children", ["children", Interop_reactApi.Children.toArray(Array.from(elements))]])]), Interop_reactApi_1.createElement(Pie, createObj(properties_6)))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]), Interop_reactApi_1.createElement(PieChart, createObj(properties_7)))]]), Interop_reactApi_1.createElement(ResponsiveContainer, createObj(properties_8)))), delay(() => singleton(createElement("span", {
                    style: {
                        textAlign: "center",
                        fontSize: 0.8 + "rem",
                    },
                    children: `${a} av ${m.length} har brukt Enterprise Mobile News appen de siste 7 dager`,
                }))));
            }
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function csvAdd(model, dispatch) {
    let elements;
    return MuiHelpers_createElement(Paper, [["style", {
        padding: 10,
        display: "flex",
        flexDirection: "column",
        width: 280,
        margin: 10,
        alignItems: "center",
    }], ["elevation", 1], (elements = [createElement("span", {
        style: {
            marginBottom: 10,
        },
        children: "Legg til mange medlemmer",
    }), createElement("button", {
        style: {
            border: "none",
            backgroundColor: "#363636",
            width: 150,
            color: "#FFFFFF",
            padding: 10,
            fontSize: 16 + "px",
            cursor: "pointer",
            borderRadius: 5,
        },
        onClick: (_arg) => {
            dispatch(new Message(8, !model.MemberCsvForm));
        },
        children: "Last opp .csv fil",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
}

export function Members() {
    let elems_1;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        Member_fetchAllMembers((arg_1) => {
            dispatch(new Message(0, arg_1));
        });
        fetchActivity(dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
    }], (elems_1 = toList(delay(() => append(singleton(snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(10));
    })), delay(() => append(model_1.MemberCsvForm ? singleton(createElement(MultiUserUpload_MultiUpload, {
        onClose: (newMembers) => {
            if (newMembers != null) {
                dispatch(new Message(9, newMembers));
            }
            else {
                dispatch(new Message(8, false));
            }
        },
    })) : empty(), delay(() => append(singleton(drawMembers(model_1, dispatch)), delay(() => {
        let elems;
        return singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
        }], (elems = [drawActivity(model_1, dispatch), csvAdd(model_1, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
    })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

