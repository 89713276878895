import { Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { record_type, class_type, obj_type, float64_type, string_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import * as react from "@sentry/react";

export class SentryConfig extends Record {
    constructor(dsn, tracesSampleRate, integrations) {
        super();
        this.dsn = dsn;
        this.tracesSampleRate = tracesSampleRate;
        this.integrations = integrations;
    }
}

export function SentryConfig$reflection() {
    return record_type("Sentry.SentryConfig", [], SentryConfig, () => [["dsn", string_type], ["tracesSampleRate", float64_type], ["integrations", class_type("System.Collections.Generic.IEnumerable`1", [obj_type])]]);
}

export function init() {
    react.init(new SentryConfig("https://d3caccb07c9b23c5a9590ca5eddc9c96@o990465.ingest.sentry.io/4505629515382784", 0.1, [new react.BrowserTracing({tracePropagationTargets: ["/^(https?://)?(?:[^./]+\.)?(mobilenews|localhost)\.no/"]})]));
}

