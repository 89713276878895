import { Record, Union } from "../fable_modules/fable-library.3.7.22/Types.js";
import { SharePoint_SharePointConfig, GenericResponse$reflection, SharePoint_SharePointConfig$reflection } from "../Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, record_type, option_type, string_type, int32_type, union_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { props as props_25 } from "../Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "../fable_modules/fable-library.3.7.22/Util.js";
import { createElement } from "react";
import * as react from "react";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import { newDomain } from "../Pages.js";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { loadingIndicator, snackError } from "../ViewHelpers.js";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

class ConfigStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Loading", "NotCreated", "Loaded", "Saved"];
    }
}

function ConfigStatus$reflection() {
    return union_type("SharePointSettings.ConfigStatus", [], ConfigStatus, () => [[], [], [["Item", SharePoint_SharePointConfig$reflection()]], [["Item", SharePoint_SharePointConfig$reflection()]]]);
}

class Model extends Record {
    constructor(ChannelId, Config, ErrorMsg) {
        super();
        this.ChannelId = (ChannelId | 0);
        this.Config = Config;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("SharePointSettings.Model", [], Model, () => [["ChannelId", int32_type], ["Config", ConfigStatus$reflection()], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ConfigResponse", "UpdateMainDomain", "UpdateSharePointDomain", "UpdateClientId", "UpdateClientSecret", "UpdateSiteRealm", "CommitResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("SharePointSettings.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [SharePoint_SharePointConfig$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", SharePoint_SharePointConfig$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(channelId) {
    return new Model(channelId, new ConfigStatus(0), void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const matchValue = model.Config;
            if (matchValue.tag === 2) {
                const c = matchValue.fields[0];
                return new Model(model.ChannelId, new ConfigStatus(2, new SharePoint_SharePointConfig(msg.fields[0], c.SpDomain, c.NewsList, c.ClientId, c.ClientSecret, c.RedirectUri, c.SiteRealm, c.Filters, c.AccessToken, c.NumNewsItems, c.MaxDaysAgo, c.Configured, c.FetchType)), model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 2: {
            const matchValue_1 = model.Config;
            if (matchValue_1.tag === 2) {
                const c_1 = matchValue_1.fields[0];
                return new Model(model.ChannelId, new ConfigStatus(2, new SharePoint_SharePointConfig(c_1.MainDomain, msg.fields[0], c_1.NewsList, c_1.ClientId, c_1.ClientSecret, c_1.RedirectUri, c_1.SiteRealm, c_1.Filters, c_1.AccessToken, c_1.NumNewsItems, c_1.MaxDaysAgo, c_1.Configured, c_1.FetchType)), model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 3: {
            const matchValue_2 = model.Config;
            if (matchValue_2.tag === 2) {
                const c_2 = matchValue_2.fields[0];
                return new Model(model.ChannelId, new ConfigStatus(2, new SharePoint_SharePointConfig(c_2.MainDomain, c_2.SpDomain, c_2.NewsList, msg.fields[0], c_2.ClientSecret, c_2.RedirectUri, c_2.SiteRealm, c_2.Filters, c_2.AccessToken, c_2.NumNewsItems, c_2.MaxDaysAgo, c_2.Configured, c_2.FetchType)), model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 4: {
            const matchValue_3 = model.Config;
            if (matchValue_3.tag === 2) {
                const c_3 = matchValue_3.fields[0];
                return new Model(model.ChannelId, new ConfigStatus(2, new SharePoint_SharePointConfig(c_3.MainDomain, c_3.SpDomain, c_3.NewsList, c_3.ClientId, msg.fields[0], c_3.RedirectUri, c_3.SiteRealm, c_3.Filters, c_3.AccessToken, c_3.NumNewsItems, c_3.MaxDaysAgo, c_3.Configured, c_3.FetchType)), model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 5: {
            const matchValue_4 = model.Config;
            if (matchValue_4.tag === 2) {
                const c_4 = matchValue_4.fields[0];
                return new Model(model.ChannelId, new ConfigStatus(2, new SharePoint_SharePointConfig(c_4.MainDomain, c_4.SpDomain, c_4.NewsList, c_4.ClientId, c_4.ClientSecret, c_4.RedirectUri, msg.fields[0], c_4.Filters, c_4.AccessToken, c_4.NumNewsItems, c_4.MaxDaysAgo, c_4.Configured, c_4.FetchType)), model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 6: {
            const res_1 = msg.fields[0];
            const matchValue_5 = model.Config;
            if (matchValue_5.tag === 2) {
                if (res_1.tag === 0) {
                    const x_6 = res_1.fields[0];
                    if (x_6.Result === "success") {
                        return new Model(model.ChannelId, new ConfigStatus(3, matchValue_5.fields[0]), model.ErrorMsg);
                    }
                    else {
                        return new Model(model.ChannelId, model.Config, x_6.Message);
                    }
                }
                else {
                    return new Model(model.ChannelId, model.Config, "Kunne ikke lagre SharePoint-innstillinger. Vennligst prøv igjen senere.");
                }
            }
            else {
                return model;
            }
        }
        case 7: {
            return new Model(model.ChannelId, model.Config, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                let pattern_matching_result;
                if (err.tag === 2) {
                    if ((err.fields[0].status) === 410) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return new Model(model.ChannelId, new ConfigStatus(1), model.ErrorMsg);
                    }
                    case 1: {
                        return new Model(model.ChannelId, model.Config, "Kunne ikke laste SharePoint-innstillinger. Vennligst prøv igjen senere.");
                    }
                }
            }
            else {
                return new Model(model.ChannelId, new ConfigStatus(2, res.fields[0]), model.ErrorMsg);
            }
        }
    }
}

function fetchConfig(channelId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = `/api/channel/sharepoint-config/${channelId}`;
        return ((props = props_25(), (decoder = Auto_generateBoxedDecoder_79988AEF(SharePoint_SharePointConfig$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(SharePoint_SharePointConfig$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(SharePoint_SharePointConfig$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function tryCommit(channelId, config, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, props, decoder, data_1, caseStrategy_3, extra_3;
        const requestPath = `/api/channel/sharepoint-config/${channelId}`;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(SharePoint_SharePointConfig$reflection(), void 0, void 0, void 0)(config), (props = props_25(), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))))).then((_arg_2) => {
            dispatch(new Message(6, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function getAuthUrl(fullDomain, clientId, redirectUri) {
    return `${fullDomain}/_layouts/15/OAuthAuthorize.aspx?client_id=${clientId}&scope=Web.Read&response_type=code&redirect_uri=${redirectUri}`;
}

function permissionString(domain) {
    return `<AppPermissionRequests AllowAppOnlyPolicy="true"><AppPermissionRequest Scope="https://${domain}" Right="Read" /></AppPermissionRequests>`;
}

function fieldHeader(x) {
    return createElement("span", {
        style: {
            fontSize: 0.8 + "rem",
        },
        children: x,
    });
}

function fieldHolder(x) {
    return MuiHelpers_createElement(Paper, [["elevation", 1], ["style", {
        display: "flex",
        flexDirection: "column",
        width: 100 + "%",
        maxWidth: 550,
        marginBottom: 30,
        padding: 20,
    }], ["children", Interop_reactApi.Children.toArray(Array.from(x))]]);
}

function sectionTitle(x) {
    return MuiHelpers_createElement(Typography, [["variant", "h6"], ["style", {
        marginBottom: 5,
    }], ["children", x]]);
}

function drawConfig(model, dispatch, x) {
    let elems_4, elements_3, children_2, children, children_1;
    return createElement("div", createObj(ofArray([["style", {
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 100 + "%",
    }], (elems_4 = [fieldHolder(ofArray([sectionTitle("1. SharePoint info"), fieldHeader("SharePoint-domene"), MuiHelpers_createElement(TextField, [["style", {
        marginTop: 5,
        marginBottom: 10,
    }], ["size", "small"], ["variant", "outlined"], ["defaultValue", x.MainDomain], ["fullWidth", true], ["label", "xxx.sharepoint.com"], ["onChange", (e) => {
        dispatch(new Message(1, Browser_Types_Event__Event_get_Value(e)));
    }]]), fieldHeader("Domene med sti"), MuiHelpers_createElement(TextField, [["style", {
        marginTop: 5,
        marginBottom: 10,
    }], ["size", "small"], ["variant", "outlined"], ["defaultValue", x.SpDomain], ["fullWidth", true], ["label", `https://${x.MainDomain}/sti/til/subfolder`], ["onChange", (e_1) => {
        dispatch(new Message(2, Browser_Types_Event__Event_get_Value(e_1)));
    }]])])), fieldHolder(ofArray([sectionTitle("2. Registrer i SharePoint"), createElement("span", {
        style: {},
        children: "For å kunne hente nyheter krever SharePoint at du registrerer Enterprise Mobile News som en app. Dette kan du gjøre i lenken under. Logg deg inn på SharePoint og derfra registrer Enterprise Mobile News.",
    }), MuiHelpers_createElement(Table, [["style", {
        marginTop: 5,
    }], ["size", "small"], (elements_3 = [(children_2 = ofArray([(children = ofArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"], ["children", "Appdomene"]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["children", newDomain]])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = ofArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"], ["children", "URI for omdirigering"]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["children", `https://${newDomain}/sharepoint/refresh`]])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])]), createElement("span", {
        style: {
            fontStyle: "italic",
            margin: ((10 + "px ") + 0) + "px",
        },
        children: "Skriv ned verdiene ofr Client ID og Client Secret som blir generert",
    }), createElement("a", {
        target: "_blank",
        href: `https://${x.MainDomain}/_layouts/15/appregnew.aspx`,
        children: "Trykk her for å registrere Enterprise Mobile News som SharePoint app",
    })])), fieldHolder(ofArray([sectionTitle("3. Fyll inn informasjonen generert av SharePoint i feltene under."), fieldHeader("Klient-ID"), MuiHelpers_createElement(TextField, [["style", {
        marginTop: 5,
        marginBottom: 10,
    }], ["size", "small"], ["variant", "outlined"], ["defaultValue", x.ClientId], ["fullWidth", true], ["onChange", (e_2) => {
        dispatch(new Message(3, Browser_Types_Event__Event_get_Value(e_2)));
    }]]), fieldHeader("Klient-hemmelighet"), MuiHelpers_createElement(TextField, [["style", {
        marginTop: 5,
        marginBottom: 10,
    }], ["size", "small"], ["variant", "outlined"], ["defaultValue", x.ClientSecret], ["fullWidth", true], ["onChange", (e_3) => {
        dispatch(new Message(4, Browser_Types_Event__Event_get_Value(e_3)));
    }]])])), fieldHolder(ofArray([sectionTitle("4. Gi tilgang"), createElement("span", {
        children: "Neste steg er å gi Enterprise Mobile News appen du nettopp har opprettet tilgang til å lese nyheter. Klikk på lenken under for å opprette en ny invitasjon. I feltet \"App Id\" limer du inn Client Id/Klient-ID og trykker på \"oppslag\".",
    }), createElement("a", {
        target: "_blank",
        href: `https://${x.MainDomain}/_layouts/15/appinv.aspx`,
        style: {
            margin: ((10 + "px ") + 0) + "px",
        },
        children: "Følg denne lenken for å gi Enterprise Mobile News rettigheter til å lese nyheter",
    }), createElement("span", {
        children: "For at Enterprise Mobile News skal få tilgang til listen med nyheter krever SharePoint at du spesifiserer rettighetene i XML-format. I feltet \"Permission Request XML\" limer du inn følgende:",
    }), createElement("code", {
        style: {
            backgroundColor: "#FFFFFF",
            borderRadius: 5,
            padding: 10,
            marginTop: 10,
        },
        children: permissionString(x.MainDomain),
    })])), fieldHolder(ofArray([sectionTitle("5. Site realm"), createElement("span", {
        children: "På siden over app-rettigheter i SharePoint finner du listen over dine apper knyttet opp mot SharePoint. Hvis du har registrert Enterprise Mobile News korrekt skal du kunne finne den her.",
    }), createElement("a", {
        target: "_blank",
        href: `https://${x.MainDomain}/_layouts/15/appprincipals.aspx`,
        style: {
            margin: ((10 + "px ") + 0) + "px",
        },
        children: "Lenke til app-rettigheter",
    }), createElement("span", {
        children: "Under \"App Identifier\" kolonnen for Enterprise Mobile News appen kopierer du ut koden som står etter @ tegnet og limer inn i feltet under.",
    }), fieldHeader("Site realm"), MuiHelpers_createElement(TextField, [["style", {
        marginTop: 5,
        marginBottom: 10,
    }], ["size", "small"], ["variant", "outlined"], ["defaultValue", x.SiteRealm], ["fullWidth", true], ["onChange", (e_4) => {
        dispatch(new Message(5, Browser_Types_Event__Event_get_Value(e_4)));
    }]])])), MuiHelpers_createElement(Button, [["disabled", ((((x.MainDomain.length === 0) ? true : (x.SpDomain.length === 0)) ? true : (x.ClientId.length === 0)) ? true : (x.ClientSecret.length === 0)) ? true : (x.SiteRealm.length === 0)], ["children", "Lagre"], ["onClick", (_arg) => {
        tryCommit(model.ChannelId, x, dispatch);
    }]]), createElement("span", {
        style: {
            margin: ((10 + "px ") + 0) + "px",
        },
        children: "eller ...",
    }), createElement("a", {
        target: "_blank",
        href: getAuthUrl(x.SpDomain, x.ClientId, x.RedirectUri),
        children: "Gi tilgang på nytt",
    }), createElement("b", {
        children: ["NB: Brukeren som gir tilgang må ha admin rolle for SharePoint-site det gjelder"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function Settings(settingsInputProps) {
    let elems_2;
    const onClose = settingsInputProps.onClose;
    const channelId = settingsInputProps.channelId;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(channelId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchConfig(channelId, dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    }], (elems_2 = toList(delay(() => append(singleton(snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(7));
    })), delay(() => {
        let elems, props;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            width: 100 + "%",
        }], (elems = [MuiHelpers_createElement(IconButton, [["children", (props = {}, react.createElement(Close, props))], ["onClick", (_arg_2) => {
            onClose();
        }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(singleton(MuiHelpers_createElement(Typography, [["variant", "h5"], ["children", "SharePoint konfigurasjon"]])), delay(() => {
            let elems_1;
            const matchValue = model_1.Config;
            switch (matchValue.tag) {
                case 1: {
                    return singleton(createElement("span", {
                        children: ["not created"],
                    }));
                }
                case 2: {
                    return singleton(drawConfig(model_1, dispatch, matchValue.fields[0]));
                }
                case 3: {
                    const x_1 = matchValue.fields[0];
                    return singleton(createElement("div", createObj(ofArray([["style", {
                        margin: 20,
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 15 + "px ",
                    }], (elems_1 = [MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", "Innstillinger lagret"]]), createElement("a", {
                        href: getAuthUrl(x_1.SpDomain, x_1.ClientId, x_1.RedirectUri),
                        target: "_blank",
                        children: "Trykk her for å starte hente nyheter",
                    }), createElement("b", {
                        children: ["NB: Du må ha admin rolle for SharePoint-site det gjelder for å starte hente nyheter."],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
                }
                default: {
                    return singleton(loadingIndicator());
                }
            }
        }))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

