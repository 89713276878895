import { Record, Union } from "./fable_modules/fable-library.3.7.22/Types.js";
import { anonRecord_type, unit_type, equals, obj_type, record_type, option_type, union_type, string_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { GenericMessage$reflection } from "./Shared/ApiDataTypes.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { createElement } from "react";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { RouterModule_nav } from "./fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { loadingIndicator } from "./ViewHelpers.js";

class ValidateState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Processing", "Success", "Expired", "Failed"];
    }
}

function ValidateState$reflection() {
    return union_type("PasswordRecovery.ValidateState", [], ValidateState, () => [[], [], [], [["Item", string_type]]]);
}

class RecoveryState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotInitiated", "Processing", "Success", "Failed"];
    }
}

function RecoveryState$reflection() {
    return union_type("PasswordRecovery.RecoveryState", [], RecoveryState, () => [[], [], [], [["Item", string_type]]]);
}

class Model extends Record {
    constructor(Token, Validate, Recovery, Password, PasswordRepeat, ErrorMsg) {
        super();
        this.Token = Token;
        this.Validate = Validate;
        this.Recovery = Recovery;
        this.Password = Password;
        this.PasswordRepeat = PasswordRepeat;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("PasswordRecovery.Model", [], Model, () => [["Token", string_type], ["Validate", ValidateState$reflection()], ["Recovery", RecoveryState$reflection()], ["Password", string_type], ["PasswordRepeat", string_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdatePassword", "UpdatePasswordRepeat", "ValidateResponse", "RecoveryResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("PasswordRecovery.Message", [], Message, () => [[["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(token) {
    return new Model(token, new ValidateState(0), new RecoveryState(0), "", "", void 0);
}

function update(model, msg) {
    let y;
    switch (msg.tag) {
        case 1: {
            return new Model(model.Token, model.Validate, model.Recovery, model.Password, msg.fields[0], model.ErrorMsg);
        }
        case 2: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                let pattern_matching_result;
                if (err.tag === 2) {
                    if ((y = err.fields[0], (((y.status) === 410) ? true : ((y.status) === 409)) ? true : ((y.status) === 400))) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return new Model(model.Token, new ValidateState(2), model.Recovery, model.Password, model.PasswordRepeat, model.ErrorMsg);
                    }
                    case 1: {
                        return new Model(model.Token, new ValidateState(3, "Kunne ikke validere tilbakestillingslenken. Vennligst prøv igjen senere."), model.Recovery, model.Password, model.PasswordRepeat, model.ErrorMsg);
                    }
                }
            }
            else {
                const x_2 = res.fields[0];
                if (x_2.result === "success") {
                    return new Model(model.Token, new ValidateState(1), model.Recovery, model.Password, model.PasswordRepeat, model.ErrorMsg);
                }
                else {
                    return new Model(model.Token, new ValidateState(3, x_2.message), model.Recovery, model.Password, model.PasswordRepeat, model.ErrorMsg);
                }
            }
        }
        case 3: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Token, model.Validate, new RecoveryState(3, "Kunne ikke starte tilbakestilling av passord. Vennligst prøv igjen senere."), model.Password, model.PasswordRepeat, model.ErrorMsg);
            }
            else {
                const x_3 = res_1.fields[0];
                if (x_3.result === "success") {
                    return new Model(model.Token, model.Validate, new RecoveryState(2), model.Password, model.PasswordRepeat, model.ErrorMsg);
                }
                else {
                    return new Model(model.Token, model.Validate, new RecoveryState(3, x_3.message), model.Password, model.PasswordRepeat, model.ErrorMsg);
                }
            }
        }
        case 4: {
            return new Model(model.Token, model.Validate, model.Recovery, model.Password, model.PasswordRepeat, void 0);
        }
        default: {
            return new Model(model.Token, model.Validate, model.Recovery, msg.fields[0], model.PasswordRepeat, model.ErrorMsg);
        }
    }
}

function tryValidate(token, userId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = `/api/password-recovery/validate?token=${token}&uid=${userId}`;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(2, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function tryRecover(token, userId, password, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let item, body, decoder, data_1, caseStrategy_3, extra_3;
        const requestPath = `/api/password-recovery/recover?token=${token}&uid=${userId}`;
        return ((item = {
            Password: password,
        }, (body = Auto_generateBoxedEncoder_Z20B7B430(anonRecord_type(["Password", string_type]), void 0, void 0, void 0)(item), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))))).then((_arg_2) => {
            dispatch(new Message(3, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

export function Recover(recoverInputProps) {
    let elems_3, elements;
    const userId = recoverInputProps.userId;
    const token = recoverInputProps.token;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(token));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        tryValidate(token, userId, dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 100 + "%",
    }], (elems_3 = [MuiHelpers_createElement(Paper, [["elevation", 1], ["style", {
        padding: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }], (elements = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
            maxWidth: 350,
        }], (elems = [createElement("img", {
            style: {
                width: 100 + "%",
            },
            src: "/images/logo_text.png",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            const matchValue = model_1.Validate;
            switch (matchValue.tag) {
                case 2: {
                    return singleton_1(createElement("span", {
                        children: ["Tilbakestillingslenke er utløpt eller ugyldig"],
                    }));
                }
                case 3: {
                    return singleton_1(createElement("span", {
                        children: [matchValue.fields[0]],
                    }));
                }
                case 1: {
                    const matchValue_1 = model_1.Recovery;
                    switch (matchValue_1.tag) {
                        case 3: {
                            return singleton_1(createElement("span", {
                                children: [matchValue_1.fields[0]],
                            }));
                        }
                        case 2: {
                            return append(singleton_1(createElement("span", {
                                style: {
                                    fontSize: 1.4 + "rem",
                                    marginBottom: 20,
                                },
                                children: "Passord endret",
                            })), delay(() => singleton_1(createElement("a", {
                                style: {
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                },
                                onClick: (_arg_1) => {
                                    RouterModule_nav(singleton(""), 1, 2);
                                },
                                children: "Til innlogging",
                            }))));
                        }
                        case 0: {
                            return append(singleton_1(createElement("span", {
                                style: {
                                    marginBottom: 20,
                                },
                                children: "Velg ett nytt passord for brukeren.",
                            })), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["type", "password"], ["id", "pw"], ["autoFocus", true], ["label", "Passord"], ["variant", "outlined"], ["fullWidth", true], ["onChange", (e) => {
                                dispatch(new Message(0, Browser_Types_Event__Event_get_Value(e)));
                            }], ["defaultValue", model_1.Password], ["helperText", "Minst 8 tegn"], ["style", {
                                marginBottom: 20,
                            }]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["type", "password"]), delay(() => append(singleton_1(["id", "pw-repeat"]), delay(() => append(singleton_1(["label", "Gjenta passord"]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["fullWidth", true]), delay(() => append(singleton_1(["onChange", (e_1) => {
                                dispatch(new Message(1, Browser_Types_Event__Event_get_Value(e_1)));
                            }]), delay(() => append(singleton_1(["defaultValue", model_1.PasswordRepeat]), delay(() => append(((model_1.PasswordRepeat.length > 0) && (model_1.Password !== model_1.PasswordRepeat)) ? singleton_1(["helperText", "Passord er ikke like"]) : singleton_1(["helperText", ""]), delay(() => singleton_1(["style", {
                                marginBottom: 20,
                            }]))))))))))))))))))))), delay(() => {
                                let elems_1;
                                return singleton_1(createElement("div", createObj(ofArray([["style", {
                                    display: "flex",
                                    justifyContent: "center",
                                }], (elems_1 = [createElement("button", {
                                    disabled: (model_1.Password.length < 8) ? true : (model_1.Password !== model_1.PasswordRepeat),
                                    style: createObj(toList(delay(() => append(singleton_1(["border", "none"]), delay(() => append(((model_1.Password.length < 8) ? true : (model_1.Password !== model_1.PasswordRepeat)) ? append(singleton_1(["opacity", 0.2]), delay(() => singleton_1(["cursor", "not-allowed"]))) : singleton_1(["cursor", "pointer"]), delay(() => append(singleton_1(["backgroundColor", "#363636"]), delay(() => append(singleton_1(["width", 200]), delay(() => append(singleton_1(["color", "#FFFFFF"]), delay(() => append(singleton_1(["padding", 10]), delay(() => append(singleton_1(["fontSize", 16 + "px"]), delay(() => singleton_1(["borderRadius", 5])))))))))))))))))),
                                    onClick: (_arg_2) => {
                                        if ((model_1.Password.length >= 8) && (model_1.Password === model_1.PasswordRepeat)) {
                                            tryRecover(token, userId, model_1.Password, dispatch);
                                        }
                                    },
                                    children: "Tilbakestill passord",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
                            }))))));
                        }
                        default: {
                            return singleton_1(loadingIndicator());
                        }
                    }
                }
                default: {
                    return singleton_1(loadingIndicator());
                }
            }
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

