import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { UserSession$reflection } from "./Shared/ApiDataTypes.js";
import { Auto_generateBoxedEncoder_Z20B7B430, toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.22/String.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { string_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";

export function tryLoadSession() {
    try {
        let x;
        const value = localStorage.getItem("mobilenewsno");
        x = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(UserSession$reflection(), void 0, void 0)), value);
        return (x.tag === 0) ? x.fields[0] : (void 0);
    }
    catch (matchValue) {
        return void 0;
    }
}

export function storeUserSession(entry) {
    try {
        const uSess = toString(4, Auto_generateBoxedEncoder_Z20B7B430(UserSession$reflection(), void 0, void 0, void 0)(entry));
        localStorage.setItem("mobilenewsno", uSess);
    }
    catch (matchValue) {
    }
}

export function deleteUserSession() {
    try {
        localStorage.removeItem("mobilenewsno");
    }
    catch (matchValue) {
    }
}

export const RememberMeKey = (() => {
    const arg = tenantId;
    return toText(printf("%s-rememberme"))(arg);
})();

export function tryGetRememberMe() {
    try {
        const value = localStorage.getItem(RememberMeKey);
        return fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(string_type, void 0, void 0)), value);
    }
    catch (matchValue) {
        return new FSharpResult$2(1, "");
    }
}

export function storeRememberMe(username) {
    try {
        const x = toString(4, Auto_generateBoxedEncoder_Z20B7B430(string_type, void 0, void 0, void 0)(username));
        localStorage.setItem(RememberMeKey, x);
    }
    catch (matchValue) {
    }
}

export function deleteRememberMe() {
    try {
        localStorage.removeItem(RememberMeKey);
    }
    catch (matchValue) {
    }
}

