import { toString, Union, Record } from "../fable_modules/fable-library.3.7.22/Types.js";
import { ChannelForm, ApiChannelSettings$reflection, Channel$reflection, ChannelForm$reflection } from "../Shared/ApiDataTypes.js";
import { unit_type, equals as equals_1, obj_type, union_type, int32_type, record_type, array_type, option_type, string_type, bool_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { ChannelAccess, ChannelAccess_FromInt_Z524259A4, ChannelAccess__ToInt, ChannelSource, ChannelSource__ToInt } from "../Shared/Shared.js";
import { createElement } from "react";
import { uncurry, equals, createObj } from "../fable_modules/fable-library.3.7.22/Util.js";
import { cons, empty as empty_1, ofArray, map, toArray } from "../fable_modules/fable-library.3.7.22/List.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Tooltip from "@material-ui/core/Tooltip";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { loadingIndicatorSmall, snackError } from "../ViewHelpers.js";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { map as map_1 } from "../fable_modules/fable-library.3.7.22/Array.js";
import MenuItem from "@material-ui/core/MenuItem";
import { Settings } from "./ApiSettings.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { basePath } from "../Pages.js";
import { Channel_Add } from "../Shared/Urls.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { props as props_17 } from "../Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { map as map_2, defaultArg, some } from "../fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString as toString_1 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { checkFetchError } from "../Utils.js";

class Model extends Record {
    constructor(Form, ValidName, ValidCode, Processing, ErrorMsg, Channels) {
        super();
        this.Form = Form;
        this.ValidName = ValidName;
        this.ValidCode = ValidCode;
        this.Processing = Processing;
        this.ErrorMsg = ErrorMsg;
        this.Channels = Channels;
    }
}

function Model$reflection() {
    return record_type("ChannelForm.Model", [], Model, () => [["Form", ChannelForm$reflection()], ["ValidName", bool_type], ["ValidCode", bool_type], ["Processing", bool_type], ["ErrorMsg", option_type(string_type)], ["Channels", option_type(array_type(Channel$reflection()))]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateName", "UpdateCode", "UpdateAccess", "UpdateSource", "SetApiSettings", "InvalidateName", "InvalidateCode", "ToggleProcessing", "SetError", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ChannelForm.Message", [], Message, () => [[["Item", string_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", ApiChannelSettings$reflection()]], [], [], [["Item", bool_type]], [["Item", string_type]], []]);
}

function init(updating, channels) {
    return new Model((updating == null) ? (new ChannelForm(void 0, "", "", 1, ChannelSource__ToInt(new ChannelSource(0)), void 0)) : updating, true, true, false, void 0, channels);
}

function update(model, msg) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord;
    switch (msg.tag) {
        case 1: {
            const x_1 = msg.fields[0];
            return new Model((inputRecord_1 = model.Form, new ChannelForm(inputRecord_1.Id, inputRecord_1.Name, x_1, inputRecord_1.Access, inputRecord_1.SourceType, inputRecord_1.ApiSettings)), model.ValidName, x_1.trim().length > 0, model.Processing, model.ErrorMsg, model.Channels);
        }
        case 2: {
            return new Model((inputRecord_2 = model.Form, new ChannelForm(inputRecord_2.Id, inputRecord_2.Name, inputRecord_2.Code, msg.fields[0], inputRecord_2.SourceType, inputRecord_2.ApiSettings)), model.ValidName, model.ValidCode, model.Processing, model.ErrorMsg, model.Channels);
        }
        case 3: {
            return new Model((inputRecord_3 = model.Form, new ChannelForm(inputRecord_3.Id, inputRecord_3.Name, inputRecord_3.Code, inputRecord_3.Access, msg.fields[0], inputRecord_3.ApiSettings)), model.ValidName, model.ValidCode, model.Processing, model.ErrorMsg, model.Channels);
        }
        case 4: {
            return new Model((inputRecord_4 = model.Form, new ChannelForm(inputRecord_4.Id, inputRecord_4.Name, inputRecord_4.Code, inputRecord_4.Access, inputRecord_4.SourceType, msg.fields[0])), model.ValidName, model.ValidCode, model.Processing, model.ErrorMsg, model.Channels);
        }
        case 5: {
            return new Model(model.Form, false, model.ValidCode, model.Processing, model.ErrorMsg, model.Channels);
        }
        case 6: {
            return new Model(model.Form, model.ValidName, false, model.Processing, model.ErrorMsg, model.Channels);
        }
        case 7: {
            return new Model(model.Form, model.ValidName, model.ValidCode, msg.fields[0], model.ErrorMsg, model.Channels);
        }
        case 8: {
            return new Model(model.Form, model.ValidName, model.ValidCode, model.Processing, msg.fields[0], model.Channels);
        }
        case 9: {
            return new Model(model.Form, model.ValidName, model.ValidCode, model.Processing, void 0, model.Channels);
        }
        default: {
            const x = msg.fields[0];
            return new Model((inputRecord = model.Form, new ChannelForm(inputRecord.Id, x, inputRecord.Code, inputRecord.Access, inputRecord.SourceType, inputRecord.ApiSettings)), x.trim().length > 0, model.ValidCode, model.Processing, model.ErrorMsg, model.Channels);
        }
    }
}

function drawSourceSelect(model, dispatch) {
    let elems_4, elems_2, elems_3, matchValue_1;
    let hasSharePointChannel;
    const matchValue = model.Channels;
    if (matchValue == null) {
        hasSharePointChannel = false;
    }
    else {
        const array = matchValue;
        hasSharePointChannel = array.some((x_1) => (x_1.SourceType === ChannelSource__ToInt(new ChannelSource(1))));
    }
    return createElement("div", createObj(ofArray([["style", {
        marginBottom: 30,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }], (elems_4 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
    }], (elems_2 = [toArray(map((s) => {
        const isSelected = model.Form.SourceType === ChannelSource__ToInt(s);
        return createElement("div", createObj(toList(delay(() => append(singleton(["style", createObj(toList(delay(() => append(singleton(["margin", 10]), delay(() => append(singleton(["padding", 5]), delay(() => append(singleton(["width", 100]), delay(() => append(singleton(["height", 100]), delay(() => append(singleton(["display", "flex"]), delay(() => append(singleton(["alignItems", "center"]), delay(() => append(singleton(["justifyContent", "center"]), delay(() => append((hasSharePointChannel && equals(s, new ChannelSource(1))) ? append(singleton(["backgroundColor", "#D3D3D3"]), delay(() => singleton(["cursor", "not-allowed"]))) : append(singleton(["backgroundColor", "#FFFFFF"]), delay(() => singleton(["cursor", "pointer"]))), delay(() => append(isSelected ? singleton(["border", (((3 + "px ") + "solid") + " ") + "rgb(255, 207, 1)"]) : singleton(["border", (((2 + "px ") + "solid") + " ") + "#ddd"]), delay(() => singleton(["borderRadius", 5]))))))))))))))))))))))]), delay(() => {
            let elems;
            return (hasSharePointChannel && equals(s, new ChannelSource(1))) ? singleton((elems = [MuiHelpers_createElement(Tooltip, [["title", "Maks antall kanaler til SharePoint allerede opprettet"], ["children", createElement("span", {
                children: toString(s),
            })]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])) : append(singleton(["onClick", (_arg) => {
                if (!isSelected) {
                    dispatch(new Message(3, ChannelSource__ToInt(s)));
                }
            }]), delay(() => {
                let elems_1;
                return singleton((elems_1 = [createElement("span", {
                    children: toString(s),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
            }));
        }))))));
    }, ofArray([new ChannelSource(0), new ChannelSource(1), new ChannelSource(2)])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["style", {
        maxWidth: 300,
        fontSize: 0.8 + "rem",
        textAlign: "center",
    }], (elems_3 = [createElement("span", {
        children: (matchValue_1 = (model.Form.SourceType | 0), (matchValue_1 === 1) ? "Standard Enterprise Mobile News kanal. Nyheter skrives direkte i dette web-grensesnittet" : ((matchValue_1 === 2) ? "Kanal henter automatisk nyheter fra SharePoint liste eller område. Krever at du gir Enterprise Mobile News tilgang." : ((matchValue_1 === 3) ? "Henter nyheter automatisk fra eksternt API. Krever at API svarer i korrekt format." : ""))),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function Form(formInputProps) {
    let elems_8, elements_2, elems_4, elems_6, elems_5;
    const onClose = formInputProps.onClose;
    const channels = formInputProps.channels;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(formInputProps.updating, channels));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const fieldHolder = (children) => createElement("div", {
        style: {
            width: 100 + "%",
            maxWidth: 250,
            marginBottom: 15,
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    }], (elems_8 = [snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(9));
    }), createElement("span", {
        style: {
            fontSize: 1.2 + "rem",
            marginBottom: 10,
        },
        children: "Legg til ny kanal",
    }), MuiHelpers_createElement(Paper, [["style", {
        width: 100 + "%",
        maxWidth: 650,
        padding: 20,
        display: "flex",
        flexDirection: "column",
    }], ["elevation", 1], (elements_2 = [createElement("span", {
        children: "Hvor vil du hente nyheter fra?",
    }), drawSourceSelect(model_1, dispatch), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        margin: ((20 + "px ") + 0) + "px",
    }], (elems_4 = toList(delay(() => {
        let elems_3, elements_1, elements;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }], (elems_3 = [createElement("span", {
            children: "Generell info",
        }), fieldHolder([MuiHelpers_createElement(TextField, [["defaultValue", model_1.Form.Name], ["onChange", (e) => {
            dispatch(new Message(0, Browser_Types_Event__Event_get_Value(e)));
        }], ["variant", "outlined"], ["label", "Kanalnavn"], ["error", !model_1.ValidName], ["fullWidth", true]])]), fieldHolder([MuiHelpers_createElement(TextField, [["defaultValue", model_1.Form.Code], ["onChange", (e_1) => {
            dispatch(new Message(1, Browser_Types_Event__Event_get_Value(e_1)));
        }], ["variant", "outlined"], ["label", "Kanalkode"], ["error", !model_1.ValidCode], ["fullWidth", true]])]), fieldHolder([MuiHelpers_createElement(FormControl, [["variant", "outlined"], ["fullWidth", true], (elements_1 = [MuiHelpers_createElement(Select, [["value", model_1.Form.Access], ["onChange", (e_2, _arg_4) => {
            dispatch(new Message(2, ChannelAccess__ToInt(ChannelAccess_FromInt_Z524259A4(Browser_Types_Event__Event_get_Value(e_2)))));
        }], (elements = [map_1((a_2) => MuiHelpers_createElement(MenuItem, [["value", ChannelAccess__ToInt(a_2)], ["children", toString(a_2)]]), [new ChannelAccess(0), new ChannelAccess(1)])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => ((model_1.Form.SourceType === 3) ? append(singleton(createElement(Settings, {
            updating: void 0,
            onSettings: (arg_13) => {
                dispatch(new Message(4, arg_13));
            },
        })), delay(() => ((model_1.Form.ApiSettings == null) ? singleton(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                maxWidth: 250,
                textAlign: "center",
                marginBottom: 5,
            },
            children: "Legg til og test API-innstillinger før du kan lagre",
        })) : empty()))) : empty())));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
    }], (elems_6 = [createElement("button", createObj(ofArray([["style", createObj(toList(delay(() => append(singleton(["border", "none"]), delay(() => append(singleton(["backgroundColor", "#363636"]), delay(() => append(singleton(["width", 150]), delay(() => append(singleton(["color", "#FFFFFF"]), delay(() => append(singleton(["padding", 10]), delay(() => append(singleton(["fontSize", 16 + "px"]), delay(() => append(singleton(["cursor", "pointer"]), delay(() => append(singleton(["borderRadius", 5]), delay(() => (((((!model_1.ValidName) ? true : (model_1.Form.Name.length === 0)) ? true : (!model_1.ValidCode)) ? true : (model_1.Form.Code.length === 0)) ? append(singleton(["opacity", 0.5]), delay(() => singleton(["cursor", "not-allowed"]))) : empty()))))))))))))))))))))], ["disabled", model_1.Processing], ["onClick", (_arg_5) => {
        const validName = model_1.Form.Name.trim().length > 0;
        if ((validName && (model_1.Form.Code.trim().length > 0)) && ((model_1.Form.SourceType === 3) ? (model_1.Form.ApiSettings != null) : true)) {
            const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let body, props, decoder, data_1, caseStrategy_3, extra_3;
                if ((model_1.Form.Name.trim().length > 0) && (model_1.Form.Code.trim().length > 0)) {
                    dispatch(new Message(7, true));
                    const requestPath = basePath + Channel_Add;
                    return ((body = Auto_generateBoxedEncoder_Z20B7B430(ChannelForm$reflection(), void 0, void 0, void 0)(model_1.Form), (props = props_17(), (decoder = Auto_generateBoxedDecoder_79988AEF(Channel$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                        let properties_2, headers_2;
                        try {
                            const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty_1()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_2((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                            const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                                let response_1, decoder_1_1;
                                return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Channel$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                    let matchValue;
                                    return Promise.resolve(equals_1(Channel$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                                })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                            }))));
                            return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                        }
                        catch (exn) {
                            return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                        }
                    })()))))))).then((_arg_2) => {
                        const res = _arg_2;
                        if (res.tag === 1) {
                            const err = res.fields[0];
                            checkFetchError(err);
                            dispatch(new Message(8, (err.tag === 2) ? (((err.fields[0].status) === 409) ? "Det eksisterer allerede en kanal med denne kanalkoden" : "Kunne ikke legge til kanal. Vennligst prøv igjen senere.") : "Kunne ikke legge til kanal. Vennligst prøv igjen senere."));
                            dispatch(new Message(7, false));
                            return Promise.resolve();
                        }
                        else {
                            onClose(res.fields[0]);
                            return Promise.resolve();
                        }
                    });
                }
                else {
                    return Promise.resolve();
                }
            }));
            pr_1.then();
        }
        else if (!validName) {
            dispatch(new Message(5));
        }
        else {
            dispatch(new Message(6));
        }
    }], (elems_5 = toList(delay(() => ((!model_1.Processing) ? singleton("Legg til") : singleton(loadingIndicatorSmall())))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

