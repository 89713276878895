import { Union, Record } from "../fable_modules/fable-library.3.7.22/Types.js";
import { ChannelAccess, ChannelAccess$reflection, Member$reflection, Channel$reflection } from "../Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, int32_type, string_type, option_type, array_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { checkFetchError } from "../Utils.js";
import { map as map_1, append, tryFind } from "../fable_modules/fable-library.3.7.22/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { props as props_22 } from "../Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "../fable_modules/fable-library.3.7.22/Util.js";
import { ChannelAccess_Revoke, ChannelAccess_Grant } from "../Shared/Urls.js";
import { createElement } from "react";
import * as react from "react";
import { singleton, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { Member_fetchAllMembers } from "../Promises.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import { loadingIndicatorSmall } from "../ViewHelpers.js";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowRight from "@material-ui/icons/ArrowRight";
import TableRow from "@material-ui/core/TableRow";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

class Model extends Record {
    constructor(Channel, HasAccess, AllMembers, Filter, UpdatedAccess, ErrorMsg) {
        super();
        this.Channel = Channel;
        this.HasAccess = HasAccess;
        this.AllMembers = AllMembers;
        this.Filter = Filter;
        this.UpdatedAccess = UpdatedAccess;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("ChannelAccess.Model", [], Model, () => [["Channel", Channel$reflection()], ["HasAccess", option_type(array_type(Member$reflection()))], ["AllMembers", option_type(array_type(Member$reflection()))], ["Filter", string_type], ["UpdatedAccess", option_type(int32_type)], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasAccessResponse", "MembersResponse", "UpdateFilter", "GrantResponse", "RevokeResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ChannelAccess.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Member$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Member$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Member$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Member$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ChannelAccess$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", ChannelAccess$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ChannelAccess$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", ChannelAccess$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(c) {
    return new Model(c, void 0, void 0, "", void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                checkFetchError(res_1.fields[0]);
                return new Model(model.Channel, model.HasAccess, model.AllMembers, model.Filter, model.UpdatedAccess, "Kunne ikke laste medlemmer. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.Channel, model.HasAccess, res_1.fields[0], model.Filter, model.UpdatedAccess, model.ErrorMsg);
            }
        }
        case 2: {
            return new Model(model.Channel, model.HasAccess, model.AllMembers, msg.fields[0], model.UpdatedAccess, model.ErrorMsg);
        }
        case 3: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                checkFetchError(res_2.fields[0]);
                return new Model(model.Channel, model.HasAccess, model.AllMembers, model.Filter, model.UpdatedAccess, "Kunne ikke gi medlem tilgang. Vennligst prøv igjen senere.");
            }
            else {
                const matchValue = model.AllMembers;
                if (matchValue != null) {
                    const _arg = tryFind((m) => (m.Id === res_2.fields[0].MemberId), matchValue);
                    if (_arg != null) {
                        const m_1 = _arg;
                        let a;
                        const matchValue_1 = model.HasAccess;
                        a = ((matchValue_1 != null) ? append([m_1], matchValue_1) : [m_1]);
                        return new Model(model.Channel, a, model.AllMembers, model.Filter, a.length, model.ErrorMsg);
                    }
                    else {
                        return model;
                    }
                }
                else {
                    return model;
                }
            }
        }
        case 4: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                checkFetchError(res_3.fields[0]);
                return new Model(model.Channel, model.HasAccess, model.AllMembers, model.Filter, model.UpdatedAccess, "Kunne ikke fjerne tilgang for medlem. Vennligst prøv igjen senere.");
            }
            else {
                let patternInput;
                const matchValue_2 = model.HasAccess;
                if (matchValue_2 != null) {
                    let ha_1;
                    const array_1 = matchValue_2;
                    ha_1 = array_1.filter((m_2) => (m_2.Id !== res_3.fields[0].MemberId));
                    patternInput = [ha_1, ha_1.length];
                }
                else {
                    patternInput = [void 0, model.UpdatedAccess];
                }
                return new Model(model.Channel, patternInput[0], model.AllMembers, model.Filter, patternInput[1], model.ErrorMsg);
            }
        }
        case 5: {
            return new Model(model.Channel, model.HasAccess, model.AllMembers, model.Filter, model.UpdatedAccess, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return new Model(model.Channel, model.HasAccess, model.AllMembers, model.Filter, model.UpdatedAccess, "Kunne ikke laste medlemmer. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.Channel, res.fields[0], model.AllMembers, model.Filter, model.UpdatedAccess, model.ErrorMsg);
            }
        }
    }
}

function fetchMembersWithAccess(channelId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = `/api/access/channel/${channelId}`;
        return ((props = props_22(), (decoder = Auto_generateBoxedDecoder_79988AEF(array_type(Member$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(Member$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(Member$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function grantAccess(channelId, memberId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, props, decoder, data_1, caseStrategy_3, extra_3;
        const req = new ChannelAccess(channelId, memberId);
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(ChannelAccess$reflection(), void 0, void 0, void 0)(req), (props = props_22(), (decoder = Auto_generateBoxedDecoder_79988AEF(ChannelAccess$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(ChannelAccess_Grant, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(ChannelAccess$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(ChannelAccess$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))))).then((_arg_2) => {
            dispatch(new Message(3, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function removeAccess(channelId, memberId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, props, decoder, data_1, caseStrategy_3, extra_3;
        const req = new ChannelAccess(channelId, memberId);
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(ChannelAccess$reflection(), void 0, void 0, void 0)(req), (props = props_22(), (decoder = Auto_generateBoxedDecoder_79988AEF(ChannelAccess$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(ChannelAccess_Revoke, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(ChannelAccess$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(ChannelAccess$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))))).then((_arg_2) => {
            dispatch(new Message(4, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function accessColumn(borderRight, children) {
    return createElement("div", {
        style: createObj(toList(delay(() => append_1(singleton(["display", "flex"]), delay(() => append_1(singleton(["flexDirection", "column"]), delay(() => append_1(singleton(["padding", 10]), delay(() => append_1(singleton(["flexGrow", 1]), delay(() => (borderRight ? singleton(["borderRight", (((1 + "px ") + "solid") + " ") + "#ddd"]) : singleton(["borderLeft", (((1 + "px ") + "solid") + " ") + "#ddd"]))))))))))))),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Access(accessInputProps) {
    let elems_10, elems, props, elems_9;
    const onClose = accessInputProps.onClose;
    const channel = accessInputProps.channel;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(channel));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchMembersWithAccess(channel.Id, dispatch);
        Member_fetchAllMembers((arg_1) => {
            dispatch(new Message(1, arg_1));
        });
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems_10 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "center",
    }], (elems = [MuiHelpers_createElement(IconButton, [["children", (props = {}, react.createElement(Close, props))], ["onClick", (_arg_1) => {
        onClose(model_1.UpdatedAccess);
    }]]), createElement("span", {
        style: {
            marginLeft: 10,
        },
        children: `Medlemmer med tilgang til kanalen ${channel.Name}`,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
    }], (elems_9 = toList(delay(() => {
        let elements_3;
        const matchValue = [model_1.AllMembers, model_1.HasAccess];
        let pattern_matching_result, access, all;
        if (matchValue[0] == null) {
            if (matchValue[1] == null) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 0;
            }
        }
        else if (matchValue[1] != null) {
            pattern_matching_result = 1;
            access = matchValue[1];
            all = matchValue[0];
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton(loadingIndicatorSmall());
            }
            case 1: {
                return append_1(singleton(accessColumn(true, [createElement("span", {
                    style: {
                        textAlign: "center",
                        marginBottom: 10,
                    },
                    children: "Medlemmer uten tilgang",
                }), MuiHelpers_createElement(Table, [["size", "small"], (elements_3 = [map_1((x) => {
                    let elements, elements_1, props_7;
                    const children_2 = ofArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], (elements = [createElement("span", {
                        style: {
                            fontSize: 0.8 + "rem",
                        },
                        children: `${x.FirstName} ${x.LastName} (${x.Phone})`,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["style", {
                        padding: 0,
                        width: 30,
                    }], (elements_1 = [MuiHelpers_createElement(Tooltip, [["title", "Gi tilgang"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", (props_7 = {}, react.createElement(ArrowRight, props_7))], ["onClick", (_arg_2) => {
                        grantAccess(model_1.Channel.Id, x.Id, dispatch);
                    }]])]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])]);
                    return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]);
                }, all.filter((m) => (!access.some((a) => (a.Id === m.Id)))))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])])])), delay(() => {
                    let elements_7;
                    return singleton(accessColumn(false, [createElement("span", {
                        style: {
                            textAlign: "center",
                            marginBottom: 10,
                        },
                        children: "Medlemmer med tilgang",
                    }), MuiHelpers_createElement(Table, [["size", "small"], (elements_7 = [map_1((x_1) => {
                        let elements_4, props_13, elements_5;
                        const children_4 = ofArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["style", {
                            padding: 0,
                            width: 30,
                        }], (elements_4 = [MuiHelpers_createElement(Tooltip, [["title", "Fjern tilgang"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", (props_13 = {}, react.createElement(ArrowLeft, props_13))], ["onClick", (_arg_3) => {
                            removeAccess(model_1.Channel.Id, x_1.Id, dispatch);
                        }]])]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], (elements_5 = [createElement("span", {
                            style: {
                                fontSize: 0.8 + "rem",
                            },
                            children: `${x_1.FirstName} ${x_1.LastName} (${x_1.Phone})`,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elements_5))])])]);
                        return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]);
                    }, access)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_7))])])]));
                }));
            }
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])));
}

