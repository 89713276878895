import { toString as toString_1, Union, Record } from "../fable_modules/fable-library.3.7.22/Types.js";
import { GenericMessage$reflection, Channel$reflection, News_NewsMeta$reflection } from "../Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, bool_type, union_type, record_type, string_type, option_type, array_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { parseAsUtc, toLocalTime, checkFetchError } from "../Utils.js";
import { sortByDescending, map, append } from "../fable_modules/fable-library.3.7.22/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { props as props_28 } from "../Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { compareArrays, createObj, uncurry } from "../fable_modules/fable-library.3.7.22/Util.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { empty as empty_1, append as append_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { snackError, loadingIndicator, loadingIndicatorSmall } from "../ViewHelpers.js";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { ChannelSource, ChannelSource__ToInt, ChannelSource_FromInt_Z524259A4 } from "../Shared/Shared.js";
import { createElement } from "react";
import * as react from "react";
import Add from "@material-ui/icons/Add";
import { Settings } from "./SharePointReactions.js";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import TableBody from "@material-ui/core/TableBody";
import { Channel_fetchChannels } from "../Promises.js";
import { Publish, Update } from "./Publish.js";
import Close from "@material-ui/icons/Close";

class Model extends Record {
    constructor(News, Channel, Channels, AddForm, EditForm, DeleteForm, ErrorMsg) {
        super();
        this.News = News;
        this.Channel = Channel;
        this.Channels = Channels;
        this.AddForm = AddForm;
        this.EditForm = EditForm;
        this.DeleteForm = DeleteForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("NewsFeed.Model", [], Model, () => [["News", option_type(array_type(News_NewsMeta$reflection()))], ["Channel", option_type(Channel$reflection())], ["Channels", option_type(array_type(Channel$reflection()))], ["AddForm", option_type(Channel$reflection())], ["EditForm", option_type(string_type)], ["DeleteForm", option_type(string_type)], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NewsResponse", "ChannelsResponse", "ToggleAdd", "NewsAdded", "ToggleEdit", "NewsUpdated", "ToggleDelete", "NewsDeleted", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("NewsFeed.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(News_NewsMeta$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(News_NewsMeta$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Channel$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Channel$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], [["Item", News_NewsMeta$reflection()]], [["Item", option_type(string_type)]], [["Item", News_NewsMeta$reflection()]], [["Item", option_type(string_type)]], [["Item", string_type]], []]);
}

function init(x) {
    return new Model(void 0, x, void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1, array_1;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                checkFetchError(res_1.fields[0]);
                return new Model(model.News, model.Channel, model.Channels, model.AddForm, model.EditForm, model.DeleteForm, "Kunne ikke laste kanaler. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.News, model.Channel, res_1.fields[0], model.AddForm, model.EditForm, model.DeleteForm, model.ErrorMsg);
            }
        }
        case 2: {
            return new Model(model.News, model.Channel, model.Channels, (!msg.fields[0]) ? (void 0) : model.Channel, model.EditForm, model.DeleteForm, model.ErrorMsg);
        }
        case 3: {
            const x_3 = msg.fields[0];
            return new Model((matchValue = model.News, (matchValue != null) ? append([x_3], matchValue) : [x_3]), model.Channel, model.Channels, void 0, model.EditForm, model.DeleteForm, model.ErrorMsg);
        }
        case 4: {
            return new Model(model.News, model.Channel, model.Channels, model.AddForm, msg.fields[0], model.DeleteForm, model.ErrorMsg);
        }
        case 5: {
            const x_5 = msg.fields[0];
            return new Model((matchValue_1 = model.News, (matchValue_1 != null) ? map((z_1) => {
                if (z_1.Id === x_5.Id) {
                    return x_5;
                }
                else {
                    return z_1;
                }
            }, matchValue_1) : [x_5]), model.Channel, model.Channels, model.AddForm, void 0, model.DeleteForm, model.ErrorMsg);
        }
        case 6: {
            return new Model(model.News, model.Channel, model.Channels, model.AddForm, model.EditForm, msg.fields[0], model.ErrorMsg);
        }
        case 7: {
            const matchValue_2 = model.News;
            if (matchValue_2 != null) {
                return new Model((array_1 = matchValue_2, array_1.filter((a) => (a.Id !== msg.fields[0]))), model.Channel, model.Channels, model.AddForm, model.EditForm, void 0, model.ErrorMsg);
            }
            else {
                return new Model(model.News, model.Channel, model.Channels, model.AddForm, model.EditForm, void 0, model.ErrorMsg);
            }
        }
        case 8: {
            return new Model(model.News, model.Channel, model.Channels, model.AddForm, model.EditForm, model.DeleteForm, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return new Model(model.News, model.Channel, model.Channels, model.AddForm, model.EditForm, model.DeleteForm, "Kunne ikke laste nyheter. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(res.fields[0], model.Channel, model.Channels, model.AddForm, model.EditForm, model.DeleteForm, model.ErrorMsg);
            }
        }
    }
}

function fetchNews(channelId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = `/api/news/feed/${channelId}`;
        return ((props = props_28(), (decoder = Auto_generateBoxedDecoder_79988AEF(array_type(News_NewsMeta$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(News_NewsMeta$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(News_NewsMeta$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function DeleteForm(deleteFormInputProps) {
    let elements_2, children, children_1;
    const onClose = deleteFormInputProps.onClose;
    const newsId = deleteFormInputProps.newsId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput[1];
    const setErrMsg = useFeliz_React__React_useState_Static_1505(void 0)[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_4, v) => {
        onClose(false);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Slett nyhet"]]), (children = singleton(MuiHelpers_createElement(DialogContentText, [["children", "Er du sikker på at du ønsker å slette denne nyheten?"]])), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (patternInput[0] ? singleton_1(loadingIndicatorSmall()) : append_1(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Avbryt"], ["onClick", (_arg_5) => {
        onClose(false);
    }]])), delay(() => singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Slett"], ["onClick", (_arg_6) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let props, decoder, data_1, caseStrategy_2, extra_2;
            setProcessing(true);
            const requestPath = `/api/news/${newsId}`;
            return ((props = props_28(), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 1) {
                    checkFetchError(res.fields[0]);
                    setErrMsg("Kunne ikke slette nyhet. Vennligst prøv igjen senere.");
                    return Promise.resolve();
                }
                else {
                    const x_1 = res.fields[0];
                    if (x_1.result === "success") {
                        onClose(true);
                        return Promise.resolve();
                    }
                    else {
                        setProcessing(false);
                        setErrMsg(x_1.message);
                        return Promise.resolve();
                    }
                }
            });
        }));
        pr_1.then();
    }]]))))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function drawNews(model, dispatch, channelId, source) {
    let elems_8;
    const src = ChannelSource_FromInt_Z524259A4(source);
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }], (elems_8 = toList(delay(() => {
        let elems;
        const matchValue = model.News;
        if (matchValue != null) {
            const n = matchValue;
            return append_1(singleton_1(createElement("div", createObj(ofArray([["style", {
                width: 100 + "%",
                margin: ((10 + "px ") + 0) + "px",
            }], (elems = toList(delay(() => {
                let props;
                return (source === ChannelSource__ToInt(new ChannelSource(0))) ? singleton_1(MuiHelpers_createElement(Button, [["startIcon", (props = {}, react.createElement(Add, props))], ["children", "Ny nyhet"], ["onClick", (_arg) => {
                    dispatch(new Message(2, true));
                }]])) : singleton_1(createElement("span", {
                    children: `Denne kanalen henter nyheter fra ${toString_1(src)}. Nye nyheter publiseres der.`,
                }));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append_1((source === ChannelSource__ToInt(new ChannelSource(1))) ? singleton_1(createElement(Settings, {
                channelId: channelId,
            })) : empty_1(), delay(() => {
                let elements_4, children_2, children_1, children_5;
                return (n.length === 0) ? singleton_1(createElement("span", {
                    style: {
                        fontStyle: "italic",
                        marginTop: 20,
                    },
                    children: "Ingen nyheter publisert i denne kanalen enda.",
                })) : singleton_1(MuiHelpers_createElement(Table, [["size", "small"], (elements_4 = [(children_2 = singleton((children_1 = ofArray([MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["style", {
                    width: 20,
                }]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", "Tittel"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", "Opprettet"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", "Skrevet av"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", "Publiser til"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", "Fra"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", "Til"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"]])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_5 = singleton(map((y_2) => {
                    let elems_3, elems_4, matchValue_3, props_23;
                    let after;
                    const matchValue_1 = y_2.PublishAfter;
                    after = ((matchValue_1 != null) ? toLocalTime(matchValue_1) : "");
                    let until;
                    const matchValue_2 = y_2.PublishedUntil;
                    until = ((matchValue_2 != null) ? toLocalTime(matchValue_2) : "");
                    const children_4 = ofArray([MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["style", {
                        width: 20,
                    }], (elems_3 = toList(delay(() => (y_2.Pinned ? singleton_1(MuiHelpers_createElement(Tooltip, [["title", "Festet til topp"], ["children", createElement("span", {
                        className: "fas fa-thumbtack",
                    })]])) : empty_1()))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], (elems_4 = toList(delay(() => ((source === ChannelSource__ToInt(new ChannelSource(0))) ? singleton_1(createElement("a", {
                        style: {
                            textDecoration: "underline",
                            cursor: "pointer",
                        },
                        onClick: (_arg_1) => {
                            dispatch(new Message(4, y_2.Id));
                        },
                        children: y_2.Title,
                    })) : singleton_1(y_2.Title)))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", toLocalTime(y_2.Created)]]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", y_2.Author]]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", (matchValue_3 = (y_2.PublishedTo | 0), (matchValue_3 === 2) ? "Kun app" : ((matchValue_3 === 3) ? "Kun infoskjerm" : "App og infoskjerm"))]]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", after]]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", until]]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", (props_23 = {}, react.createElement(Delete, props_23))], ["onClick", (_arg_2) => {
                        dispatch(new Message(6, y_2.Id));
                    }]])]])]);
                    return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]);
                }, sortByDescending((y) => [y.Pinned, parseAsUtc(y.Created)], n, {
                    Compare: compareArrays,
                }))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])]));
            }))));
        }
        else {
            return singleton_1(loadingIndicator());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

export function Feed(feedInputProps) {
    let elems_1;
    const onClose = feedInputProps.onClose;
    const channel = feedInputProps.channel;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(channel));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        Channel_fetchChannels((arg_1) => {
            dispatch(new Message(1, arg_1));
        });
        if (channel != null) {
            fetchNews(channel.Id, dispatch);
        }
    });
    const matchValue = model_1.AddForm;
    if (matchValue == null) {
        const matchValue_1 = [model_1.EditForm, model_1.Channel];
        let pattern_matching_result, c_1, ef;
        if (matchValue_1[0] != null) {
            if (matchValue_1[1] != null) {
                pattern_matching_result = 0;
                c_1 = matchValue_1[1];
                ef = matchValue_1[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return createElement(Update, {
                    newsId: ef,
                    channel: c_1,
                    onClose: (updatedEntry) => {
                        if (updatedEntry != null) {
                            dispatch(new Message(5, updatedEntry));
                        }
                        else {
                            dispatch(new Message(4, void 0));
                        }
                    },
                });
            }
            case 1: {
                return createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    flexDirection: "column",
                }], (elems_1 = toList(delay(() => {
                    let matchValue_2, newsId;
                    return append_1((matchValue_2 = model_1.DeleteForm, (matchValue_2 != null) ? ((newsId = matchValue_2, singleton_1(createElement(DeleteForm, {
                        newsId: newsId,
                        onClose: (didDelete) => {
                            if (didDelete) {
                                dispatch(new Message(7, newsId));
                            }
                            else {
                                dispatch(new Message(6, void 0));
                            }
                        },
                    })))) : ((empty_1()))), delay(() => append_1(singleton_1(snackError(model_1.ErrorMsg, () => {
                        dispatch(new Message(8));
                    })), delay(() => {
                        let elems;
                        return append_1(singleton_1(createElement("div", createObj(ofArray([["style", {
                            display: "flex",
                            alignItems: "center",
                        }], (elems = toList(delay(() => {
                            let props;
                            return append_1(singleton_1(MuiHelpers_createElement(Tooltip, [["title", "Lukk"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", (props = {}, react.createElement(Close, props))], ["onClick", (_arg_2) => {
                                onClose();
                            }], ["style", {
                                marginRight: 10,
                            }]])]])), delay(() => {
                                const matchValue_3 = model_1.Channel;
                                if (matchValue_3 != null) {
                                    const c_2 = matchValue_3;
                                    return singleton_1(createElement("span", {
                                        style: {
                                            fontSize: 1.5 + "rem",
                                        },
                                        children: `Nyheter publisert i ${c_2.Name}`,
                                    }));
                                }
                                else {
                                    return singleton_1(createElement("span", {
                                        style: {},
                                        children: "Ingen kanal valgt",
                                    }));
                                }
                            }));
                        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                            const matchValue_4 = model_1.Channel;
                            if (matchValue_4 != null) {
                                const c_3 = matchValue_4;
                                return singleton_1(drawNews(model_1, dispatch, c_3.Id, c_3.SourceType));
                            }
                            else {
                                return empty_1();
                            }
                        }));
                    }))));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
            }
        }
    }
    else {
        const channel_1 = matchValue;
        return createElement(Publish, {
            updating: void 0,
            channel: channel_1,
            onClose: (newEntry) => {
                if (newEntry != null) {
                    dispatch(new Message(3, newEntry));
                }
                else {
                    dispatch(new Message(2, false));
                }
            },
        });
    }
}

