import { Record, Union } from "../fable_modules/fable-library.3.7.22/Types.js";
import { unit_type, equals, obj_type, record_type, array_type, tuple_type, int32_type, class_type, option_type, union_type, string_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { GenericMessage$reflection, Statistics_NewsReactions$reflection, Member$reflection, NewsStat$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { toLocalDate, checkFetchError } from "../Utils.js";
import { mapIndexed, tryFind, map } from "../fable_modules/fable-library.3.7.22/Array.js";
import { toString as toString_1, date, equals as equals_1, addDays, today, op_Subtraction, parse } from "../fable_modules/fable-library.3.7.22/Date.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { format, toBase64String, printf, toText } from "../fable_modules/fable-library.3.7.22/String.js";
import { basePath } from "../Pages.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { props as props_28 } from "../Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { map as map_1, defaultArg, some } from "../fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { toArray, map as map_2, singleton, cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { equals as equals_2, int32ToString, createObj, uncurry } from "../fable_modules/fable-library.3.7.22/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { BarChart, Tooltip, YAxis, XAxis, Bar, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { totalDays } from "../fable_modules/fable-library.3.7.22/TimeSpan.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { rangeDouble } from "../fable_modules/fable-library.3.7.22/Range.js";
import { createElement } from "react";
import * as react from "react";
import { loadingIndicatorSmall, loadingIndicator } from "../ViewHelpers.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { tryLoadSession } from "../LocalStorage.js";
import { get_UTF8 } from "../fable_modules/fable-library.3.7.22/Encoding.js";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import { toColor, toIcon } from "../News/Reactions.js";
import Badge from "@material-ui/core/Badge";
import Tooltip_1 from "@material-ui/core/Tooltip";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

class AlertStep extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotInitiated", "GetConfirmation", "Sending", "IsSent", "CouldNotSend"];
    }
}

function AlertStep$reflection() {
    return union_type("NewsReport.AlertStep", [], AlertStep, () => [[], [], [], [], [["Item", string_type]]]);
}

class Model extends Record {
    constructor(News, ErrorMsg, PushStep, SMSStep, Distribution, NotRead, Reactions) {
        super();
        this.News = News;
        this.ErrorMsg = ErrorMsg;
        this.PushStep = PushStep;
        this.SMSStep = SMSStep;
        this.Distribution = Distribution;
        this.NotRead = NotRead;
        this.Reactions = Reactions;
    }
}

function Model$reflection() {
    return record_type("NewsReport.Model", [], Model, () => [["News", NewsStat$reflection()], ["ErrorMsg", option_type(string_type)], ["PushStep", AlertStep$reflection()], ["SMSStep", AlertStep$reflection()], ["Distribution", option_type(array_type(tuple_type(class_type("System.DateTime"), int32_type)))], ["NotRead", option_type(array_type(Member$reflection()))], ["Reactions", option_type(array_type(Statistics_NewsReactions$reflection()))]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PushResponse", "SMSResponse", "SetPushStep", "SetSMSStep", "DistributionResponse", "NotReadResponse", "ReactionResponse"];
    }
}

function Message$reflection() {
    return union_type("NewsReport.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", AlertStep$reflection()]], [["Item", AlertStep$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(tuple_type(string_type, int32_type)), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(tuple_type(string_type, int32_type))]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Member$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Member$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Statistics_NewsReactions$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Statistics_NewsReactions$reflection())]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

function init(x) {
    return new Model(x, void 0, new AlertStep(0), new AlertStep(0), void 0, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                checkFetchError(res_1.fields[0]);
                return new Model(model.News, model.ErrorMsg, model.PushStep, new AlertStep(4, "Noe gikk galt. Kunne ikke sende SMS påminnelse."), model.Distribution, model.NotRead, model.Reactions);
            }
            else {
                const x_1 = res_1.fields[0];
                if (x_1.result === "success") {
                    return new Model(model.News, model.ErrorMsg, model.PushStep, new AlertStep(3), model.Distribution, model.NotRead, model.Reactions);
                }
                else {
                    return new Model(model.News, model.ErrorMsg, model.PushStep, new AlertStep(4, x_1.message), model.Distribution, model.NotRead, model.Reactions);
                }
            }
        }
        case 2: {
            return new Model(model.News, model.ErrorMsg, msg.fields[0], model.SMSStep, model.Distribution, model.NotRead, model.Reactions);
        }
        case 3: {
            return new Model(model.News, model.ErrorMsg, model.PushStep, msg.fields[0], model.Distribution, model.NotRead, model.Reactions);
        }
        case 4: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                checkFetchError(res_2.fields[0]);
                return new Model(model.News, "Noe gikk galt. Kunne ikke laste lesehistorikk.", model.PushStep, model.SMSStep, model.Distribution, model.NotRead, model.Reactions);
            }
            else {
                return new Model(model.News, model.ErrorMsg, model.PushStep, model.SMSStep, map((tupledArg) => [parse(tupledArg[0], null, 128), tupledArg[1]], res_2.fields[0]), model.NotRead, model.Reactions);
            }
        }
        case 5: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                checkFetchError(res_3.fields[0]);
                return new Model(model.News, "Noe gikk galt. Kunne ikke laste brukere som ikke har lest", model.PushStep, model.SMSStep, model.Distribution, model.NotRead, model.Reactions);
            }
            else {
                return new Model(model.News, model.ErrorMsg, model.PushStep, model.SMSStep, model.Distribution, res_3.fields[0], model.Reactions);
            }
        }
        case 6: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                checkFetchError(res_4.fields[0]);
                return new Model(model.News, "Noe gikk galt. Kunne ikke laste reaksjoner.", model.PushStep, model.SMSStep, model.Distribution, model.NotRead, model.Reactions);
            }
            else {
                return new Model(model.News, model.ErrorMsg, model.PushStep, model.SMSStep, model.Distribution, model.NotRead, res_4.fields[0]);
            }
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return new Model(model.News, model.ErrorMsg, new AlertStep(4, "Noe gikk galt. Kunne ikke sende push påminnelse."), model.SMSStep, model.Distribution, model.NotRead, model.Reactions);
            }
            else {
                const x = res.fields[0];
                if (x.result === "success") {
                    return new Model(model.News, model.ErrorMsg, new AlertStep(3), model.SMSStep, model.Distribution, model.NotRead, model.Reactions);
                }
                else {
                    return new Model(model.News, model.ErrorMsg, new AlertStep(4, x.message), model.SMSStep, model.Distribution, model.NotRead, model.Reactions);
                }
            }
        }
    }
}

function sendPush(newsId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, props, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(2, new AlertStep(2)));
        const requestPath = toText(printf("%s/api/statistics/alert/push/%s"))(basePath)(newsId);
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(string_type, void 0, void 0, void 0)(""), (props = props_28(), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_5) => (new FSharpResult$2(1, new FetchError(3, arg_5)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function sendSMS(newsId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, props, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(3, new AlertStep(2)));
        const requestPath = toText(printf("%s/api/statistics/alert/sms/%s"))(basePath)(newsId);
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(string_type, void 0, void 0, void 0)(""), (props = props_28(), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_5) => (new FSharpResult$2(1, new FetchError(3, arg_5)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))))).then((_arg_2) => {
            dispatch(new Message(1, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchDistribution(newsId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = toText(printf("%s/api/statistics/distribution/%s"))(basePath)(newsId);
        return ((props = props_28(), (decoder = Auto_generateBoxedDecoder_79988AEF(array_type(tuple_type(string_type, int32_type)), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(tuple_type(string_type, int32_type)), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(tuple_type(string_type, int32_type)), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_5) => (new FSharpResult$2(1, new FetchError(3, arg_5)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(4, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchNotRead(newsId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = toText(printf("%s/api/statistics/notread/%s"))(basePath)(newsId);
        return ((props = props_28(), (decoder = Auto_generateBoxedDecoder_79988AEF(array_type(Member$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(Member$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(Member$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_5) => (new FSharpResult$2(1, new FetchError(3, arg_5)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(5, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchReactions(newsId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = toText(printf("%s/api/statistics/reactions/%s"))(basePath)(newsId);
        return ((props = props_28(), (decoder = Auto_generateBoxedDecoder_79988AEF(array_type(Statistics_NewsReactions$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(Statistics_NewsReactions$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(Statistics_NewsReactions$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_5) => (new FSharpResult$2(1, new FetchError(3, arg_5)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(6, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

class PieData extends Record {
    constructor(name, vl) {
        super();
        this.name = name;
        this.vl = (vl | 0);
    }
}

function PieData$reflection() {
    return record_type("NewsReport.PieData", [], PieData, () => [["name", string_type], ["vl", int32_type]]);
}

function percentagePieChart(data, color) {
    let properties_3, elements_1, properties_2, elements;
    const properties_4 = singleton(["children", (properties_3 = ofArray([["width", 250], ["height", 250], (elements_1 = singleton((properties_2 = ofArray([["dataKey", "vl"], ["fill", "#209cee"], ["label", true], ["cx", 50 + "%"], ["cy", 50 + "%"], ["data", data], ["outerRadius", 90 + "%"], ["innerRadius", 50 + "%"], (elements = ofArray([Interop_reactApi.createElement(Cell, {
        key: "1",
        fill: color,
        stroke: "#363636",
    }), Interop_reactApi.createElement(Cell, {
        key: "2",
        fill: "#f6f6f6",
        stroke: "#363636",
    })]), ["children", ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))]])]), Interop_reactApi.createElement(Pie, createObj(properties_2)))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements_1))])]), Interop_reactApi.createElement(PieChart, createObj(properties_3)))]);
    return Interop_reactApi.createElement(ResponsiveContainer, createObj(properties_4));
}

class DistributionData extends Record {
    constructor(name, lest, dte) {
        super();
        this.name = name;
        this.lest = (lest | 0);
        this.dte = dte;
    }
}

function DistributionData$reflection() {
    return record_type("NewsReport.DistributionData", [], DistributionData, () => [["name", string_type], ["lest", int32_type], ["dte", class_type("System.DateTime")]]);
}

function drawReadDistribution(model) {
    let copyOfStruct, elems_1, properties_6, properties_5, elements;
    const matchValue = model.Distribution;
    if (matchValue != null) {
        const x = matchValue;
        const startDate = parse(model.News.Published);
        const daysApart = ((copyOfStruct = op_Subtraction(today(), startDate), totalDays(copyOfStruct))) + 1;
        const data = map_2((arg_2) => {
            const y = addDays(startDate, arg_2);
            const matchValue_2 = tryFind((tupledArg) => equals_1(date(tupledArg[0]), date(y)), x);
            if (matchValue_2 == null) {
                return new DistributionData(toString_1(y, "dd/MM"), 0, y);
            }
            else {
                const entryDate = matchValue_2[0];
                const count = matchValue_2[1] | 0;
                return new DistributionData(toString_1(entryDate, "dd/MM"), count, entryDate);
            }
        }, toList(rangeDouble(0, 1, (daysApart > 31) ? 31 : (~(~daysApart)))));
        return createElement("div", createObj(ofArray([["style", {
            height: 350 + "px",
            marginBottom: 20,
        }], (elems_1 = [(properties_6 = singleton(["children", (properties_5 = ofArray([["margin", {
            top: defaultArg(5, 0),
            right: defaultArg(20, 0),
            left: defaultArg(5, 0),
            bottom: defaultArg(0, 0),
        }], ["data", toArray(data)], (elements = ofArray([Interop_reactApi.createElement(Bar, {
            dataKey: "lest",
            fill: "rgb(255, 207, 1)",
        }), Interop_reactApi.createElement(XAxis, {
            dataKey: "name",
        }), Interop_reactApi.createElement(YAxis, {}), Interop_reactApi.createElement(Tooltip, {})]), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))])]), Interop_reactApi.createElement(BarChart, createObj(properties_5)))]), Interop_reactApi.createElement(ResponsiveContainer, createObj(properties_6)))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])));
    }
    else {
        return loadingIndicator();
    }
}

function entryInfoTable(x) {
    let elements_2, children_1;
    const rowEntry = (title, v) => {
        const children = ofArray([MuiHelpers_createElement(TableCell, [["variant", "head"], ["size", "small"], ["children", title]]), MuiHelpers_createElement(TableCell, [["variant", "body"], ["size", "small"], ["children", v]])]);
        return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi_1.Children.toArray(Array.from(children))]]);
    };
    return MuiHelpers_createElement(Table, [["size", "small"], (elements_2 = [(children_1 = toList(delay(() => append(singleton_1(rowEntry("Kanal", x.ChannelName)), delay(() => append(singleton_1(rowEntry("Publisert", toLocalDate(x.Published))), delay(() => append(singleton_1(rowEntry("Antall lest", `${x.NumReads} av ${x.NumMembersWithAccess}`)), delay(() => (x.RequiresConfirmation ? singleton_1(rowEntry("Antall bekretet lest", `${x.Confirmed} av ${x.NumMembersWithAccess}`)) : empty_1()))))))))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi_1.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elements_2))])]);
}

function notReadTable(model) {
    let elems_2, elems, elems_1;
    let token;
    let token$0027;
    const matchValue = tryLoadSession();
    token$0027 = ((matchValue != null) ? matchValue.Token : "");
    token = toBase64String(get_UTF8().getBytes(token$0027));
    return createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
    }], (elems_2 = [createElement("span", {
        style: {
            fontSize: 1.2 + "rem",
            fontWeight: 600,
        },
        children: "Medlemmer som ikke har lest denne nyheten",
    }), createElement("br", {}), MuiHelpers_createElement(Button, [["fullWidth", false], ["href", `${basePath}/api/statistics/download/notread/${model.News.Id}/${token}`], ["variant", "text"], (elems = [createElement("span", {
        style: {
            textTransform: "none",
            color: "rgba(0,0,0,0.87)",
        },
        children: "Last ned komplett liste",
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]), createElement("div", {
        style: {
            backgroundColor: "#333333",
            height: 2 + "px",
            width: 100 + "%",
        },
    }), createElement("br", {}), createElement("div", createObj(ofArray([["style", {
        display: "grid",
        ["grid-gap"]: 5,
        ["grid-template-columns"]: "auto auto auto",
        ["grid-template-rows"]: "25px 25px 25px 25px 25px 25px 25px 25px 25px 25px",
        fontSize: 0.8 + "rem",
    }], (elems_1 = toList(delay(() => {
        const matchValue_1 = model.NotRead;
        if (matchValue_1 != null) {
            const nrl = matchValue_1;
            const maxMembersToDisplay = 29;
            const membersList = (nrl.length >= maxMembersToDisplay) ? nrl.slice(void 0, (maxMembersToDisplay - 1) + 1) : nrl;
            const numMembersNotDisplayed = (nrl.length - maxMembersToDisplay) | 0;
            return append(singleton_1(mapIndexed((i, elem) => {
                let arg, arg_3;
                const column = ((~(~(i / 10))) + 1) | 0;
                const row = ((i % 10) + 1) | 0;
                return createElement("span", {
                    style: {
                        ["grid-column"]: column,
                        ["grid-row"]: row,
                    },
                    children: (arg = ((elem.FirstName.length > 1) ? ((arg_3 = elem.FirstName[0], toText(printf("%c."))(arg_3))) : elem.FirstName), toText(printf("%s %s (%s)"))(arg)(elem.LastName)(elem.Phone)),
                });
            }, membersList)), delay(() => ((numMembersNotDisplayed > 0) ? singleton_1(createElement("span", {
                style: {
                    ["grid-row"]: 10,
                    ["grid-column"]: 3,
                },
                children: toText(printf("+ %i medlemmer"))(numMembersNotDisplayed),
            })) : empty_1())));
        }
        else {
            return singleton_1(loadingIndicator());
        }
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])));
}

function ReactionIcons(model) {
    let elems_3;
    const icons = new Int32Array([3, 4, 1, 2]);
    return createElement("div", createObj(singleton((elems_3 = toList(delay(() => {
        let elems_2;
        const matchValue = model.Reactions;
        if (matchValue != null) {
            const reactions = matchValue;
            return singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexDirection: "column",
                gap: 5 + "px ",
            }], (elems_2 = map((x) => {
                let elems_1, elems;
                const isPermitted = tryFind((y) => (y.Reaction === x), reactions);
                return createElement("div", createObj(singleton((elems_1 = [MuiHelpers_createElement(Chip, [["style", {
                    marginRight: 5,
                    display: "flex",
                    flexGrow: 1,
                    backgroundColor: "#FFFFFF",
                }], ["variant", "outlined"], ["avatar", MuiHelpers_createElement(Icon, [["className", toIcon(x)], ["style", createObj(toList(delay(() => {
                    let matchValue_1;
                    return append((matchValue_1 = isPermitted, (matchValue_1 != null) ? singleton_1(["color", toColor(matchValue_1.Reaction)]) : singleton_1(["color", "#696969"])), delay(() => append(singleton_1(["display", "flex"]), delay(() => append(singleton_1(["justifyContent", "center"]), delay(() => append(singleton_1(["alignItems", "center"]), delay(() => singleton_1(["fontSize", 14 + "px"])))))))));
                })))]])], ["label", createElement("div", createObj(singleton((elems = toList(delay(() => ((isPermitted != null) ? singleton_1(MuiHelpers_createElement(Badge, [["style", {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fefefe",
                    width: 20,
                    height: 20,
                    borderRadius: 50 + "%",
                    backgroundColor: "#bdbdbd",
                }], ["children", int32ToString(isPermitted.Count)]])) : singleton_1(MuiHelpers_createElement(Tooltip_1, [["title", "Inaktiv reaksjon"], ["children", createElement("i", {
                    className: "fas fa-question-circle",
                })]]))))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))]))))]])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))]))));
            }, icons), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])]))));
        }
        else {
            return singleton_1(loadingIndicator());
        }
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))]))));
}

function notifyView(model, dispatch) {
    let elems_4;
    return createElement("div", createObj(ofArray([["style", {
        fontSize: 0.8 + "rem",
        maxWidth: 400,
        width: 100 + "%",
        textAlign: "center",
    }], (elems_4 = toList(delay(() => append(singleton_1(createElement("span", {
        style: {
            fontWeight: "bold",
            marginBottom: 10,
            display: "block",
        },
        children: "Varsle medlemmer som ikke har lest",
    })), delay(() => {
        let elems_1;
        return append(equals_2(model.SMSStep, new AlertStep(0)) ? singleton_1(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
            alignItems: "center",
        }], (elems_1 = toList(delay(() => {
            let value_27;
            const matchValue = model.PushStep;
            switch (matchValue.tag) {
                case 1: {
                    return append(singleton_1((value_27 = (`Bekreft at du vil sende push-varsling til ${(model.News.NumMembersWithAccess - model.News.NumReads)} medlemmer`), createElement("span", {
                        children: [value_27],
                    }))), delay(() => {
                        let elems;
                        return singleton_1(createElement("div", createObj(ofArray([["style", {
                            display: "flex",
                            alignItems: "center",
                            width: 100 + "%",
                            justifyContent: "center",
                        }], (elems = [MuiHelpers_createElement(Button, [["size", "small"], ["children", "Bekreft"], ["onClick", (_arg_1) => {
                            sendPush(model.News.Id, dispatch);
                        }]]), MuiHelpers_createElement(Button, [["size", "small"], ["children", "Avbryt"], ["onClick", (_arg_2) => {
                            dispatch(new Message(2, new AlertStep(0)));
                        }]])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))));
                    }));
                }
                case 2: {
                    return singleton_1(loadingIndicatorSmall());
                }
                case 3: {
                    return append(singleton_1(createElement("br", {})), delay(() => {
                        let children;
                        return singleton_1((children = singleton(createElement("b", {
                            children: ["Push-varsling vil bli sendt!"],
                        })), createElement("span", {
                            children: Interop_reactApi_1.Children.toArray(Array.from(children)),
                        })));
                    }));
                }
                case 4: {
                    return append(singleton_1(createElement("br", {})), delay(() => {
                        let children_2, value_45;
                        return singleton_1((children_2 = singleton((value_45 = toText(printf("Kunne ikke sende push-varsling: %s"))(matchValue.fields[0]), createElement("b", {
                            children: [value_45],
                        }))), createElement("span", {
                            children: Interop_reactApi_1.Children.toArray(Array.from(children_2)),
                        })));
                    }));
                }
                default: {
                    return append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Send push"], ["onClick", (_arg) => {
                        dispatch(new Message(2, new AlertStep(1)));
                    }]])), delay(() => singleton_1(createElement("span", {
                        children: ["Merk: Mottaker kan ha slått av push varslinger"],
                    }))));
                }
            }
        })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])))) : empty_1(), delay(() => {
            let elems_3;
            return equals_2(model.PushStep, new AlertStep(0)) ? singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexDirection: "column",
                marginBottom: 10,
                alignItems: "center",
            }], (elems_3 = toList(delay(() => {
                const matchValue_1 = model.SMSStep;
                switch (matchValue_1.tag) {
                    case 1: {
                        const numMembers_1 = (model.News.NumMembersWithAccess - model.News.NumReads) | 0;
                        let amount;
                        const matchValue_3 = 1 * numMembers_1;
                        amount = ((matchValue_3 > 0) ? matchValue_3 : 0);
                        return append(singleton_1(createElement("span", {
                            style: {
                                fontSize: 13 + "px",
                            },
                            children: `Bekreft at du vil sende SMS-varsling til ${numMembers_1} medlemmer for totalt ${amount}kr.`,
                        })), delay(() => {
                            let elems_2;
                            return singleton_1(createElement("div", createObj(ofArray([["style", {
                                display: "flex",
                                alignItems: "center",
                                width: 100 + "%",
                                justifyContent: "center",
                            }], (elems_2 = [MuiHelpers_createElement(Button, [["size", "small"], ["children", "Bekreft"], ["onClick", (_arg_4) => {
                                sendSMS(model.News.Id, dispatch);
                            }]]), MuiHelpers_createElement(Button, [["size", "small"], ["children", "Avbryt"], ["onClick", (_arg_5) => {
                                dispatch(new Message(3, new AlertStep(0)));
                            }]])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])]))));
                        }));
                    }
                    case 2: {
                        return singleton_1(loadingIndicatorSmall());
                    }
                    case 3: {
                        return append(singleton_1(createElement("br", {})), delay(() => {
                            let children_4;
                            return singleton_1((children_4 = singleton(createElement("b", {
                                children: ["SMS-varsling vil bli sendt!"],
                            })), createElement("span", {
                                children: Interop_reactApi_1.Children.toArray(Array.from(children_4)),
                            })));
                        }));
                    }
                    case 4: {
                        return append(singleton_1(createElement("br", {})), delay(() => {
                            let children_6, value_80;
                            return singleton_1((children_6 = singleton((value_80 = toText(printf("Kunne ikke sende SMS-varsling: %s"))(matchValue_1.fields[0]), createElement("b", {
                                children: [value_80],
                            }))), createElement("span", {
                                children: Interop_reactApi_1.Children.toArray(Array.from(children_6)),
                            })));
                        }));
                    }
                    default: {
                        return append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Send SMS"], ["onClick", (_arg_3) => {
                            dispatch(new Message(3, new AlertStep(1)));
                        }]])), delay(() => singleton_1(createElement("span", createObj(toList(delay(() => {
                            let arg_2, arg_1, copyOfStruct;
                            let total;
                            const matchValue_2 = 1 * (model.News.NumMembersWithAccess - model.News.NumReads);
                            total = ((matchValue_2 > 0) ? matchValue_2 : 0);
                            return singleton_1(["children", (arg_2 = format('{0:' + "0.00" + '}', total), (arg_1 = ((copyOfStruct = 1, format('{0:' + "0.00" + '}', copyOfStruct))), toText(printf("%skr pr SMS. Totalt %skr for denne nyheten"))(arg_1)(arg_2)))]);
                        })))))));
                    }
                }
            })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])))) : empty_1();
        }));
    })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_4))])])));
}

export function NewsReport(newsReportInputProps) {
    let elems_7, elems, props, elems_1, elems_6;
    const onClose = newsReportInputProps.onClose;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(newsReportInputProps.x));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchDistribution(model_1.News.Id, dispatch);
        fetchNotRead(model_1.News.Id, dispatch);
        fetchReactions(model_1.News.Id, dispatch);
    });
    let readPieData;
    let readPercent;
    if (model_1.News.NumMembersWithAccess === 0) {
        readPercent = 0;
    }
    else {
        const matchValue = (~(~((model_1.News.NumReads * 100) / model_1.News.NumMembersWithAccess))) | 0;
        readPercent = ((matchValue > 100) ? 100 : matchValue);
    }
    readPieData = [new PieData("Lest", readPercent), new PieData("Ulest", 100 - readPercent)];
    let confirmPieData;
    let confirmedPercent;
    if (model_1.News.NumMembersWithAccess === 0) {
        confirmedPercent = 0;
    }
    else {
        const matchValue_1 = (~(~((model_1.News.Confirmed * 100) / model_1.News.NumMembersWithAccess))) | 0;
        confirmedPercent = ((matchValue_1 > 100) ? 100 : matchValue_1);
    }
    confirmPieData = [new PieData("Bekreftet", confirmedPercent), new PieData("Ubekreftet", 100 - confirmedPercent)];
    const children_3 = ofArray([createElement("div", createObj(ofArray([["id", "base-stats"], (elems_7 = [createElement("div", createObj(ofArray([["style", {}], (elems = [MuiHelpers_createElement(Tooltip_1, [["title", "Tilbake til oversikt"], ["children", MuiHelpers_createElement(IconButton, [["children", (props = {}, react.createElement(Close, props))], ["size", "small"], ["onClick", (_arg_1) => {
        onClose();
    }]])]])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
        textAlign: "center",
        marginBottom: 10,
    }], (elems_1 = [createElement("span", {
        style: {
            fontSize: 1.3 + "rem",
            fontWeight: "bold",
        },
        children: model_1.News.Title,
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
    }], (elems_6 = toList(delay(() => {
        let children_1;
        return append(singleton_1((children_1 = singleton(entryInfoTable(model_1.News)), createElement("div", {
            children: Interop_reactApi_1.Children.toArray(Array.from(children_1)),
        }))), delay(() => {
            let elems_3, elems_2;
            return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }], (elems_3 = [createElement("div", createObj(ofArray([["style", {
                height: 150 + "px",
                width: 150 + "px",
                margin: 20,
            }], (elems_2 = [percentagePieChart(readPieData, "rgb(255, 207, 1)")], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])]))), createElement("span", {
                style: {
                    fontWeight: "bold",
                    position: "absolute",
                    fontSize: 0.7 + "rem",
                    fontStyle: "italic",
                },
                children: "Lest",
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])))), delay(() => append(singleton_1(ReactionIcons(model_1)), delay(() => {
                let elems_5, elems_4;
                return append(model_1.News.RequiresConfirmation ? singleton_1(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }], (elems_5 = [createElement("div", createObj(ofArray([["style", {
                    height: 150 + "px",
                    width: 150 + "px",
                }], (elems_4 = [percentagePieChart(confirmPieData, "forestgreen")], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_4))])]))), createElement("span", {
                    style: {
                        fontWeight: "bold",
                        position: "absolute",
                        fontSize: 0.7 + "rem",
                        fontStyle: "italic",
                    },
                    children: "Bekreftet",
                })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_5))])])))) : empty_1(), delay(() => singleton_1(notifyView(model_1, dispatch))));
            }))));
        }));
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_7))])]))), drawReadDistribution(model_1), notReadTable(model_1)]);
    return createElement("div", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children_3)),
    });
}

