import { createElement } from "react";
import * as react from "react";
import { createObj } from "./fable_modules/fable-library.3.7.22/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { append, empty, singleton as singleton_1, ofArray } from "./fable_modules/fable-library.3.7.22/List.js";
import { DOMAttr, HTMLAttr } from "./fable_modules/Fable.React.7.4.0/Fable.React.Props.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { singleton, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { Option, button as button_1 } from "./fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Common_GenericOption, Color_IColor } from "./fable_modules/Fulma.2.16.0/Common.fs.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_1, modal } from "./fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export function demoLimitNotification(x) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "center",
        gap: 3 + "px ",
        padding: ((3 + "px ") + 8) + "px",
        borderRadius: 5,
        border: (((1 + "px ") + "solid") + " ") + "#363636",
    }], (elems = [createElement("i", {
        className: "fas fa-exclamation-circle",
    }), createElement("span", {
        children: [x],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function warningBar(msg) {
    return react.createElement("div", {
        className: "alert alert-warning",
    }, msg);
}

export function settingsIcon() {
    const props = [new HTMLAttr(64, "fas fa-cog"), ["style", {
        color: "#ffffff",
    }]];
    return react.createElement("i", keyValueList(props, 1));
}

export function editIcon() {
    return react.createElement("i", {
        className: "fas fa-edit",
    });
}

export function deleteIcon() {
    return react.createElement("i", {
        className: "fas fa-trash",
    });
}

export function confirmedIcon() {
    const props = [new HTMLAttr(64, "fas fa-check-circle"), ["style", {
        color: "#00f910",
    }]];
    return react.createElement("i", keyValueList(props, 1));
}

export function unconfirmedIcon() {
    const props = [new HTMLAttr(64, "fas fa-times-circle"), ["style", {
        color: "red",
    }]];
    return react.createElement("i", keyValueList(props, 1));
}

export function unselectedIcon() {
    return react.createElement("i", {
        className: "far fa-square",
    });
}

export function selectedIcon() {
    return react.createElement("i", {
        className: "far fa-caret-square-down",
    });
}

export function subMenuButton(onClick, title, toggled) {
    let props_4, children_4, props, props_2, children_2;
    const children_6 = [(props_4 = [["style", {
        display: "grid",
        gridTemplateColumns: "95% 5%",
        fontSize: "1.1rem",
    }]], (children_4 = [(props = [["style", {
        gridColumn: 1,
    }]], react.createElement("div", keyValueList(props, 1), title)), (props_2 = [["style", {
        gridColumn: 2,
    }]], (children_2 = toList(delay(() => (toggled ? singleton(selectedIcon()) : singleton(unselectedIcon())))), react.createElement("div", keyValueList(props_2, 1), ...children_2)))], react.createElement("div", keyValueList(props_4, 1), ...children_4)))];
    return react.createElement("button", {
        className: "toggle-button",
        onClick: onClick,
    }, ...children_6);
}

export function editButton(editFunc, isLoading) {
    return button_1(ofArray([new Option(17, singleton_1(["style", {
        width: "30px",
    }])), new Option(0, new Color_IColor(5)), new Option(19, "is-small is-block"), new Option(18, editFunc), new Option(13, isLoading)]), singleton_1(editIcon()));
}

export function deleteButton(deleteFunc, isLoading) {
    return button_1(ofArray([new Option(17, singleton_1(["style", {
        width: "30px",
    }])), new Option(0, new Color_IColor(8)), new Option(19, "is-small is-block"), new Option(18, deleteFunc), new Option(13, isLoading)]), singleton_1(deleteIcon()));
}

export function confirmDeleteModal(confirmFunc, abortFunc) {
    let props_4, children_4, props, children, props_2, children_2;
    return modal(singleton_1(new Option_1(1, true)), ofArray([background(singleton_1(new Common_GenericOption(1, singleton_1(new DOMAttr(40, abortFunc)))), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton_1(Card_title(empty(), singleton_1("Bekreft sletting")))), Card_body(empty(), singleton_1((props_4 = [["style", {
        width: "100%",
        height: "100%",
        display: "grid",
        gridTemplateColumns: "auto 50px auto",
    }]], (children_4 = [(props = [["style", {
        gridColumn: 1,
        margin: "auto auto",
    }]], (children = [button_1(ofArray([new Option(18, confirmFunc), new Option(0, new Color_IColor(8))]), singleton_1("Slett"))], react.createElement("div", keyValueList(props, 1), ...children))), (props_2 = [["style", {
        gridColumn: 3,
        margin: "auto auto",
    }]], (children_2 = [button_1(ofArray([new Option(18, abortFunc), new Option(0, new Color_IColor(20))]), singleton_1("Avbryt"))], react.createElement("div", keyValueList(props_2, 1), ...children_2)))], react.createElement("div", keyValueList(props_4, 1), ...children_4))))), Card_foot(empty(), empty())]))]));
}

export function loadingIndicator() {
    return MuiHelpers_createElement(CircularProgress, [["size", 25], ["style", {
        color: "rgb(255, 207, 1)",
    }]]);
}

export function loadingIndicatorSmall() {
    return MuiHelpers_createElement(CircularProgress, [["size", 15], ["style", {
        color: "rgb(255, 207, 1)",
    }]]);
}

export function loadingIndicatorLarge() {
    return MuiHelpers_createElement(CircularProgress, [["size", 40], ["style", {
        color: "rgb(255, 207, 1)",
    }]]);
}

export function snackError(errMsg, dismiss) {
    return MuiHelpers_createElement(Snackbar, [["open", errMsg != null], ["onClose", (_arg_1, v) => {
        dismiss();
    }], ["autoHideDuration", 20000], ["anchorOrigin", {
        vertical: "bottom",
        horizontal: "center",
    }], ["children", MuiHelpers_createElement(Alert, [["onClose", (_arg_2) => {
        dismiss();
    }], ["severity", "error"], ["variant", "filled"], ["style", {}], ["children", (errMsg == null) ? "" : errMsg]])]]);
}

export function errorBanner(errMsg, dismiss) {
    if (errMsg != null) {
        return MuiHelpers_createElement(Alert, [["severity", "error"], ["variant", "outlined"], ["children", errMsg], ["onClose", (_arg) => {
            dismiss();
        }]]);
    }
    else {
        return null;
    }
}

export function button(disabled, onClick, styles, children) {
    return createElement("button", {
        style: createObj(append(ofArray([["border", "none"], ["backgroundColor", "#363636"], ["width", 150], ["color", "#FFFFFF"], ["padding", 10], ["fontSize", 16 + "px"], ["cursor", "pointer"], ["borderRadius", 5]]), styles)),
        disabled: disabled,
        onClick: onClick,
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

