import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.7.22/Util.js";
import { Administrators } from "./Administrators.js";
import { OpenAPI } from "./OpenAPI.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { ofArray } from "./fable_modules/fable-library.3.7.22/List.js";

export function Admin() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    }], (elems = [createElement(Administrators, null), createElement(OpenAPI, null)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

