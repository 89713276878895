import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { GenericMessage$reflection, AdminForm$reflection, AdminForm, Admin$reflection } from "./Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, string_type, bool_type, option_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { sortBy, map as map_1, append } from "./fable_modules/fable-library.3.7.22/Array.js";
import { validateEmail, checkFetchError } from "./Utils.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { props as props_13 } from "./Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Admin_Add, Admin_All } from "./Shared/Urls.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { comparePrimitives, createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createElement } from "react";
import * as react from "react";
import { loadingIndicator, errorBanner, loadingIndicatorSmall, snackError } from "./ViewHelpers.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import DialogContent from "@material-ui/core/DialogContent";
import { empty as empty_1, append as append_1, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Add from "@material-ui/icons/Add";

class Model extends Record {
    constructor(Admins, AddForm, DeleteForm, ErrorMsg) {
        super();
        this.Admins = Admins;
        this.AddForm = AddForm;
        this.DeleteForm = DeleteForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Administrators.Model", [], Model, () => [["Admins", option_type(array_type(Admin$reflection()))], ["AddForm", bool_type], ["DeleteForm", option_type(Admin$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AdminsResponse", "ToggleAddForm", "AdminAdded", "ToggleDeleteForm", "AdminDeleted", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Administrators.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Admin$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Admin$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], [["Item", Admin$reflection()]], [["Item", option_type(Admin$reflection())]], [["Item", string_type]], []]);
}

function init() {
    return new Model(void 0, false, void 0, void 0);
}

function update(model, msg) {
    let matchValue, array;
    switch (msg.tag) {
        case 1: {
            return new Model(model.Admins, msg.fields[0], model.DeleteForm, model.ErrorMsg);
        }
        case 2: {
            const x_2 = msg.fields[0];
            return new Model((matchValue = model.Admins, (matchValue != null) ? append([x_2], matchValue) : [x_2]), false, model.DeleteForm, model.ErrorMsg);
        }
        case 3: {
            return new Model(model.Admins, model.AddForm, msg.fields[0], model.ErrorMsg);
        }
        case 4: {
            const matchValue_1 = model.Admins;
            if (matchValue_1 != null) {
                return new Model((array = matchValue_1, array.filter((z) => (z.Id !== msg.fields[0]))), model.AddForm, void 0, model.ErrorMsg);
            }
            else {
                return new Model(model.Admins, model.AddForm, void 0, model.ErrorMsg);
            }
        }
        case 5: {
            return new Model(model.Admins, model.AddForm, model.DeleteForm, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return new Model(model.Admins, model.AddForm, model.DeleteForm, "Kunne ikke laste administratorer. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(res.fields[0], model.AddForm, model.DeleteForm, model.ErrorMsg);
            }
        }
    }
}

function fetchAdmins(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        return ((props = props_13(), (decoder = Auto_generateBoxedDecoder_79988AEF(array_type(Admin$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(Admin_All, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(Admin$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(Admin$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function AddForm(addFormInputProps) {
    let elements_2, children, elems, children_1;
    const onClose = addFormInputProps.onClose;
    const patternInput = useFeliz_React__React_useState_Static_1505(new AdminForm(""));
    const admin = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_2[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_4, v) => {
        onClose(void 0);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Legg til administrator"]]), (children = singleton(createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems = [snackError(patternInput_2[0], () => {
        setErrMsg(void 0);
    }), MuiHelpers_createElement(TextField, [["size", "small"], ["variant", "outlined"], ["defaultValue", admin.Email], ["onChange", (e) => {
        patternInput[1](new AdminForm(Browser_Types_Event__Event_get_Value(e)));
    }], ["label", "E-post adresse"], ["style", {
        marginBottom: 10,
    }]]), createElement("span", {
        children: "En e-post med lenke til aktivering av kontoen sendes til e-posten over.",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (patternInput_1[0] ? singleton_1(loadingIndicatorSmall()) : append_1(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Avbryt"], ["onClick", (_arg_6) => {
        onClose(void 0);
    }]])), delay(() => singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["disabled", !validateEmail(admin.Email)], ["children", "Legg til"], ["onClick", (_arg_7) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let body, props, decoder, data_1, caseStrategy_3, extra_3;
            if (validateEmail(admin.Email)) {
                setProcessing(true);
                return ((body = Auto_generateBoxedEncoder_Z20B7B430(AdminForm$reflection(), void 0, void 0, void 0)(admin), (props = props_13(), (decoder = Auto_generateBoxedDecoder_79988AEF(Admin$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                    let properties_2, headers_2;
                    try {
                        const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(Admin_Add, properties_3).then((_arg) => {
                            let response_1, decoder_1_1;
                            return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Admin$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                let matchValue;
                                return Promise.resolve(equals(Admin$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                            })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                        }))));
                        return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                    }
                    catch (exn) {
                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                    }
                })()))))))).then((_arg_2) => {
                    const res = _arg_2;
                    if (res.tag === 1) {
                        const err = res.fields[0];
                        setErrMsg((err.tag === 2) ? (((err.fields[0].status) === 400) ? "Ugyldig e-post adresse" : (((err.fields[0].status) === 409) ? "Det eksisterer allerede en adminkonto med denne e-post adressen." : "Kunne ikke legge til administrator. Vennligst prøv igjen senere.")) : "Kunne ikke legge til administrator. Vennligst prøv igjen senere.");
                        setProcessing(false);
                        return Promise.resolve();
                    }
                    else {
                        onClose(res.fields[0]);
                        return Promise.resolve();
                    }
                });
            }
            else {
                return Promise.resolve();
            }
        }));
        pr_1.then();
    }]]))))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function DeleteForm(deleteFormInputProps) {
    let elements_2, children, children_1;
    const onClose = deleteFormInputProps.onClose;
    const email = deleteFormInputProps.email;
    const adminId = deleteFormInputProps.adminId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_4, v) => {
        onClose(false);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Slett administrator"]]), (children = ofArray([MuiHelpers_createElement(DialogContentText, [["children", `Er du sikker på at du ønsker å slette administratorkontor for ${email}?`]]), errorBanner(patternInput_1[0], () => {
        setErrMsg(void 0);
    })]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (patternInput[0] ? singleton_1(loadingIndicatorSmall()) : append_1(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Nei"], ["onClick", (_arg_6) => {
        onClose(false);
    }]])), delay(() => singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Ja"], ["onClick", (_arg_7) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let props, decoder, data_1, caseStrategy_2, extra_2;
            setProcessing(true);
            const requestPath = `/api/admin/${adminId}
                `;
            return ((props = props_13(), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 1) {
                    setErrMsg("Kunne ikke slette administrator. Vennligst prøv igjen senere.");
                    setProcessing(false);
                    return Promise.resolve();
                }
                else {
                    const x_1 = res.fields[0];
                    if (x_1.result === "success") {
                        onClose(true);
                        return Promise.resolve();
                    }
                    else {
                        setErrMsg(x_1.message);
                        setProcessing(false);
                        return Promise.resolve();
                    }
                }
            });
        }));
        pr_1.then();
    }]]))))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function drawAdmins(model, dispatch) {
    let elems_7;
    return createElement("div", createObj(ofArray([["style", {}], (elems_7 = toList(delay(() => {
        let elements_5, children_1, children, children_4, elems;
        const matchValue = model.Admins;
        if (matchValue != null) {
            const x = matchValue;
            return singleton_1(MuiHelpers_createElement(Table, [["size", "small"], (elements_5 = [(children_1 = singleton((children = ofArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"], ["children", "E-post"]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"], ["children", "Aktivert"]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"], ["children", "Superadmin"]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"]])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_4 = singleton(map_1((a_1) => {
                let elements_2, props_8;
                const children_3 = ofArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["children", a_1.Username]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["children", a_1.Activated ? "Ja" : "Nei"]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["children", a_1.IsSuperAdmin ? "Ja" : "Nei"]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], (elements_2 = [MuiHelpers_createElement(IconButton, [["size", "small"], ["children", (props_8 = {}, react.createElement(Delete, props_8))], ["onClick", (_arg) => {
                    dispatch(new Message(3, a_1));
                }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])]);
                return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]);
            }, sortBy((a) => a.Username, x, {
                Compare: comparePrimitives,
            }))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_5))])]));
        }
        else {
            return singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }], (elems = [loadingIndicator()], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
}

export function Administrators() {
    let elements;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchAdmins(dispatch);
    });
    return MuiHelpers_createElement(Paper, [["elevation", 1], ["style", {
        padding: 10,
        maxWidth: 800,
        margin: 10,
    }], (elements = toList(delay(() => append_1(singleton_1(snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(5));
    })), delay(() => append_1(model_1.AddForm ? singleton_1(createElement(AddForm, {
        onClose: (newAdmin) => {
            if (newAdmin != null) {
                dispatch(new Message(2, newAdmin));
            }
            else {
                dispatch(new Message(1, false));
            }
        },
    })) : empty_1(), delay(() => {
        let matchValue, a_1;
        return append_1((matchValue = model_1.DeleteForm, (matchValue != null) ? ((a_1 = matchValue, singleton_1(createElement(DeleteForm, {
            adminId: a_1.Id,
            email: a_1.Username,
            onClose: (didDelete) => {
                if (didDelete) {
                    dispatch(new Message(4, a_1.Id));
                }
                else {
                    dispatch(new Message(3, void 0));
                }
            },
        })))) : ((empty_1()))), delay(() => {
            let elems, props_1;
            return append_1(singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }], (elems = [createElement("span", {
                style: {
                    fontSize: 1.5 + "rem",
                },
                children: "Administratorer",
            }), MuiHelpers_createElement(Tooltip, [["title", "Legg til administrator"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", (props_1 = {}, react.createElement(Add, props_1))], ["onClick", (_arg_2) => {
                dispatch(new Message(1, !model_1.AddForm));
            }]])]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => singleton_1(drawAdmins(model_1, dispatch))));
        }));
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
}

