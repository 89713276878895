import { toString as toString_2, Union, Record } from "../fable_modules/fable-library.3.7.22/Types.js";
import { unit_type, equals, obj_type, union_type, record_type, string_type, option_type, class_type, bool_type, int32_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { News_NewsEntry$reflection, News_NewsMeta$reflection, News_NewsForm, News_NewsForm$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { Array_distinct } from "../fable_modules/fable-library.3.7.22/Seq2.js";
import { sort, map as map_1, append } from "../fable_modules/fable-library.3.7.22/Array.js";
import { comparePrimitives, createObj, uncurry, numberHash } from "../fable_modules/fable-library.3.7.22/Util.js";
import { toISO8601, checkFetchError } from "../Utils.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { props as props_31 } from "../Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { map as map_2, singleton, cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505, useReact_useRef_1505 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import { toSelectable, toName, toIcon, toColor } from "./Reactions.js";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Value, Browser_Types_Event__Event_get_Checked } from "../fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { addDays, minute, hour, day, month, year, create, parse, toString as toString_1, addHours, now } from "../fable_modules/fable-library.3.7.22/Date.js";
import TextField from "@material-ui/core/TextField";
import { format } from "../fable_modules/fable-library.3.7.22/String.js";
import { parse as parse_1 } from "../fable_modules/fable-library.3.7.22/Int32.js";
import { QuillModule, QuillProp_Modules_636F969F, QuillProp, quill } from "./ReactQuill.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { PublishTarget, PublishTarget__ToInt } from "../Shared/Shared.js";
import { loadingIndicator, snackError, loadingIndicatorSmall } from "../ViewHelpers.js";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";

class Model extends Record {
    constructor(ChannelId, Form, Updating, PublishAfter, PublishUntil, ValidTitle, ValidAuthor, ValidAfterHour, ValidAfterMinute, ValidUntilHour, ValidUntilMinute, MemberCount, Processing, ErrorMsg) {
        super();
        this.ChannelId = (ChannelId | 0);
        this.Form = Form;
        this.Updating = Updating;
        this.PublishAfter = PublishAfter;
        this.PublishUntil = PublishUntil;
        this.ValidTitle = ValidTitle;
        this.ValidAuthor = ValidAuthor;
        this.ValidAfterHour = ValidAfterHour;
        this.ValidAfterMinute = ValidAfterMinute;
        this.ValidUntilHour = ValidUntilHour;
        this.ValidUntilMinute = ValidUntilMinute;
        this.MemberCount = MemberCount;
        this.Processing = Processing;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Publish.Model", [], Model, () => [["ChannelId", int32_type], ["Form", News_NewsForm$reflection()], ["Updating", bool_type], ["PublishAfter", option_type(class_type("System.DateTime"))], ["PublishUntil", option_type(class_type("System.DateTime"))], ["ValidTitle", bool_type], ["ValidAuthor", bool_type], ["ValidAfterHour", bool_type], ["ValidAfterMinute", bool_type], ["ValidUntilHour", bool_type], ["ValidUntilMinute", bool_type], ["MemberCount", option_type(int32_type)], ["Processing", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["MemberCountResponse", "UpdateTitle", "UpdateExcerpt", "UpdateAuthor", "UpdateBody", "TogglePinned", "TogglePush", "ToggleSMS", "UpdatePublishTo", "UpdatePublishAfter", "UpdatePublishUntil", "ToggleAllowReactions", "AddReaction", "RemoveReaction", "ToggleRequireConfirmation", "SetAfterHourValid", "SetAfterMinValid", "SetUntilHourValid", "SetUntilMinValid", "Commit", "PostFailed", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Publish.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [int32_type, FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", int32_type]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", int32_type]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", bool_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [], [["Item", string_type]], []]);
}

function emptyForm() {
    return new News_NewsForm(void 0, "", "", "", "", true, false, true, false, 1, void 0, void 0, true, new Int32Array([1, 2, 3, 4]), false);
}

function init(channelId, updating) {
    return new Model(channelId, (updating == null) ? emptyForm() : updating, updating != null, void 0, void 0, true, true, true, true, true, true, void 0, false, void 0);
}

function update(model, msg) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11;
    switch (msg.tag) {
        case 1: {
            return new Model(model.ChannelId, (inputRecord = model.Form, new News_NewsForm(inputRecord.Id, msg.fields[0], inputRecord.Excerpt, inputRecord.Author, inputRecord.Body, inputRecord.Push, inputRecord.SMS, inputRecord.Published, inputRecord.Pinned, inputRecord.PublishTo, inputRecord.PublishAfter, inputRecord.PublishUntil, inputRecord.AllowReactions, inputRecord.AllowedReactions, inputRecord.RequireConfirmation)), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 2: {
            return new Model(model.ChannelId, (inputRecord_1 = model.Form, new News_NewsForm(inputRecord_1.Id, inputRecord_1.Title, msg.fields[0], inputRecord_1.Author, inputRecord_1.Body, inputRecord_1.Push, inputRecord_1.SMS, inputRecord_1.Published, inputRecord_1.Pinned, inputRecord_1.PublishTo, inputRecord_1.PublishAfter, inputRecord_1.PublishUntil, inputRecord_1.AllowReactions, inputRecord_1.AllowedReactions, inputRecord_1.RequireConfirmation)), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 3: {
            return new Model(model.ChannelId, (inputRecord_2 = model.Form, new News_NewsForm(inputRecord_2.Id, inputRecord_2.Title, inputRecord_2.Excerpt, msg.fields[0], inputRecord_2.Body, inputRecord_2.Push, inputRecord_2.SMS, inputRecord_2.Published, inputRecord_2.Pinned, inputRecord_2.PublishTo, inputRecord_2.PublishAfter, inputRecord_2.PublishUntil, inputRecord_2.AllowReactions, inputRecord_2.AllowedReactions, inputRecord_2.RequireConfirmation)), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 4: {
            return new Model(model.ChannelId, (inputRecord_3 = model.Form, new News_NewsForm(inputRecord_3.Id, inputRecord_3.Title, inputRecord_3.Excerpt, inputRecord_3.Author, msg.fields[0], inputRecord_3.Push, inputRecord_3.SMS, inputRecord_3.Published, inputRecord_3.Pinned, inputRecord_3.PublishTo, inputRecord_3.PublishAfter, inputRecord_3.PublishUntil, inputRecord_3.AllowReactions, inputRecord_3.AllowedReactions, inputRecord_3.RequireConfirmation)), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 5: {
            return new Model(model.ChannelId, (inputRecord_4 = model.Form, new News_NewsForm(inputRecord_4.Id, inputRecord_4.Title, inputRecord_4.Excerpt, inputRecord_4.Author, inputRecord_4.Body, inputRecord_4.Push, inputRecord_4.SMS, inputRecord_4.Published, msg.fields[0], inputRecord_4.PublishTo, inputRecord_4.PublishAfter, inputRecord_4.PublishUntil, inputRecord_4.AllowReactions, inputRecord_4.AllowedReactions, inputRecord_4.RequireConfirmation)), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 6: {
            return new Model(model.ChannelId, (inputRecord_5 = model.Form, new News_NewsForm(inputRecord_5.Id, inputRecord_5.Title, inputRecord_5.Excerpt, inputRecord_5.Author, inputRecord_5.Body, msg.fields[0], inputRecord_5.SMS, inputRecord_5.Published, inputRecord_5.Pinned, inputRecord_5.PublishTo, inputRecord_5.PublishAfter, inputRecord_5.PublishUntil, inputRecord_5.AllowReactions, inputRecord_5.AllowedReactions, inputRecord_5.RequireConfirmation)), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 7: {
            return new Model(model.ChannelId, (inputRecord_6 = model.Form, new News_NewsForm(inputRecord_6.Id, inputRecord_6.Title, inputRecord_6.Excerpt, inputRecord_6.Author, inputRecord_6.Body, inputRecord_6.Push, msg.fields[0], inputRecord_6.Published, inputRecord_6.Pinned, inputRecord_6.PublishTo, inputRecord_6.PublishAfter, inputRecord_6.PublishUntil, inputRecord_6.AllowReactions, inputRecord_6.AllowedReactions, inputRecord_6.RequireConfirmation)), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 8: {
            return new Model(model.ChannelId, (inputRecord_7 = model.Form, new News_NewsForm(inputRecord_7.Id, inputRecord_7.Title, inputRecord_7.Excerpt, inputRecord_7.Author, inputRecord_7.Body, inputRecord_7.Push, inputRecord_7.SMS, inputRecord_7.Published, inputRecord_7.Pinned, msg.fields[0], inputRecord_7.PublishAfter, inputRecord_7.PublishUntil, inputRecord_7.AllowReactions, inputRecord_7.AllowedReactions, inputRecord_7.RequireConfirmation)), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 9: {
            return new Model(model.ChannelId, model.Form, model.Updating, msg.fields[0], model.PublishUntil, model.ValidTitle, model.ValidAuthor, true, true, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 10: {
            return new Model(model.ChannelId, model.Form, model.Updating, model.PublishAfter, msg.fields[0], model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, true, true, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 11: {
            return new Model(model.ChannelId, (inputRecord_8 = model.Form, new News_NewsForm(inputRecord_8.Id, inputRecord_8.Title, inputRecord_8.Excerpt, inputRecord_8.Author, inputRecord_8.Body, inputRecord_8.Push, inputRecord_8.SMS, inputRecord_8.Published, inputRecord_8.Pinned, inputRecord_8.PublishTo, inputRecord_8.PublishAfter, inputRecord_8.PublishUntil, msg.fields[0], inputRecord_8.AllowedReactions, inputRecord_8.RequireConfirmation)), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 12: {
            return new Model(model.ChannelId, (inputRecord_9 = model.Form, new News_NewsForm(inputRecord_9.Id, inputRecord_9.Title, inputRecord_9.Excerpt, inputRecord_9.Author, inputRecord_9.Body, inputRecord_9.Push, inputRecord_9.SMS, inputRecord_9.Published, inputRecord_9.Pinned, inputRecord_9.PublishTo, inputRecord_9.PublishAfter, inputRecord_9.PublishUntil, inputRecord_9.AllowReactions, Array_distinct(append(new Int32Array([msg.fields[0]]), model.Form.AllowedReactions, Int32Array), {
                Equals: (x_12, y) => (x_12 === y),
                GetHashCode: numberHash,
            }), inputRecord_9.RequireConfirmation)), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 13: {
            return new Model(model.ChannelId, (inputRecord_10 = model.Form, new News_NewsForm(inputRecord_10.Id, inputRecord_10.Title, inputRecord_10.Excerpt, inputRecord_10.Author, inputRecord_10.Body, inputRecord_10.Push, inputRecord_10.SMS, inputRecord_10.Published, inputRecord_10.Pinned, inputRecord_10.PublishTo, inputRecord_10.PublishAfter, inputRecord_10.PublishUntil, inputRecord_10.AllowReactions, model.Form.AllowedReactions.filter((r) => (r !== msg.fields[0])), inputRecord_10.RequireConfirmation)), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 14: {
            return new Model(model.ChannelId, (inputRecord_11 = model.Form, new News_NewsForm(inputRecord_11.Id, inputRecord_11.Title, inputRecord_11.Excerpt, inputRecord_11.Author, inputRecord_11.Body, inputRecord_11.Push, inputRecord_11.SMS, inputRecord_11.Published, inputRecord_11.Pinned, inputRecord_11.PublishTo, inputRecord_11.PublishAfter, inputRecord_11.PublishUntil, inputRecord_11.AllowReactions, inputRecord_11.AllowedReactions, msg.fields[0])), model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 15: {
            return new Model(model.ChannelId, model.Form, model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, msg.fields[0], model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 16: {
            return new Model(model.ChannelId, model.Form, model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, msg.fields[0], model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 17: {
            return new Model(model.ChannelId, model.Form, model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, msg.fields[0], model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 18: {
            return new Model(model.ChannelId, model.Form, model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, msg.fields[0], model.MemberCount, model.Processing, model.ErrorMsg);
        }
        case 19: {
            const validTitle = model.Form.Title.trim().length > 0;
            const validAuthor = model.Form.Author.trim().length > 0;
            if (validTitle && validAuthor) {
                return new Model(model.ChannelId, model.Form, model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, true, model.ErrorMsg);
            }
            else {
                return new Model(model.ChannelId, model.Form, model.Updating, model.PublishAfter, model.PublishUntil, validTitle, validAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, model.ErrorMsg);
            }
        }
        case 20: {
            return new Model(model.ChannelId, model.Form, model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, false, msg.fields[0]);
        }
        case 21: {
            return new Model(model.ChannelId, model.Form, model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, model.MemberCount, model.Processing, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return model;
            }
            else {
                return new Model(model.ChannelId, model.Form, model.Updating, model.PublishAfter, model.PublishUntil, model.ValidTitle, model.ValidAuthor, model.ValidAfterHour, model.ValidAfterMinute, model.ValidUntilHour, model.ValidUntilMinute, res.fields[0], model.Processing, model.ErrorMsg);
            }
        }
    }
}

function fetchMemberCount(channelId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = `/api/channel/num-members/${channelId}`;
        return ((props = props_31(), (decoder = Auto_generateBoxedDecoder_79988AEF(int32_type, void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(int32_type, caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(int32_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function field(children) {
    return createElement("div", {
        style: {
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function ReactionForm(reactionFormInputProps) {
    const dispatch = reactionFormInputProps.dispatch;
    const selectable = reactionFormInputProps.selectable;
    const anchor = useReact_useRef_1505(void 0);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setOpen = patternInput[1];
    const isOpen = patternInput[0];
    const menuOptions = map_1((x) => {
        let elements_1, children;
        return MuiHelpers_createElement(MenuItem, [(elements_1 = [(children = singleton(MuiHelpers_createElement(Icon, [["fontSize", "small"], ["style", {
            color: toColor(x),
        }], ["className", toIcon(x)]])), MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), MuiHelpers_createElement(ListItemText, [["children", toName(x)]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))]), ["onClick", (_arg) => {
            dispatch(new Message(12, x));
            setOpen(false);
        }]]);
    }, selectable);
    const children_2 = toList(delay(() => {
        let props_3;
        return (menuOptions.length > 0) ? append_1(singleton_1(MuiHelpers_createElement(IconButton, [["aria-label", "Legg til"], ["ref", anchor], ["onClick", (_arg_1) => {
            setOpen(!isOpen);
        }], ["size", "small"], ["children", (props_3 = {}, react.createElement(Add, props_3))]])), delay(() => singleton_1(MuiHelpers_createElement(Menu, [["open", isOpen], ["onClose", (_arg_3, v) => {
            setOpen(false);
        }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray(Array.from(menuOptions))]])))) : empty_1();
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

function drawPublishPeriod(model, dispatch) {
    let elems_6, elems_2, elems_5;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    }], (elems_6 = [createElement("div", createObj(ofArray([["style", {
        width: 325,
        display: "flex",
        flexDirection: "column",
        margin: ((10 + "px ") + 0) + "px",
    }], (elems_2 = toList(delay(() => append_1(singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Publiser etter bestemt tidspunkt"], ["control", MuiHelpers_createElement(Checkbox, [["style", {
        color: "#363636",
    }], ["checked", model.PublishAfter != null], ["onChange", (e) => {
        let copyOfStruct;
        const _arg = Browser_Types_Event__Event_get_Checked(e);
        if (model.PublishAfter != null) {
            dispatch(new Message(9, void 0));
        }
        else {
            dispatch(new Message(9, (copyOfStruct = now(), addHours(copyOfStruct, 1))));
        }
    }]])]])), delay(() => {
        let elems_1, copyOfStruct_1, elems, copyOfStruct_2;
        const matchValue = model.PublishAfter;
        if (matchValue != null) {
            const pa = matchValue;
            return singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                alignItems: "center",
            }], (elems_1 = [createElement("span", {
                style: {
                    marginRight: 10,
                },
                className: "fas fa-calendar-alt",
            }), MuiHelpers_createElement(TextField, [["type", "date"], ["size", "small"], ["variant", "outlined"], ["value", toString_1(pa, "yyyy-MM-dd")], ["style", {
                maxWidth: 150 + "px",
                marginRight: 10 + "px",
                backgroundColor: "#FFFFFF",
            }], ["onChange", (e_1) => {
                const x = Browser_Types_Event__Event_get_Value(e_1);
                try {
                    const y = parse(x);
                    dispatch(new Message(9, create(year(y), month(y), day(y), hour(pa), minute(pa), 0)));
                }
                catch (matchValue_1) {
                }
            }]]), createElement("span", {
                style: {
                    marginRight: 10,
                },
                className: "fas fa-clock",
            }), MuiHelpers_createElement(TextField, [["size", "small"], ["variant", "outlined"], ["defaultValue", (copyOfStruct_1 = hour(pa), format('{0:' + "00" + '}', copyOfStruct_1))], ["style", {
                maxWidth: 50 + "px",
                textAlign: "center",
                backgroundColor: "#FFFFFF",
            }], ["inputProps", {
                style: {
                    textAlign: "center",
                },
            }], ["error", !model.ValidAfterHour], ["onChange", (e_2) => {
                const x_1 = Browser_Types_Event__Event_get_Value(e_2);
                try {
                    const asInt = parse_1(x_1, 511, false, 32) | 0;
                    if ((asInt >= 0) && (asInt <= 23)) {
                        dispatch(new Message(9, create(year(pa), month(pa), day(pa), asInt, minute(pa), 0)));
                    }
                    else {
                        dispatch(new Message(15, false));
                    }
                }
                catch (matchValue_2) {
                    dispatch(new Message(15, false));
                }
            }]]), createElement("span", createObj(ofArray([["style", {
                marginLeft: 4 + "px",
                marginRight: 4 + "px",
            }], (elems = [createElement("b", {
                children: [":"],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), MuiHelpers_createElement(TextField, [["size", "small"], ["variant", "outlined"], ["defaultValue", (copyOfStruct_2 = minute(pa), format('{0:' + "00" + '}', copyOfStruct_2))], ["style", {
                maxWidth: 50 + "px",
                textAlign: "center",
                backgroundColor: "#FFFFFF",
            }], ["inputProps", {
                style: {
                    textAlign: "center",
                },
            }], ["error", !model.ValidAfterMinute], ["onChange", (e_3) => {
                const x_2 = Browser_Types_Event__Event_get_Value(e_3);
                try {
                    const asInt_1 = parse_1(x_2, 511, false, 32) | 0;
                    if ((asInt_1 >= 0) && (asInt_1 <= 59)) {
                        dispatch(new Message(9, create(year(pa), month(pa), day(pa), hour(pa), asInt_1, 0)));
                    }
                    else {
                        dispatch(new Message(16, false));
                    }
                }
                catch (matchValue_3) {
                    dispatch(new Message(16, false));
                }
            }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
        }
        else {
            return append_1(singleton_1(createElement("br", {})), delay(() => singleton_1(createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                    fontStyle: "italic",
                },
                children: "Artikkelen vil gjøres tilgjengelig øyeblikkelig.",
            }))));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["style", {
        width: 325,
        display: "flex",
        flexDirection: "column",
        margin: ((10 + "px ") + 0) + "px",
    }], (elems_5 = toList(delay(() => append_1(singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Publiser fram til bestemt tidspunkt"], ["control", MuiHelpers_createElement(Checkbox, [["style", {
        color: "#363636",
    }], ["checked", model.PublishUntil != null], ["onChange", (e_4) => {
        let z_3, copyOfStruct_3;
        const _arg_1 = Browser_Types_Event__Event_get_Checked(e_4);
        if (model.PublishUntil != null) {
            dispatch(new Message(10, void 0));
        }
        else {
            dispatch(new Message(10, (z_3 = ((copyOfStruct_3 = now(), addDays(copyOfStruct_3, 7))), create(year(z_3), month(z_3), day(z_3), 0, 0, 0))));
        }
    }]])]])), delay(() => {
        let elems_4, copyOfStruct_4, elems_3, copyOfStruct_5;
        const matchValue_4 = model.PublishUntil;
        if (matchValue_4 != null) {
            const pu = matchValue_4;
            return singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                alignItems: "center",
            }], (elems_4 = [createElement("span", {
                style: {
                    marginRight: 10,
                },
                className: "fas fa-calendar-alt",
            }), MuiHelpers_createElement(TextField, [["type", "date"], ["size", "small"], ["variant", "outlined"], ["value", toString_1(pu, "yyyy-MM-dd")], ["style", {
                maxWidth: 150 + "px",
                marginRight: 10 + "px",
                backgroundColor: "#FFFFFF",
            }], ["onChange", (ev) => {
                const x_3 = ev.target.value;
                try {
                    const y_2 = parse(x_3);
                    dispatch(new Message(10, create(year(y_2), month(y_2), day(y_2), hour(pu), minute(pu), 0)));
                }
                catch (matchValue_5) {
                }
            }]]), createElement("span", {
                style: {
                    marginRight: 10,
                },
                className: "fas fa-clock",
            }), MuiHelpers_createElement(TextField, [["size", "small"], ["variant", "outlined"], ["defaultValue", (copyOfStruct_4 = hour(pu), format('{0:' + "00" + '}', copyOfStruct_4))], ["style", {
                maxWidth: 50 + "px",
                textAlign: "center",
                backgroundColor: "#FFFFFF",
            }], ["inputProps", {
                style: {
                    textAlign: "center",
                },
            }], ["error", !model.ValidUntilHour], ["onChange", (e_5) => {
                const x_4 = Browser_Types_Event__Event_get_Value(e_5);
                try {
                    const asInt_2 = parse_1(x_4, 511, false, 32) | 0;
                    if ((asInt_2 >= 0) && (asInt_2 <= 23)) {
                        dispatch(new Message(10, create(year(pu), month(pu), day(pu), asInt_2, minute(pu), 0)));
                    }
                    else {
                        dispatch(new Message(17, false));
                    }
                }
                catch (matchValue_6) {
                    dispatch(new Message(17, false));
                }
            }]]), createElement("span", createObj(ofArray([["style", {
                marginLeft: 4 + "px",
                marginRight: 4 + "px",
            }], (elems_3 = [createElement("b", {
                children: [":"],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), MuiHelpers_createElement(TextField, [["size", "small"], ["variant", "outlined"], ["defaultValue", (copyOfStruct_5 = minute(pu), format('{0:' + "00" + '}', copyOfStruct_5))], ["style", {
                maxWidth: 50 + "px",
                textAlign: "center",
                backgroundColor: "#FFFFFF",
            }], ["inputProps", {
                style: {
                    textAlign: "center",
                },
            }], ["error", !model.ValidUntilMinute], ["onChange", (e_6) => {
                const x_5 = Browser_Types_Event__Event_get_Value(e_6);
                try {
                    const asInt_3 = parse_1(x_5, 511, false, 32) | 0;
                    if ((asInt_3 >= 0) && (asInt_3 <= 59)) {
                        dispatch(new Message(10, create(year(pu), month(pu), day(pu), hour(pu), asInt_3, 0)));
                    }
                    else {
                        dispatch(new Message(18, false));
                    }
                }
                catch (matchValue_7) {
                    dispatch(new Message(18, false));
                }
            }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
        }
        else {
            return append_1(singleton_1(createElement("br", {})), delay(() => singleton_1(createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                    fontStyle: "italic",
                },
                children: "Artikkelen vil være tilgjengelig helt til den manuelt slettes.",
            }))));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
}

function drawForm(model, dispatch, onCommit) {
    let elems_8, elems_7, elems, elements_1, elements, children, elems_6, elems_5;
    const canPublish = (((((model.Form.Title.trim().length > 0) && (model.Form.Author.trim().length > 0)) && model.ValidAfterHour) && model.ValidAfterMinute) && model.ValidUntilHour) && model.ValidUntilMinute;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 20,
    }], (elems_8 = [createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
        maxWidth: 800,
        display: "flex",
        flexDirection: "column",
    }], (elems_7 = [MuiHelpers_createElement(TextField, [["id", "title-input"], ["defaultValue", model.Form.Title], ["onChange", (e) => {
        dispatch(new Message(1, Browser_Types_Event__Event_get_Value(e)));
    }], ["variant", "outlined"], ["fullWidth", true], ["label", "Tittel"], ["style", {
        marginBottom: 20,
        backgroundColor: "#FFFFFF",
    }]]), MuiHelpers_createElement(TextField, [["id", "author-input"], ["defaultValue", model.Form.Author], ["onChange", (e_1) => {
        dispatch(new Message(3, Browser_Types_Event__Event_get_Value(e_1)));
    }], ["variant", "outlined"], ["fullWidth", true], ["label", "Skrevet av"], ["style", {
        marginBottom: 20,
        backgroundColor: "#FFFFFF",
    }]]), MuiHelpers_createElement(TextField, [["id", "excerpt-input"], ["defaultValue", model.Form.Excerpt], ["onChange", (e_2) => {
        dispatch(new Message(2, Browser_Types_Event__Event_get_Value(e_2)));
    }], ["variant", "outlined"], ["fullWidth", true], ["label", "Kort utdrag"], ["multiline", true], ["style", {
        marginBottom: 20,
        backgroundColor: "#FFFFFF",
    }]]), createElement("div", createObj(ofArray([["style", {
        backgroundColor: "#FFFFFF",
    }], (elems = [quill([new QuillProp(0, (arg_7) => {
        dispatch(new Message(4, arg_7));
    }), new QuillProp(1, model.Form.Body), new QuillProp(2, "snow"), QuillProp_Modules_636F969F([new QuillModule(0, [[{
        header: 1,
    }, {
        header: 2,
    }, {
        header: 3,
    }], [{
        size: ["normal", "small", "large", "huge"],
    }], ["bold", "italic", "underline", "strike"], [{
        list: "ordered",
    }, {
        list: "bullet",
    }], ["code-block"], ["link", "image"]])])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), field([createElement("span", {
        style: {
            fontSize: 0.8 + "rem",
        },
        children: "Publiser til",
    }), MuiHelpers_createElement(FormControl, [["variant", "outlined"], ["fullWidth", true], ["style", {
        maxWidth: 300,
        backgroundColor: "#FFFFFF",
    }], ["size", "small"], (elements_1 = [MuiHelpers_createElement(Select, [["value", model.Form.PublishTo], ["onChange", (e_3, _arg) => {
        dispatch(new Message(8, Browser_Types_Event__Event_get_Value(e_3)));
    }], (elements = map_2((pt) => MuiHelpers_createElement(MenuItem, [["value", PublishTarget__ToInt(pt)], ["children", toString_2(pt)]]), ofArray([new PublishTarget(0), new PublishTarget(1), new PublishTarget(2)])), ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])]), field([MuiHelpers_createElement(FormControlLabel, [["label", "Fest til toppen av feed"], ["control", MuiHelpers_createElement(Checkbox, [["style", {
        color: "#363636",
    }], ["checked", model.Form.Pinned], ["onChange", (e_4) => {
        dispatch(new Message(5, Browser_Types_Event__Event_get_Checked(e_4)));
    }]])]])]), field([MuiHelpers_createElement(FormControlLabel, [["disabled", (model.Form.PublishTo === 3) ? true : model.Updating], ["label", "Send push-varsling"], ["control", MuiHelpers_createElement(Checkbox, [["style", {
        color: "#363636",
    }], ["checked", (model.Form.Push && (model.Form.PublishTo !== 3)) && (!model.Updating)], ["onChange", (e_5) => {
        dispatch(new Message(6, Browser_Types_Event__Event_get_Checked(e_5)));
    }]])]])]), field(toList(delay(() => append_1(singleton_1(MuiHelpers_createElement(FormControlLabel, [["disabled", (model.Form.PublishTo === 3) ? true : model.Updating], ["label", "Send SMS-varsling"], ["control", MuiHelpers_createElement(Checkbox, [["style", {
        color: "#363636",
    }], ["checked", (model.Form.SMS && (model.Form.PublishTo !== 3)) && (!model.Updating)], ["onChange", (e_6) => {
        dispatch(new Message(7, Browser_Types_Event__Event_get_Checked(e_6)));
    }]])]])), delay(() => {
        let elems_3;
        return (!((model.Form.PublishTo === 3) ? true : model.Updating)) ? singleton_1(createElement("span", createObj(singleton((elems_3 = toList(delay(() => append_1(singleton_1("1kr pr SMS. Totalt "), delay(() => {
            let matchValue, mc;
            return append_1((matchValue = model.MemberCount, (matchValue != null) ? ((mc = (matchValue | 0), singleton_1(`${mc}kr`))) : singleton_1(loadingIndicatorSmall())), delay(() => singleton_1(" for denne nyheten")));
        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]))))) : empty_1();
    }))))), field(toList(delay(() => append_1(singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Tillat reaksjoner"], ["disabled", model.Form.PublishTo === 3], ["control", MuiHelpers_createElement(Checkbox, [["style", {
        color: "#363636",
    }], ["checked", model.Form.AllowReactions && (model.Form.PublishTo !== 3)], ["onChange", (e_7) => {
        dispatch(new Message(11, Browser_Types_Event__Event_get_Checked(e_7)));
    }]])]])), delay(() => {
        let elems_4;
        return (model.Form.AllowReactions && (model.Form.PublishTo !== 3)) ? singleton_1(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexWrap: "wrap",
        }], (elems_4 = [map_1((x_1) => MuiHelpers_createElement(Chip, [["style", {
            marginRight: 5,
            backgroundColor: "#FFFFFF",
        }], ["variant", "outlined"], ["avatar", MuiHelpers_createElement(Icon, [["className", toIcon(x_1)], ["style", {
            color: toColor(x_1),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14 + "px",
        }]])], ["label", toName(x_1)], ["onDelete", (_arg_1) => {
            dispatch(new Message(13, x_1));
        }]]), sort(model.Form.AllowedReactions, {
            Compare: comparePrimitives,
        })), createElement(ReactionForm, {
            selectable: toSelectable(model.Form.AllowedReactions),
            dispatch: dispatch,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))) : empty_1();
    }))))), field([MuiHelpers_createElement(FormControlLabel, [["disabled", model.Form.PublishTo === 3], ["label", (children = ofArray(["Krev lesebekreftelse", MuiHelpers_createElement(Tooltip, [["title", "Aktiver for å be brukerne trykke på en bekreftelsesknapp i appen om at innholdet er lest og forstått. (Brukeren må ha lastet ned versjon 4.1.3 eller høyere)."], ["children", createElement("i", {
        style: {
            marginLeft: 10,
        },
        className: "fas fa-question-circle",
    })]])]), createElement("span", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["control", MuiHelpers_createElement(Checkbox, [["style", {
        color: "#363636",
    }], ["checked", model.Form.RequireConfirmation && (model.Form.PublishTo !== 3)], ["onChange", (e_8) => {
        dispatch(new Message(14, Browser_Types_Event__Event_get_Checked(e_8)));
    }]])]])]), drawPublishPeriod(model, dispatch), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        width: 100 + "%",
        margin: ((10 + "px ") + 0) + "px",
    }], (elems_6 = [createElement("button", createObj(ofArray([["disabled", !canPublish], ["style", createObj(toList(delay(() => append_1(singleton_1(["border", "none"]), delay(() => append_1((!canPublish) ? append_1(singleton_1(["opacity", 0.2]), delay(() => singleton_1(["cursor", "not-allowed"]))) : singleton_1(["cursor", "pointer"]), delay(() => append_1(singleton_1(["backgroundColor", "#363636"]), delay(() => append_1(singleton_1(["width", 150]), delay(() => append_1(singleton_1(["color", "#FFFFFF"]), delay(() => append_1(singleton_1(["padding", 10]), delay(() => append_1(singleton_1(["fontSize", 16 + "px"]), delay(() => singleton_1(["borderRadius", 5]))))))))))))))))))], ["onClick", (_arg_2) => {
        if (canPublish) {
            onCommit();
        }
    }], (elems_5 = toList(delay(() => (model.Processing ? singleton_1(loadingIndicatorSmall()) : singleton_1(model.Updating ? "Oppdater" : "Publiser")))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

export function Publish(publishInputProps) {
    let elems_1, elems, props_1;
    const onClose = publishInputProps.onClose;
    const channel = publishInputProps.channel;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(channel.Id, publishInputProps.updating));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchMemberCount(channel.Id, dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems_1 = [snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(21));
    }), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "center",
    }], (elems = [MuiHelpers_createElement(Tooltip, [["title", "Lukk"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", (props_1 = {}, react.createElement(Close, props_1))], ["onClick", (_arg_5) => {
        onClose(void 0);
    }], ["style", {
        marginRight: 10,
    }]])]]), createElement("span", {
        style: {
            fontSize: 1.5 + "rem",
        },
        children: model_1.Updating ? "Oppdater nyhetsartikkel" : (`Publiser ny nyhet til ${channel.Name}`),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), drawForm(model_1, dispatch, () => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let item, inputRecord, body, props, decoder, data_1, caseStrategy_3, extra_3;
            dispatch(new Message(19));
            if ((model_1.Form.Title.trim().length > 0) && (model_1.Form.Author.trim().length > 0)) {
                let after;
                const matchValue = model_1.PublishAfter;
                after = ((matchValue != null) ? toISO8601(matchValue) : (void 0));
                let until;
                const matchValue_1 = model_1.PublishUntil;
                until = ((matchValue_1 != null) ? toISO8601(matchValue_1) : (void 0));
                const requestPath = `/api/news/${model_1.ChannelId}`;
                return ((item = ((inputRecord = model_1.Form, new News_NewsForm(inputRecord.Id, inputRecord.Title, inputRecord.Excerpt, inputRecord.Author, inputRecord.Body, inputRecord.Push, inputRecord.SMS, inputRecord.Published, inputRecord.Pinned, inputRecord.PublishTo, after, until, inputRecord.AllowReactions, inputRecord.AllowedReactions, inputRecord.RequireConfirmation))), (body = Auto_generateBoxedEncoder_Z20B7B430(News_NewsForm$reflection(), void 0, void 0, void 0)(item), (props = props_31(), (decoder = Auto_generateBoxedDecoder_79988AEF(News_NewsMeta$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                    let properties_2, headers_2;
                    try {
                        const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg_1) => {
                            let response_1, decoder_1_1;
                            return ((response_1 = _arg_1, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(News_NewsMeta$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_2) => {
                                let matchValue_2;
                                return Promise.resolve(equals(News_NewsMeta$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue_2 = fromString(uncurry(2, decoder_1_1), _arg_2), (matchValue_2.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue_2.fields[0]))) : (new FSharpResult$2(0, matchValue_2.fields[0])))));
                            })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                        }))));
                        return pr.then(void 0, ((arg_5) => (new FSharpResult$2(1, new FetchError(3, arg_5)))));
                    }
                    catch (exn) {
                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                    }
                })())))))))).then((_arg_3) => {
                    const res = _arg_3;
                    if (res.tag === 1) {
                        const err = res.fields[0];
                        checkFetchError(err);
                        dispatch(new Message(20, (err.tag === 2) ? (((err.fields[0].status) === 404) ? "Fant ikke artikkelen som skal oppdateres" : (((err.fields[0].status) === 413) ? "Innholdet er for stort." : "Kunne ikke publisere nyhet. Vennligst prøv igjen senere.")) : "Kunne ikke publisere nyhet. Vennligst prøv igjen senere."));
                        return Promise.resolve();
                    }
                    else {
                        onClose(res.fields[0]);
                        return Promise.resolve();
                    }
                });
            }
            else {
                return Promise.resolve();
            }
        }));
        pr_1.then();
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function Update(updateInputProps) {
    let elems;
    const onClose = updateInputProps.onClose;
    const channel = updateInputProps.channel;
    const newsId = updateInputProps.newsId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let props, decoder, data_1, caseStrategy_2, extra_2;
            const requestPath = `/api/news/${newsId}`;
            return ((props = props_31(), (decoder = Auto_generateBoxedDecoder_79988AEF(News_NewsEntry$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg_1) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg_1, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(News_NewsEntry$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_2) => {
                            let matchValue;
                            return Promise.resolve(equals(News_NewsEntry$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_2), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))))).then((_arg_3) => {
                const res = _arg_3;
                if (res.tag === 1) {
                    const err = res.fields[0];
                    checkFetchError(err);
                    setErrMsg((err.tag === 2) ? (((err.fields[0].status) === 404) ? "Fant ikke nyhetsartikkel" : "Kunne ikke laste nyhetsartikkel. Vennligst prøv igjen senere.") : "Kunne ikke laste nyhetsartikkel. Vennligst prøv igjen senere.");
                    return Promise.resolve();
                }
                else {
                    const x_1 = res.fields[0];
                    patternInput[1](new News_NewsForm(x_1.Id, x_1.Title, x_1.Excerpt, x_1.Author, x_1.Body, x_1.Push, false, x_1.Published, x_1.Pinned, x_1.PublishTo, x_1.PublishAfter, x_1.PublishUntil, x_1.AllowReactions, x_1.AllowedReactions, x_1.RequireConfirmation));
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
    });
    return createElement("div", createObj(ofArray([["style", {}], (elems = toList(delay(() => append_1(singleton_1(snackError(patternInput_1[0], () => {
        setErrMsg(void 0);
    })), delay(() => {
        const matchValue_1 = patternInput[0];
        if (matchValue_1 != null) {
            const u = matchValue_1;
            return singleton_1(createElement(Publish, {
                updating: u,
                channel: channel,
                onClose: onClose,
            }));
        }
        else {
            return singleton_1(loadingIndicator());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

