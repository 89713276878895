import { toString, Record, Union } from "../fable_modules/fable-library.3.7.22/Types.js";
import { unit_type, equals, obj_type, anonRecord_type, record_type, option_type, string_type, bool_type, union_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { ApiChannelSettings, ApiChannelSettings$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { checkFetchError } from "../Utils.js";
import { useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { uncurry, createObj } from "../fable_modules/fable-library.3.7.22/Util.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { loadingIndicatorSmall, snackError } from "../ViewHelpers.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { cons, empty as empty_1, ofArray } from "../fable_modules/fable-library.3.7.22/List.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { props as props_14 } from "../Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { map, defaultArg, some } from "../fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString as toString_1 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";

export class TestStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotTested", "Successful", "Unauthorized", "InvalidResponse", "ResponseError"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Vellykket";
            }
            case 2: {
                return "Ikke tilgang";
            }
            case 3: {
                return "Ugyldig respons fra server";
            }
            case 4: {
                return "Feil";
            }
            default: {
                return "Ikke testet";
            }
        }
    }
}

export function TestStatus$reflection() {
    return union_type("ApiSettings.TestStatus", [], TestStatus, () => [[], [], [], [], []]);
}

export function TestStatus__ToColorCode(this$) {
    switch (this$.tag) {
        case 1: {
            return "green";
        }
        case 2: {
            return "yellow";
        }
        case 3: {
            return "yellow";
        }
        case 4: {
            return "crimson";
        }
        default: {
            return "#ddd";
        }
    }
}

class Model extends Record {
    constructor(Form, ValidUrl, ValidToken, Testing, Status, ErrorMsg) {
        super();
        this.Form = Form;
        this.ValidUrl = ValidUrl;
        this.ValidToken = ValidToken;
        this.Testing = Testing;
        this.Status = Status;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("ApiSettings.Model", [], Model, () => [["Form", ApiChannelSettings$reflection()], ["ValidUrl", bool_type], ["ValidToken", bool_type], ["Testing", bool_type], ["Status", TestStatus$reflection()], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateUrl", "ToggleAuth", "UpdateToken", "ToggleTesting", "TestResponse", "SetErorr", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ApiSettings.Message", [], Message, () => [[["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [anonRecord_type(["Status", string_type]), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", anonRecord_type(["Status", string_type])]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], []]);
}

function init(updating) {
    return new Model((updating == null) ? (new ApiChannelSettings("", true, "")) : updating, true, true, false, new TestStatus(0), void 0);
}

function validateUrl(x) {
    if ((x.indexOf("http://") === 0) ? true : (x.indexOf("https://") === 0)) {
        return true;
    }
    else {
        return x.trim().length > 8;
    }
}

function update(model, msg) {
    let inputRecord_1, inputRecord_2, matchValue, inputRecord;
    switch (msg.tag) {
        case 1: {
            const x_1 = msg.fields[0];
            return new Model((inputRecord_1 = model.Form, new ApiChannelSettings(inputRecord_1.Url, x_1, inputRecord_1.AuthToken)), model.ValidUrl, x_1 ? model.ValidToken : true, model.Testing, model.Status, model.ErrorMsg);
        }
        case 2: {
            const x_2 = msg.fields[0];
            return new Model((inputRecord_2 = model.Form, new ApiChannelSettings(inputRecord_2.Url, inputRecord_2.UseAuth, x_2)), model.ValidUrl, x_2.trim().length > 0, model.Testing, model.Status, model.ErrorMsg);
        }
        case 3: {
            return new Model(model.Form, model.ValidUrl, model.ValidToken, msg.fields[0], model.Status, model.ErrorMsg);
        }
        case 4: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                checkFetchError(res.fields[0]);
                return new Model(model.Form, model.ValidUrl, model.ValidToken, false, model.Status, "Kunne ikke gjennomføre test. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.Form, model.ValidUrl, model.ValidToken, false, (matchValue = res.fields[0].Status.toLocaleLowerCase(), (matchValue === "success") ? (new TestStatus(1)) : ((matchValue === "unauthorized") ? (new TestStatus(2)) : ((matchValue === "response") ? (new TestStatus(3)) : (new TestStatus(4))))), model.ErrorMsg);
            }
        }
        case 5: {
            return new Model(model.Form, model.ValidUrl, model.ValidToken, model.Testing, model.Status, msg.fields[0]);
        }
        case 6: {
            return new Model(model.Form, model.ValidUrl, model.ValidToken, model.Testing, model.Status, void 0);
        }
        default: {
            const x = msg.fields[0];
            return new Model((inputRecord = model.Form, new ApiChannelSettings(x, inputRecord.UseAuth, inputRecord.AuthToken)), validateUrl(x), model.ValidToken, model.Testing, model.Status, model.ErrorMsg);
        }
    }
}

export function Settings(settingsInputProps) {
    let elems_6;
    const onSettings = settingsInputProps.onSettings;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(settingsInputProps.updating));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const fieldHolder = (children) => createElement("div", {
        style: {
            width: 100 + "%",
            maxWidth: 250,
            marginBottom: 15,
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        width: 100 + "%",
        maxWidth: 250,
        textAlign: "center",
        marginBottom: 20,
    }], (elems_6 = toList(delay(() => append(singleton(snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(6));
    })), delay(() => append(singleton(createElement("span", {
        style: {},
        children: "API-innstillinger",
    })), delay(() => append(singleton(fieldHolder([MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["defaultValue", model_1.Form.Url]), delay(() => append(singleton(["onChange", (e) => {
        dispatch(new Message(0, Browser_Types_Event__Event_get_Value(e)));
    }]), delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["label", "URL"]), delay(() => append(singleton(["error", !model_1.ValidUrl]), delay(() => append((!model_1.ValidUrl) ? singleton(["helperText", "Url må starte med https:// eller http://"]) : empty(), delay(() => singleton(["fullWidth", true]))))))))))))))))])), delay(() => {
        let elems_1;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            height: 56,
            maxWidth: 250,
            marginBottom: 15,
            display: "flex",
        }], (elems_1 = [MuiHelpers_createElement(FormControlLabel, [["label", "Krever autentisering"], ["control", MuiHelpers_createElement(Checkbox, [["checked", model_1.Form.UseAuth], ["onChange", (e_1) => {
            dispatch(new Message(1, Browser_Types_Event__Event_get_Checked(e_1)));
        }], ["style", {
            color: "#363636",
        }]])]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => append(model_1.Form.UseAuth ? singleton(fieldHolder([MuiHelpers_createElement(TextField, [["defaultValue", model_1.Form.AuthToken], ["onChange", (e_2) => {
            dispatch(new Message(2, Browser_Types_Event__Event_get_Value(e_2)));
        }], ["variant", "outlined"], ["label", "Authorization"], ["error", !model_1.ValidToken], ["helperText", "Authorization HTTP-header"], ["fullWidth", true]])])) : empty(), delay(() => {
            let elems_3, elems_2;
            return append(singleton(createElement("div", createObj(ofArray([["style", {
                marginBottom: 10,
            }], (elems_3 = [createElement("span", createObj(ofArray([["style", {}], (elems_2 = [createElement("span", {
                style: {
                    color: TestStatus__ToColorCode(model_1.Status),
                    marginRight: 5,
                },
                className: "fas fa-circle",
            }), toString(model_1.Status)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                let elems_5, elems_4;
                return singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                }], (elems_5 = [createElement("button", createObj(ofArray([["style", createObj(toList(delay(() => append(singleton(["border", "none"]), delay(() => append(singleton(["backgroundColor", "#363636"]), delay(() => append(singleton(["width", 150]), delay(() => append(singleton(["color", "#FFFFFF"]), delay(() => append(singleton(["padding", 10]), delay(() => append(singleton(["fontSize", 16 + "px"]), delay(() => append((validateUrl(model_1.Form.Url) && ((model_1.Form.AuthToken.trim().length > 0) ? true : (!model_1.Form.UseAuth))) ? singleton(["cursor", "pointer"]) : singleton(["cursor", "not-allowed"]), delay(() => singleton(["borderRadius", 5]))))))))))))))))))], ["onClick", (_arg_4) => {
                    if (validateUrl(model_1.Form.Url) && ((!model_1.Form.UseAuth) ? true : (model_1.Form.AuthToken.trim().length > 0))) {
                        const dispatch_1 = dispatch;
                        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            let body, props, decoder, data_1, caseStrategy_3, extra_3;
                            dispatch_1(new Message(3, true));
                            return ((body = Auto_generateBoxedEncoder_Z20B7B430(ApiChannelSettings$reflection(), void 0, void 0, void 0)(model_1.Form), (props = props_14(), (decoder = Auto_generateBoxedDecoder_79988AEF(anonRecord_type(["Status", string_type]), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                                let properties_2, headers_2;
                                try {
                                    const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty_1()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/channel/api-test", properties_3).then((_arg) => {
                                        let response_1, decoder_1_1;
                                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(anonRecord_type(["Status", string_type]), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                            let matchValue;
                                            return Promise.resolve(equals(anonRecord_type(["Status", string_type]), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                                    }))));
                                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                                }
                                catch (exn) {
                                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                                }
                            })()))))))).then((_arg_2) => {
                                const res = _arg_2;
                                dispatch_1(new Message(4, res));
                                let pattern_matching_result;
                                if (res.tag === 0) {
                                    if (res.fields[0].Status === "success") {
                                        pattern_matching_result = 0;
                                    }
                                    else {
                                        pattern_matching_result = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result = 1;
                                }
                                switch (pattern_matching_result) {
                                    case 0: {
                                        onSettings(model_1.Form);
                                        return Promise.resolve();
                                    }
                                    case 1: {
                                        return Promise.resolve();
                                    }
                                }
                            });
                        }));
                        pr_1.then();
                    }
                }], (elems_4 = toList(delay(() => (model_1.Testing ? singleton(loadingIndicatorSmall()) : singleton("Test")))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))));
            }));
        }))));
    })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
}

