import { Union } from "../fable_modules/fable-library.3.7.22/Types.js";
import { union_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";

export class ChannelAccess extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Closed", "Open"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "Åpen kanal" : "Lukket kanal";
    }
}

export function ChannelAccess$reflection() {
    return union_type("Shared.ChannelAccess", [], ChannelAccess, () => [[], []]);
}

export function ChannelAccess__ToInt(this$) {
    if (this$.tag === 1) {
        return 0;
    }
    else {
        return 1;
    }
}

export function ChannelAccess_FromInt_Z524259A4(x) {
    if (x === 0) {
        return new ChannelAccess(1);
    }
    else {
        return new ChannelAccess(0);
    }
}

export class ChannelSource extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["MobileNews", "Sharepoint", "API", "ExternalMobileNews"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "SharePoint";
            }
            case 2: {
                return "API";
            }
            case 3: {
                return "Ekstern Mobile News";
            }
            default: {
                return "Mobile News";
            }
        }
    }
}

export function ChannelSource$reflection() {
    return union_type("Shared.ChannelSource", [], ChannelSource, () => [[], [], [], []]);
}

export function ChannelSource__ToInt(this$) {
    switch (this$.tag) {
        case 1: {
            return 2;
        }
        case 2: {
            return 3;
        }
        case 3: {
            return 4;
        }
        default: {
            return 1;
        }
    }
}

export function ChannelSource_FromInt_Z524259A4(x) {
    switch (x) {
        case 2: {
            return new ChannelSource(1);
        }
        case 3: {
            return new ChannelSource(2);
        }
        case 4: {
            return new ChannelSource(3);
        }
        default: {
            return new ChannelSource(0);
        }
    }
}

export class PublishTarget extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Both", "AppOnly", "ScreenOnly"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Kun app";
            }
            case 2: {
                return "Kun infoskjerm";
            }
            default: {
                return "App og infoskjerm";
            }
        }
    }
}

export function PublishTarget$reflection() {
    return union_type("Shared.PublishTarget", [], PublishTarget, () => [[], [], []]);
}

export function PublishTarget__ToInt(this$) {
    switch (this$.tag) {
        case 1: {
            return 2;
        }
        case 2: {
            return 3;
        }
        default: {
            return 1;
        }
    }
}

export function PublishTarget_FromInt_Z524259A4(x) {
    switch (x) {
        case 2: {
            return new PublishTarget(1);
        }
        case 3: {
            return new PublishTarget(2);
        }
        default: {
            return new PublishTarget(0);
        }
    }
}

