import { Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { UserSession$reflection } from "./Shared/ApiDataTypes.js";
import { record_type, bool_type, option_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import * as react from "react";

export class GlobalCtx extends Record {
    constructor(Session, IsBig) {
        super();
        this.Session = Session;
        this.IsBig = IsBig;
    }
}

export function GlobalCtx$reflection() {
    return record_type("Context.GlobalCtx", [], GlobalCtx, () => [["Session", option_type(UserSession$reflection())], ["IsBig", bool_type]]);
}

export function GlobalCtx_New(session, isBig) {
    return new GlobalCtx(session, isBig);
}

export const globalCtx = (() => {
    const defaultValue = GlobalCtx_New(void 0, true);
    return react.createContext(defaultValue);
})();

