import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.22/String.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { props as props_18 } from "../Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { SharePoint_ReactionConfig, GenericMessage$reflection, SharePoint_ReactionConfig$reflection } from "../Shared/ApiDataTypes.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { unit_type, equals, obj_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { numberHash, comparePrimitives, createObj, uncurry } from "../fable_modules/fable-library.3.7.22/Util.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { checkFetchError } from "../Utils.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { loadingIndicator, snackError } from "../ViewHelpers.js";
import { createElement } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { append as append_1, sort, map as map_1 } from "../fable_modules/fable-library.3.7.22/Array.js";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import ListItemText from "@material-ui/core/ListItemText";
import { Array_distinct } from "../fable_modules/fable-library.3.7.22/Seq2.js";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";

export function toIcon(x) {
    switch (x) {
        case 1: {
            return "fas fa-thumbs-up";
        }
        case 2: {
            return "fas fa-thumbs-down";
        }
        case 3: {
            return "fas fa-heart";
        }
        case 4: {
            return "fas fa-star";
        }
        default: {
            return "";
        }
    }
}

export function toColor(x) {
    switch (x) {
        case 1: {
            return "dodgerblue";
        }
        case 2: {
            return "orange";
        }
        case 3: {
            return "red";
        }
        case 4: {
            return "gold";
        }
        default: {
            return "#aaa";
        }
    }
}

export function toName(x) {
    switch (x) {
        case 1: {
            return "Tommel opp";
        }
        case 2: {
            return "Tommel ned";
        }
        case 3: {
            return "Hjerte";
        }
        case 4: {
            return "Stjerne";
        }
        default: {
            return "";
        }
    }
}

export function toSelectable(x) {
    const array_1 = new Int32Array([1, 2, 3, 4]);
    return array_1.filter((r) => (!x.some((y) => (r === y))));
}

export const selectable = new Int32Array([1, 2, 3, 4]);

function fetchConfig(channelId, callback) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = toText(printf("/api/channel/sharepoint-reactions/%i"))(channelId);
        return ((props = props_18(), (decoder = Auto_generateBoxedDecoder_79988AEF(SharePoint_ReactionConfig$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(SharePoint_ReactionConfig$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(SharePoint_ReactionConfig$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            callback(_arg_2);
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

export function Settings(settingsInputProps) {
    let elements_3;
    const channelId = settingsInputProps.channelId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setConfig = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    const anchor = useReact_useRef_1505(void 0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setOpen = patternInput_2[1];
    const isOpen = patternInput_2[0];
    const updateConfig = (x) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let body, props, decoder, data_1, caseStrategy_3, extra_3;
            const requestPath = toText(printf("/api/channel/sharepoint-reactions/%i"))(channelId);
            return ((body = Auto_generateBoxedEncoder_Z20B7B430(SharePoint_ReactionConfig$reflection(), void 0, void 0, void 0)(x), (props = props_18(), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericMessage$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericMessage$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(GenericMessage$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })()))))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 1) {
                    checkFetchError(res.fields[0]);
                    setErrMsg("Kunne ikke oppdatere reaksjonsinnstillinger. Vennligst prøv igjen senere.");
                    return Promise.resolve();
                }
                else if (res.fields[0].result === "success") {
                    setConfig(x);
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
    };
    useReact_useEffectOnce_3A5B6456(() => {
        fetchConfig(channelId, (res_1) => {
            if (res_1.tag === 1) {
                checkFetchError(res_1.fields[0]);
                setErrMsg("Kunne ikke laste SharePoint-innstillinger. Vennligst prøv igjen senere.");
            }
            else {
                setConfig(res_1.fields[0]);
            }
        });
    });
    return MuiHelpers_createElement(Paper, [["style", {
        padding: 10,
        display: "flex",
        flexDirection: "column",
        margin: 10,
        width: 100 + "%",
        maxWidth: 600,
    }], ["elevation", 1], (elements_3 = toList(delay(() => append(singleton(snackError(patternInput_1[0], () => {
        setErrMsg(void 0);
    })), delay(() => append(singleton(createElement("span", {
        style: {
            fontSize: 1.2 + "rem",
        },
        children: "Reaksjoninnstillinger for nyheter",
    })), delay(() => append(singleton(createElement("span", {
        children: "Siden nyheter for denne kanalen hentes automatisk fra SharePoint, kan du sette standard reaksjonsregler for nye nyheter.",
    })), delay(() => {
        let elems_4;
        const matchValue_1 = patternInput[0];
        if (matchValue_1 != null) {
            const config_1 = matchValue_1;
            return singleton(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexDirection: "column",
                margin: ((10 + "px ") + 0) + "px",
            }], (elems_4 = toList(delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Automatisk tillat reaksjoner for nye nyheter"], ["control", MuiHelpers_createElement(Checkbox, [["style", {
                color: "#363636",
            }], ["checked", config_1.AllowByDefault], ["onChange", (e) => {
                updateConfig(new SharePoint_ReactionConfig(Browser_Types_Event__Event_get_Checked(e), config_1.Default));
            }]])]])), delay(() => (config_1.AllowByDefault ? append(singleton(createElement("span", {
                style: {
                    marginBottom: 10,
                },
                children: "Velg hvilke reaksjoner som automatisk skal tillates:",
            })), delay(() => {
                let elems_3;
                return singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                }], (elems_3 = toList(delay(() => append(singleton(map_1((r_1) => MuiHelpers_createElement(Chip, [["style", {
                    margin: 5,
                }], ["variant", "outlined"], ["avatar", MuiHelpers_createElement(Icon, [["className", toIcon(r_1)], ["style", {
                    color: toColor(r_1),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 14 + "px",
                }]])], ["label", toName(r_1)], ["onDelete", (_arg_5) => {
                    const cfg = new SharePoint_ReactionConfig(config_1.AllowByDefault, config_1.Default.filter((y_1) => (r_1 !== y_1)));
                    updateConfig(cfg);
                    setConfig(cfg);
                }]]), sort(config_1.Default, {
                    Compare: comparePrimitives,
                }))), delay(() => {
                    let children_1;
                    const menuOptions = map_1((x_5) => {
                        let elements_1, children;
                        return MuiHelpers_createElement(MenuItem, [(elements_1 = [(children = singleton_1(MuiHelpers_createElement(Icon, [["fontSize", "small"], ["style", {
                            color: toColor(x_5),
                        }], ["className", toIcon(x_5)]])), MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), MuiHelpers_createElement(ListItemText, [["children", toName(x_5)]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))]), ["onClick", (_arg_6) => {
                            const cfg_1 = new SharePoint_ReactionConfig(config_1.AllowByDefault, Array_distinct(append_1(new Int32Array([x_5]), config_1.Default, Int32Array), {
                                Equals: (x_6, y_3) => (x_6 === y_3),
                                GetHashCode: numberHash,
                            }));
                            updateConfig(cfg_1);
                            setConfig(cfg_1);
                            setOpen(false);
                        }]]);
                    }, selectable.filter((r_2) => (!config_1.Default.some((y_2) => (r_2 === y_2)))));
                    return singleton((children_1 = toList(delay(() => ((menuOptions.length > 0) ? append(singleton(MuiHelpers_createElement(IconButton, [["aria-label", "Legg til"], ["ref", anchor], ["onClick", (_arg_7) => {
                        setOpen(!isOpen);
                    }], ["size", "small"], ["children", createElement("i", {
                        className: "fas fa-plus",
                    })]])), delay(() => singleton(MuiHelpers_createElement(Menu, [["open", isOpen], ["onClose", (_arg_9, v) => {
                        setOpen(false);
                    }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray(Array.from(menuOptions))]])))) : empty_1()))), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                    })));
                })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
            })) : empty_1()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
        }
        else {
            return singleton(loadingIndicator());
        }
    })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])]);
}

