import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { Billing_Invoice$reflection } from "./Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, string_type, option_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { checkFetchError } from "./Utils.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { props as props_16 } from "./Promises.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { map, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Billing_GetBillingInfo } from "./Shared/Urls.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { int32ToString, createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { toString as toString_1, daysInMonth, month, year, create, now as now_1 } from "./fable_modules/fable-library.3.7.22/Date.js";
import { format, printf, toText } from "./fable_modules/fable-library.3.7.22/String.js";
import { map as map_1 } from "./fable_modules/fable-library.3.7.22/Array.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { loadingIndicatorLarge, snackError } from "./ViewHelpers.js";

class Model extends Record {
    constructor(CurrentMonth, ErrorMsg) {
        super();
        this.CurrentMonth = CurrentMonth;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Billing.Model", [], Model, () => [["CurrentMonth", option_type(Billing_Invoice$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InvoiceResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Billing.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Billing_Invoice$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Billing_Invoice$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init() {
    return new Model(void 0, void 0);
}

function update(model, msg) {
    if (msg.tag === 1) {
        return new Model(model.CurrentMonth, void 0);
    }
    else {
        const res = msg.fields[0];
        if (res.tag === 1) {
            checkFetchError(res.fields[0]);
            return new Model(model.CurrentMonth, "Kunne ikke laste faktura. Vennligst prøv igjen senere.");
        }
        else {
            return new Model(res.fields[0], model.ErrorMsg);
        }
    }
}

function fetchInvoice(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let props, decoder, data_1, caseStrategy_2, extra_2;
        return ((props = props_16(), (decoder = Auto_generateBoxedDecoder_79988AEF(Billing_Invoice$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(props, (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(Billing_GetBillingInfo, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Billing_Invoice$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(Billing_Invoice$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function drawDemoView() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#FFFFFF",
        padding: 20,
        borderRadius: 5,
        width: 100 + "%",
        maxWidth: 500,
    }], (elems = [createElement("span", {
        style: {
            fontSize: 1.5 + "rem",
            marginBottom: 30,
        },
        children: "Du har ett demo abonnement",
    }), createElement("span", {
        children: "Du vil ikke bli fakturert for denne måneden.",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function drawInvoice(x) {
    let elems_1, arg_1, arg, elems, children_2, children, children_10, children_6, children_8;
    const th$0027 = (x_1) => createElement("th", {
        style: {
            padding: 8,
            borderBottom: (((2 + "px ") + "solid") + " ") + "#363636",
            textAlign: "center",
        },
        children: x_1,
    });
    const td$0027 = (x_2, alignRight) => createElement("td", {
        style: createObj(toList(delay(() => append(singleton(["padding", 8]), delay(() => (alignRight ? singleton(["textAlign", "right"]) : empty_1())))))),
        children: x_2,
    });
    const now = now_1();
    const firstDayOfMonth = create(year(now), month(now), 1);
    let lastDay;
    const numDays = daysInMonth(year(now), month(now)) | 0;
    lastDay = create(year(now), month(now), numDays);
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        padding: 20,
        borderRadius: 5,
    }], (elems_1 = [createElement("span", {
        style: {
            fontSize: 1.5 + "rem",
            marginBottom: 20,
        },
        children: "Estimert faktura for denne perioden",
    }), createElement("span", {
        children: (arg_1 = toString_1(lastDay, "dd/MM/yyyy"), (arg = toString_1(firstDayOfMonth, "dd/MM/yyyy"), toText(printf("%s - %s"))(arg)(arg_1))),
    }), createElement("table", createObj(ofArray([["style", {}], (elems = [(children_2 = singleton_1((children = ofArray([th$0027("Antall"), th$0027("Detaljer"), th$0027("Enhetspris"), th$0027("Linjetotal"), th$0027("")]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), (children_10 = ofArray([map_1((l) => {
        const children_4 = ofArray([td$0027(int32ToString(l.Quantity), true), td$0027(l.Description, false), td$0027(format('{0:' + "0.00" + '}', l.UnitCost), true), td$0027(format('{0:' + "0.00" + '}', l.LineTotal), true), td$0027("NOK", false)]);
        return createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        });
    }, x.Lines), (children_6 = singleton_1(createElement("td", {
        colSpan: 5,
        style: {
            borderBottom: (((2 + "px ") + "solid") + " ") + "#363636",
        },
    })), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    })), (children_8 = ofArray([createElement("td", {
        colSpan: 3,
        style: {
            fontWeight: "bold",
            padding: ((((((15 + "px ") + 8) + "px ") + 8) + "px ") + 8) + "px",
        },
        children: "Total",
    }), createElement("td", {
        style: {
            fontWeight: "bold",
            padding: ((((((15 + "px ") + 8) + "px ") + 8) + "px ") + 8) + "px",
            textAlign: "right",
        },
        children: format('{0:' + "0.00" + '}', x.Total),
    }), createElement("td", {
        style: {
            padding: ((((((15 + "px ") + 8) + "px ") + 8) + "px ") + 8) + "px",
        },
        children: "NOK",
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
    }))]), createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function Billing() {
    let elems;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchInvoice(dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
    }], (elems = toList(delay(() => append(singleton(snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(1));
    })), delay(() => {
        const matchValue = model_1.CurrentMonth;
        if (matchValue != null) {
            const i = matchValue;
            return i.IsDemo ? singleton(drawDemoView()) : singleton(drawInvoice(i));
        }
        else {
            return singleton(loadingIndicatorLarge());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

