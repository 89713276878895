import { createElement } from "react";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Typography from "@material-ui/core/Typography";
import { createObj } from "./fable_modules/fable-library.3.7.22/Util.js";
import { ofArray } from "./fable_modules/fable-library.3.7.22/List.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";

function spanBold(x) {
    return createElement("span", {
        style: {
            fontWeight: "bold",
        },
        children: x,
    });
}

export function channelInfo() {
    let elems, children_8, children, children_2, children_4, children_6;
    const children_10 = ofArray([MuiHelpers_createElement(Typography, [["variant", "h5"], ["children", "Sett opp en kanal"]]), createElement("p", {
        children: ["Nyheter og informasjon deles gjennom «kanaler». Kanaler gir deg en enkel måte å fordele forskjellige nyheter til forskjellige grupper publikum. Ofte ønskes det at enkelte nyheter og informasjon kun skal være tilgjengelig for enkelte ansatte, da passer det bra å sette opp en egen kanal for denne målgruppen.  All distribusjon av nyheter og informasjon skjer gjennom en eller ﬂere kanaler, du må derfor sette opp minst en kanal for å komme i gang."],
    }), createElement("ul", createObj(ofArray([["style", {
        listStyleType: "disc",
    }], (elems = [createElement("li", {
        children: ["Naviger til Kanaler under menyen på siden."],
    }), createElement("li", {
        children: ["Trykk på knappen «Legg til kanal» og fyll inn informasjon"],
    }), (children_8 = ofArray([(children = ofArray([spanBold("Kanalnavn: "), createElement("span", {
        children: ["Dette er navnet på din nyhetskanal. Dette vises på ansattes telefoner når nye innlegg publiseres. Du kan kalle en kanal hva enn du vil, men for ordens skyld anbefaler vi å bruke ett navn som best beskriver den gruppen med ansatte."],
    })]), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), (children_2 = ofArray([spanBold("Kanalkode: "), createElement("span", {
        children: ["Dette er en unik kode som identiﬁserer din Enterprise Mobile News kanal blant alle kanaler som ﬁnnes der ute. Hvis du setter opp en åpen kanal er dette koden de ansatte må fylle inn i sin Enterprise Mobile News app for å få tilgang. For lukkede kanaler hentes denne ut automatisk og de ansatte trenger kun registrere telefonnummeret sitt i appen. Dersom de derimot ikke ønsker å registrere mobilnummeret må de legge til kanalen manuelt med å bruke denne koden."],
    })]), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = ofArray([spanBold("Tilgang: "), createElement("span", {
        children: ["Du kan velge mellom åpne og lukkede kanaler. Åpne kanaler lar hvem som helst hente ut nyheter og informasjon så lenge de vet kanalkoden. Lukkede kanaler gir kun tilgang til ansatte som aktivt er gitt tilgang til kanalen. Vi anbefaler lukkede kanaler for de ﬂeste nyheter som publiseres internt i bedrift, eller som kun er rettet mot ett vist antall ansatte."],
    })]), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    })), (children_6 = ofArray([spanBold("Nyhetskilde: "), createElement("span", {
        children: ["Spesiﬁserer hvor du skal hente nyheter fra. MobileNews er standard som kommer med Enterprise Mobile News tjenesten. Du kan også hente nyheter fra SharePoint, for å gjøre det må du gi Enterprise Mobile News tilgang til å lese fra din SharePoint instans."],
    })]), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    }))]), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
    })), createElement("li", {
        children: ["Når du har fylt ut alle feltene og trykket på «Legg til» vil kanalen dukke opp i listen under. Her har du mulighet til å slette kanaler eller redigere hvem som skal ha tilgang (merk du må legge til ansatte/medlemmer før du kan gi de tilgang)"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function memberInfo() {
    let elems, children;
    const children_2 = ofArray([MuiHelpers_createElement(Typography, [["variant", "h5"], ["children", "Legg til ansatte/medlemmer"]]), createElement("ul", createObj(ofArray([["style", {
        listStyleType: "disc",
    }], (elems = [createElement("li", {
        children: ["Naviger til Medlemmer under menyen på siden"],
    }), createElement("li", {
        children: ["For å legge til et enkelt medlem trykket du på knappen «Legg til medlem» og fyller inn informasjonen der. Merk at det kun er telefonnummer som er påkrevd, men vi anbefaler å fylle ut ekstra informasjon for oversiktlighet."],
    }), createElement("li", {
        children: ["Om du nå har satt opp en kanal har du muligheten til å gi denne ansatte tilgang til kanalen ved å velge kanalen i menyen her ved å krysse denne av. Om du ønsker å legge til denne ansatte i ﬂere kanaler senere eller fjerne tilgangen ﬁnner du din kanal under «Kanaler» menyen og trykker «Tilgang»"],
    }), (children = ofArray([spanBold("Merk: "), createElement("span", {
        children: ["Selv om ansatte/medlemmer er lagt til i denne listen betyr det ikke nødvendigvis at de har fått tilgang til alle kanalene enda."],
    })]), createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

export function accessInfo() {
    let elems;
    const children = ofArray([MuiHelpers_createElement(Typography, [["variant", "h5"], ["children", "Gi ansatte/medlemmer tilgang til nyheter/informasjon"]]), createElement("p", {
        children: ["For lukkede kanaler behøver ansatte/medlemmer å bli gitt tilgang før de kan lese nyhetene som er publisert. For åpne kanaler kan hvem som helst legge til kanalkoden i appen og hente ut nyheter"],
    }), createElement("br", {}), createElement("ul", createObj(ofArray([["style", {
        listStyleType: "disc",
    }], (elems = [createElement("li", {
        children: ["For å gi tilgang til en lukket kanal trykk på «Kanaler» i menyen på siden. Finn kanalen du ønsker å gi den/de ansatte tilgang til i listen over kanaler og trykk på «Tilgang»-knappen for den gitte kanalen."],
    }), createElement("li", {
        children: ["Her får du to kolonner, en for ansatte/medlemmer uten tilgang og en for de med tilgang. Å endre en ansatt/medlems tilgang til en kanal er så enkelt som å trykke på pilen ved siden av navnet. De vil da ﬂyttes over til den andre kolonnen."],
    }), createElement("li", {
        children: ["Merk at det også er noen hjelpe-knapper øverst som lar deg legge til og fjerne alle ansatte fra tilgangslisten"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function publishInfo() {
    let elems;
    const children = ofArray([MuiHelpers_createElement(Typography, [["variant", "h5"], ["children", "Publiser ett innlegg til en kanal"]]), createElement("br", {}), createElement("ul", createObj(ofArray([["style", {
        listStyleType: "disc",
    }], (elems = [createElement("li", {
        children: ["Trykk på «Kanaler» i menyen på siden og finn kanalen du ønsker å publisere til i listen over kanaler. Trykk på \"nyheter\"-knappen for å åpne nyhetslisten."],
    }), createElement("li", {
        children: ["Merk at øverst i venstre hjørne står navnet på kanalen du håndterer nyheter for."],
    }), createElement("li", {
        children: ["Trykk på «Ny nyhet»-knappen og fyll inn informasjon om nyheten du vil publisere. Når du er ferdig trykker du på «Publiser» og så vil nyheten være tilgjengelig på alle ansatte/ medlemmers telefoner, så lenge de er blitt gitt tilgang til kanalen."],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function reportInfo() {
    const children = ofArray([MuiHelpers_createElement(Typography, [["variant", "h5"], ["children", "Få leserapport og send purringer til ansatte/ medlemmer som ikke har lest"]]), createElement("br", {}), createElement("p", {
        children: ["Etterhvert som de ansatte/medlemmene leser nyheten du har publisert vil Enterprise Mobile News plukke dette opp og presentere det for deg. Du kan se hvor mange som har lest hver nyhet ved å gå til «Rapport» under menyen på siden. Her kan du filtrere nyhetene basert på kanal. Du kan også trykke på en enkel nyhet for å få opp flere valg. Her kan du sende push-varslinger til de ansatte/ medlemmers telefoner for å minne de på at de må lese informasjonen som er blitt publisert. Merk at push-varslinger kan ha blitt deaktivert av den ansatte på deres telefon. Du kan også sende de en SMS med en påminnelse. Dette har et lite gebyr per SMS ettersom det koster penger å få sendt ut SMS."],
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function memberHowToInfo() {
    const children = ofArray([MuiHelpers_createElement(Typography, [["variant", "h5"], ["children", "Hva de ansatte må gjøre selv"]]), createElement("br", {}), createElement("p", {
        children: ["De ansatte trenger kun å laste ned Enterprise Mobile News appen på iOS eller Android. Hvis de fyller inn telefonnummeret sitt i appen vil de automatisk få hentet ut kanaler og nyheter hvor de har fått tilgang. Hvis de velger å ikke registrere telefonnummeret sitt i appen må de legge til kanalene manuelt ved å bruke kanalkoden. Dette kan gjøres under Innstillinger -\u003e Legg til kanal manuelt."],
    }), createElement("br", {}), spanBold("Merk: "), createElement("p", {
        children: ["For lukkede kanaler krever det at de er registrert som medlem og har blitt tildelt tilgang til den gitte kanalen."],
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function infoscreenInfo() {
    let elems;
    const children = ofArray([MuiHelpers_createElement(Typography, [["variant", "h5"], ["children", "Sett opp en infotavle"]]), createElement("br", {}), createElement("p", {
        children: ["Du kan sette opp infotavler for å vise nyheter på skjermer, f.eks rundt om i bedriften lokaler."],
    }), createElement("br", {}), createElement("ul", createObj(ofArray([["style", {
        listStyleType: "disc",
    }], (elems = [createElement("li", {
        children: ["For å opprette en infotavle trykker du på «Infoskjermer» i menyen på siden."],
    }), createElement("li", {
        children: ["Trykk deretter på «+»-knappen og fyll inn ett navn på tavlen."],
    }), createElement("li", {
        children: ["Når tavlen er blitt opprettet finner du den i listen over infotavler under."],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("br", {}), spanBold("Merk: "), createElement("p", {
        children: ["Du har nå opprettet en tom infoskjerm, du må fylle inn innholdet du ønsker at den skal vise. Dette kan du gjøre gjennom infoskjerm-designer verktøyet."],
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function designerInfo() {
    const children = ofArray([MuiHelpers_createElement(Typography, [["variant", "h5"], ["children", "Rediger infoskjerm"]]), createElement("br", {}), createElement("span", {
        children: ["For å fylle infoskjermen din med innhold må du redigere denne slik du ønsker at det skal presenteres. Finn infoskjermen du ønsker å redigere i listen over infoskjermer, og trykk på «Rediger», du vil nå åpne redigeringsverktøyet for infotavler. Rutenettet representerer infotavlen din, ha i tankene at dette blir å fylle hele skjermen når du tar den i bruk."],
    }), createElement("br", {}), createElement("p", {
        children: ["For å legge til noe på infoskjermen velger du markeringsverktøy i menyen til høyre og deretter klikk, dra og slipp i rutenettet for å markere opp seksjonen av skjermen som skal brukes. Markeringsverktøyet gir deg 4 forskjellige alternativ:"],
    }), createElement("br", {}), spanBold("Mobile News: "), createElement("p", {
        children: ["Dette markerer opp en del av infoskjermen som skal vise nyheter fra en av dine Enterprise Mobile News kanaler. Når du har markert opp kan du velge hvilken kanal det skal hentes nyheter fra ved å klikke på «panelet» i rutenett-området, dette bringer opp en meny nederst på siden. Klikk på kanal og velg kanalen(e) nyhetene skal hentes fra."],
    }), createElement("br", {}), spanBold("Vær: "), createElement("p", {
        children: ["Du kan sette ett område av infoskjermen til å vise været. Klikk i rutenettet, dra og slipp for å markere området. Trykk deretter på «panelet» som dukket opp og du vil få opp en rekke alternativ nederst på skjermen. Du kan blant annet velge sted og antall dager fram i tid."],
    }), createElement("br", {}), spanBold("RSS: "), createElement("p", {
        children: ["Vi tilbyr å hente inn en rekke RSS-feeds fra forskjellige nyhetskilder i Norge. Du kan markere et område på infoskjermen på samme måte som med Vær og Mobile News, som vil vise de siste RSS-nyhetene publisert av den valgte kilden. Trykk på «panelet» i rutenett området for å få opp en meny med alternativ nederst på skjermen"],
    }), createElement("br", {}), spanBold("URL: "), createElement("p", {
        children: ["Du kan også sette av ett område på infoskjermen til å vise nett-innhold hentet fra andre steder, f.eks lenker til bilder, PowerBI, andre nettsider, dokumenter og liknende. Du setter det opp på samme måte som med de andre markeringsverktøy alternativene. Trykk på «panalet» i rutenett området for å få opp menyen nederst. Her kan du legge til en rekke urladdresser som infoskjermen vil bytte på å vise. Merk at nettsider kan blokkere deg fra å laste inn innholdet på denne måten."],
    }), spanBold("Merk: "), createElement("span", {
        children: ["Om du henter inn ekstern innhold fra en nettside som krever mye prosessorkraft kan dette føre til at infoskjermen blir treg."],
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

