
export function toIcon(x) {
    switch (x) {
        case 1: {
            return "fas fa-thumbs-up";
        }
        case 2: {
            return "fas fa-thumbs-down";
        }
        case 3: {
            return "fas fa-heart";
        }
        case 4: {
            return "fas fa-star";
        }
        default: {
            return "";
        }
    }
}

export function toColor(x) {
    switch (x) {
        case 1: {
            return "dodgerblue";
        }
        case 2: {
            return "orange";
        }
        case 3: {
            return "red";
        }
        case 4: {
            return "gold";
        }
        default: {
            return "#aaa";
        }
    }
}

export function toName(x) {
    switch (x) {
        case 1: {
            return "Tommel opp";
        }
        case 2: {
            return "Tommel ned";
        }
        case 3: {
            return "Hjerte";
        }
        case 4: {
            return "Stjerne";
        }
        default: {
            return "";
        }
    }
}

export function toSelectable(x) {
    const array_1 = new Int32Array([1, 2, 3, 4]);
    return array_1.filter((r) => (!x.some((y) => (r === y))));
}

